import {Flex, Text} from '@chakra-ui/react'
import styled from '@emotion/styled'
import {COLORS, QUERIES} from '../../../constants'
import {formatDate} from '../../../lib/formatters'

const DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

const isShiftDisabled = schedule =>
  schedule.shiftStarted === 1 ||
  Date.now() > new Date(schedule.endTime).getTime()

const DesktopCalendar = ({
  currentWeek,
  unassigned,
  assigned,
  hasEditPemission,
  onEdit,
}) => (
  <Wrapper>
    <DayWrapper>
      <DayHeader />
      {currentWeek.map((day, dayIndex) => (
        <DayHeader key={day.toISOString()}>
          <span>{DAYS[dayIndex]}</span>
          <Text fontSize="sm">
            {formatDate(day, {withHours: false, isUTC: false})}
          </Text>
        </DayHeader>
      ))}
    </DayWrapper>
    {!Object.keys(unassigned).length && !Object.keys(assigned).length ? (
      <Flex justify="center" p={4}>
        No shifts for this week
      </Flex>
    ) : null}
    {Object.keys(unassigned).length ? (
      <DayWrapper>
        <Schedule>Unassigned shifts</Schedule>
        {Object.keys(unassigned).map(dayIndex => (
          <ShiftWrapper key={`unassigned-${dayIndex}`}>
            {typeof unassigned[dayIndex] === 'string' ? (
              <Schedule>-</Schedule>
            ) : (
              unassigned[dayIndex].map(schedule => (
                <Schedule
                  key={schedule.officerScheduleId}
                  onClick={
                    hasEditPemission
                      ? () => onEdit(schedule.officerScheduleId)
                      : undefined
                  }
                  clickAble={hasEditPemission}
                >
                  <span>{schedule.properties[0].propertyName}</span>
                  <Text fontSize="sm">
                    {schedule.startTime} - {schedule.endTime}
                  </Text>
                </Schedule>
              ))
            )}
          </ShiftWrapper>
        ))}
      </DayWrapper>
    ) : null}
    {Object.keys(assigned).map(officer => (
      <DayWrapper key={officer}>
        <Schedule>{officer}</Schedule>
        {Object.keys(assigned[officer]).map(dayIndex => (
          <ShiftWrapper key={`${officer}-assigned-${dayIndex}`}>
            {typeof assigned[officer][dayIndex] === 'string' ? (
              <Schedule>-</Schedule>
            ) : (
              assigned[officer][dayIndex].map(schedule => (
                <Schedule
                  key={schedule.officerScheduleId}
                  data-schedule-id={schedule.officerScheduleId}
                  onClick={
                    hasEditPemission
                      ? () => onEdit(schedule.officerScheduleId)
                      : undefined
                  }
                  disabled={isShiftDisabled(schedule)}
                  clickAble={hasEditPemission}
                  title={
                    schedule.shiftStarted === 1
                      ? "This shift's been worked"
                      : null
                  }
                >
                  <span>{schedule.officerName}</span>
                  <span>{schedule.properties[0].propertyName}</span>
                  <Text fontSize="sm">
                    {schedule.startTime} - {schedule.endTime}
                  </Text>
                </Schedule>
              ))
            )}
          </ShiftWrapper>
        ))}
      </DayWrapper>
    ))}
  </Wrapper>
)

const Wrapper = styled.div`
  // display: flex;
  border: 1px solid ${COLORS.gray[300]};
  border-radius: 4px;
  display: none;

  @media ${QUERIES.tabletAndBigger} {
    display: revert;
  }
`

const DayWrapper = styled.div`
  display: flex;

  &:not(:is(:last-of-type, :first-of-type)) {
    border-bottom: 2px solid ${COLORS.gray[300]};
  }
`

const DayHeader = styled.div`
  flex-basis: ${100 / 8}%;
  border: none;
  margin: 0;
  background-color: inherit;
  width: 100%;
  padding: 16px;
  text-align: center;
  border-bottom: 1px solid ${COLORS.gray[300]};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & * {
    text-align: center;
  }
`

const ShiftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Schedule = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  margin: 0;
  padding: 8px 0;
  background: inherit;
  width: 100%;

  &:disabled {
    color: ${COLORS.gray[500]};
  }

  ${p =>
    p.clickAble
      ? `
        &:hover:not(:disabled) {
          cursor: pointer;
          text-decoration: underline;
        }
  `
      : null}
`

export default DesktopCalendar
