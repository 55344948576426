import {useAuthMutation} from './useAuthQuery'

const usePostPoneShift = () => {
  const endpoint = '/shift/postpone_shift'
  const mutationState = useAuthMutation({
    endpoint,
  })

  return mutationState
}

export default usePostPoneShift
