import {Button, Icon} from '@chakra-ui/react'
import styled from '@emotion/styled'
import * as React from 'react'
import {MdCalendarToday} from 'react-icons/md'
import {DayPicker} from 'react-day-picker'

const WeekPicker = ({currentWeek, handleWeekChange}) => {
  const [isPickerOpen, setIsPickerOpen] = React.useState(false)
  const [pickerState, setPickerState] = React.useState(new Date())
  const pickerRef = React.useRef()
  const wrapperRef = React.useRef()

  const handleChange = week => {
    setIsPickerOpen(false)
    handleWeekChange([pickerState.getFullYear(), week])
  }

  const handleMonthChange = date => {
    setPickerState(date)
  }

  const handleButtonClick = e => {
    e.preventDefault()
    setIsPickerOpen(value => !value)
  }

  const handleClickOutside = e => {
    if (!wrapperRef.current.contains(e.target)) {
      setIsPickerOpen(false)
    }
  }

  React.useEffect(() => {
    if (isPickerOpen && pickerRef.current) {
      const activeWeekButton = pickerRef.current.querySelector(
        `button[aria-label="Week n. ${currentWeek}"]`
      )

      if (activeWeekButton) {
        activeWeekButton.style.backgroundColor = 'var(--rdp-background-color)'
      }
    }
  }, [isPickerOpen, currentWeek])

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    <Wrapper ref={wrapperRef}>
      <Button
        type="button"
        variant="outline"
        colorScheme="blue"
        aria-label="Pick a date"
        onClick={handleButtonClick}
      >
        <Icon as={MdCalendarToday} />
      </Button>
      {isPickerOpen ? (
        <PickerWrapperOutter>
          <PickerWrapperInner ref={pickerRef}>
            <DayPicker
              showWeekNumber
              onWeekNumberClick={handleChange}
              onMonthChange={handleMonthChange}
              defaultMonth={pickerState}
            />
          </PickerWrapperInner>
        </PickerWrapperOutter>
      ) : null}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: auto;
`

const PickerWrapperOutter = styled.div`
  position: relative;
`

const PickerWrapperInner = styled.div`
  position: absolute;
  z-index: 1;
  top: 12px;
  right: -24px;
  background: white;
  border-radius: 4px;
  box-shadow: 0px 1px 10px rgb(0 0 0 / 4%), 0px 4px 5px rgb(0 0 0 / 6%),
    0px 2px 4px -1px rgb(0 0 0 / 9%);
`

export default WeekPicker
