import {
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  useToast,
} from '@chakra-ui/react'
import * as React from 'react'
import {Controller, useForm} from 'react-hook-form'
import {Select} from '../Select'
import {validateMail} from '../../lib/form'
import {
  getDefaultUserValues,
  getUserProperties,
  PROPERTY_ACTIONS,
  USER_STATUS,
  USER_STATUS_OPTIONS,
} from '../../lib/form/users'

const UsersForm = ({user, roles, properties, isSubmiting, onSubmit}) => {
  const {
    register,
    control,
    reset,
    watch,
    handleSubmit,
    formState: {errors},
  } = useForm({
    defaultValues: getDefaultUserValues({
      user,
      roles,
      properties,
    }),
  })
  const toast = useToast({
    position: 'top',
    isClosable: true,
  })

  const roleWatch = watch('roleId')
  const passwordWatch = watch('password')

  const handleFormSubmit = async values => {
    try {
      const roleId = values.roleId.value
      const status = user?.userId ? values.status.value : USER_STATUS.enabled

      let userProperties
      if (values.properties) {
        userProperties = Array.isArray(values.properties)
          ? getUserProperties(values.properties, user)
          : [
              {
                propertyId: values.properties.value,
                action: PROPERTY_ACTIONS.add,
              },
            ]
      }

      const {passwordConfirmation, ...userData} = {
        ...values,
        roleId,
        status,
        properties: userProperties,
      }

      onSubmit(userData)
    } catch (e) {
      toast({
        title: 'Oops, there is some error in your data',
      })
    }
  }

  React.useEffect(
    () => () => {
      reset()
    },
    [reset]
  )

  return (
    <form id="user-form" onSubmit={handleSubmit(handleFormSubmit)}>
      <FormControl mb={2} isInvalid={errors?.roleId} isDisabled={isSubmiting}>
        <FormLabel>Role</FormLabel>
        <Controller
          control={control}
          name="roleId"
          rules={{
            validate: ({value} = {}) =>
              value !== undefined || 'You must select a user type',
            valueAsNumber: true,
          }}
          render={({field}) => (
            <Select
              isClearable
              isDisabled={isSubmiting}
              placeholder="Select a role"
              options={roles}
              hasError={Boolean(errors?.roleId)}
              {...field}
            />
          )}
        />
        {errors?.roleId ? (
          <FormErrorMessage>{errors.roleId.message}</FormErrorMessage>
        ) : null}
      </FormControl>
      {roleWatch?.multipleProperties >= 1 ? (
        <FormControl
          flexBasis="50%"
          mb={2}
          isInvalid={errors?.properties}
          isDisabled={isSubmiting}
        >
          <FormLabel>Property</FormLabel>
          <Controller
            control={control}
            name="properties"
            rules={{
              validate: value =>
                value !== undefined || 'You must select a property',
              valueAsNumber: true,
            }}
            render={({field}) => (
              <Select
                isClearable
                isDisabled={isSubmiting}
                isMulti={roleWatch?.multipleProperties > 1}
                placeholder="Select a property"
                options={properties}
                hasError={Boolean(errors?.properties)}
                {...field}
              />
            )}
          />
          {errors?.properties ? (
            <FormErrorMessage>{errors.properties.message}</FormErrorMessage>
          ) : null}
        </FormControl>
      ) : null}

      {roleWatch ? (
        <>
          <Flex mb={2} gap={2}>
            <FormControl
              flexBasis="50%"
              isInvalid={errors?.firstName}
              isDisabled={isSubmiting}
            >
              <FormLabel>First name</FormLabel>
              <Input
                {...register('firstName', {required: 'This field is required'})}
              />
              {errors?.firstName ? (
                <FormErrorMessage>{errors.firstName.message}</FormErrorMessage>
              ) : null}
            </FormControl>
            <FormControl
              flexBasis="50%"
              isInvalid={errors?.lastName}
              isDisabled={isSubmiting}
            >
              <FormLabel>Last name</FormLabel>
              <Input
                {...register('lastName', {required: 'This field is required'})}
              />
              {errors?.lastName ? (
                <FormErrorMessage>{errors.lastName.message}</FormErrorMessage>
              ) : null}
            </FormControl>
          </Flex>

          <Flex mb={2} gap={2}>
            <FormControl
              flexBasis="50%"
              isInvalid={errors?.email}
              isDisabled={isSubmiting}
            >
              <FormLabel>Email</FormLabel>
              <Input
                {...register('email', {
                  validate: value => {
                    if (!value) {
                      return 'This field is required'
                    }

                    return validateMail(value) || 'Wrong format: jon@doe.com'
                  },
                })}
              />
              {errors?.email ? (
                <FormErrorMessage>{errors.email.message}</FormErrorMessage>
              ) : null}
            </FormControl>
            <FormControl
              flexBasis="50%"
              isInvalid={errors?.userName}
              isDisabled={isSubmiting}
            >
              <FormLabel>Username</FormLabel>
              <Input
                {...register('userName', {required: 'This field is required'})}
              />
              {errors?.userName ? (
                <FormErrorMessage>{errors.userName.message}</FormErrorMessage>
              ) : null}
            </FormControl>
          </Flex>

          {!user?.userId ? (
            <Flex mb={2} gap={2}>
              <FormControl
                flexBasis="50%"
                isInvalid={errors?.password}
                isDisabled={isSubmiting}
              >
                <FormLabel>Password</FormLabel>
                <Input
                  type="password"
                  {...register('password', {
                    required: 'This field is required',
                  })}
                />
                {errors?.password ? (
                  <FormErrorMessage>{errors.password.message}</FormErrorMessage>
                ) : null}
              </FormControl>
              <FormControl
                flexBasis="50%"
                isInvalid={errors?.passwordConfirmation}
                isDisabled={isSubmiting}
              >
                <FormLabel>Password Confirmation</FormLabel>
                <Input
                  type="password"
                  {...register('passwordConfirmation', {
                    validate: value => {
                      if (!value) {
                        return 'This field is required'
                      }

                      return (
                        passwordWatch === value || "The passwords don't match"
                      )
                    },
                  })}
                />
                {errors?.passwordConfirmation ? (
                  <FormErrorMessage>
                    {errors.passwordConfirmation.message}
                  </FormErrorMessage>
                ) : null}
              </FormControl>
            </Flex>
          ) : null}

          {user?.userId ? (
            <FormControl isDisabled={isSubmiting}>
              <FormLabel>Staus</FormLabel>
              <Controller
                control={control}
                name="status"
                rules={{
                  validate: value =>
                    value !== undefined || 'You must select a status',
                  valueAsNumber: true,
                }}
                render={({field}) => (
                  <Select
                    isClearable
                    isDisabled={isSubmiting}
                    placeholder="Select a status"
                    options={USER_STATUS_OPTIONS}
                    hasError={Boolean(errors?.status)}
                    {...field}
                  />
                )}
              />
            </FormControl>
          ) : null}
        </>
      ) : null}
    </form>
  )
}

export default UsersForm
