import {getEntitiesWithActions} from '../../lib/entities'

const poolReportPermissionsList = ['poolreport list', 'poolreport show']
const poolReportPermissionsCreate = ['poolreport create', 'poolreport store']
const poolReportPermissionsEdit = ['poolreport update']
const poolReportPermissionsDelete = ['poolreport delete']

const sortFields = [
  {
    key: 'incident_date',
    dir: 'desc',
    label: 'By date Descending ',
  },
  {
    key: 'incident_date',
    dir: 'asc',
    label: 'By date Ascending ',
  },
  {
    key: 'officer_id',
    dir: 'desc',
    label: 'By officer Descending ',
  },
  {
    key: 'officer_id',
    dir: 'asc',
    label: 'By officer Ascending ',
  },
  {
    key: 'incident_type',
    dir: 'desc',
    label: 'By type Descending ',
  },
  {
    key: 'incident_type',
    dir: 'asc',
    label: 'By type Ascending ',
  },
]

const canEditValidation = entity => entity.automaticDar === 0

const canDeleteValidation = entity => entity.automaticDar === 0

const getPoolReportsWithActions = (vehicles, user) =>
  getEntitiesWithActions(
    vehicles,
    user,
    poolReportPermissionsEdit,
    poolReportPermissionsDelete,
    canEditValidation,
    canDeleteValidation
  )

export {
  poolReportPermissionsList,
  poolReportPermissionsCreate,
  poolReportPermissionsEdit,
  poolReportPermissionsDelete,
  getPoolReportsWithActions,
  sortFields,
}
