import {
  Box,
  Flex,
  Text,
  Button,
  Icon,
  List,
  ListItem,
  useBreakpointValue,
} from '@chakra-ui/react'
import {MdModeEdit, MdDelete} from 'react-icons/md'
import {HiChevronDown, HiChevronUp} from 'react-icons/hi'
import {formatDate} from '../../../lib/formatters'

const PoolsDesktopTable = ({poolReports, sort, onSort, onEdit, onDelete}) => {
  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  })

  if (isMobile) {
    return null
  }

  const SortIcon =
    sort?.dir === 'desc' ? (
      <Icon as={HiChevronDown} />
    ) : (
      <Icon as={HiChevronUp} />
    )

  return (
    <List boxShadow="lg" fontSize="xs">
      <ListItem borderBottom="1px solid" borderColor="gray.300">
        <Flex>
          <Flex
            pl={4}
            pr={0}
            py={2}
            flex="1"
            flexBasis="90%"
            fontWeight="bold"
            align="center"
          >
            <Button
              flexBasis="33.33%"
              variant="link"
              justifyContent="flex-start"
              rightIcon={sort?.field === 'incident_date' ? SortIcon : null}
              onClick={() => onSort && onSort('incident_date')}
            >
              <Text color="gray.800" fontSize="sm">
                Date
              </Text>
            </Button>
            <Button
              flexBasis="33.33%"
              variant="link"
              justifyContent="flex-start"
              rightIcon={sort?.field === 'officer_id' ? SortIcon : null}
              onClick={() => onSort && onSort('officer_id')}
            >
              <Text color="gray.800" fontSize="sm">
                Officer
              </Text>
            </Button>
            <Button
              flexBasis="33.33%"
              variant="link"
              justifyContent="flex-start"
              rightIcon={sort?.field === 'incident_type' ? SortIcon : null}
              onClick={() => onSort && onSort('incident_type')}
            >
              <Text color="gray.800" fontSize="sm">
                Type
              </Text>
            </Button>
            <Text
              color="gray.800"
              fontSize="sm"
              flexBasis="33.33%"
              fontWeight="semibold"
            >
              Details
            </Text>
          </Flex>
          <Box flexBasis="15%" py={2} pr={4} />
        </Flex>
      </ListItem>
      {poolReports.map(poolReport => (
        <ListItem
          key={poolReport.poolReportId}
          transitionProperty="var(--chakra-transition-property-common)"
          transitionDuration="var(--chakra-transition-duration-normal)"
          _even={{
            bg: 'gray.100',
          }}
          _hover={{
            bg: 'blue.50',
          }}
        >
          <Flex>
            <Flex
              w="100%"
              h="100%"
              minH="60px"
              pl={4}
              pr={0}
              py={2}
              flex="1"
              flexBasis="90%"
              fontSize="xs"
              color="gray.700"
            >
              <Flex align="center" w="100%">
                <Text flexBasis="33.33%" whiteSpace="pre-wrap">
                  {formatDate(poolReport.incidentDate)}
                </Text>
                <Text flexBasis="33.33%" whiteSpace="pre-wrap">
                  {poolReport.officerName}
                </Text>
                <Text flexBasis="33.33%" whiteSpace="pre-wrap">
                  {poolReport.incidentType}
                </Text>
                <Text flexBasis="33.33%" whiteSpace="pre-wrap">
                  {poolReport.details}
                </Text>
              </Flex>
            </Flex>
            <Flex justify="center" align="center" flexBasis="15%" py={2} pr={4}>
              {poolReport.canEdit || poolReport.canDelete ? (
                <Flex gap={2}>
                  {poolReport.canEdit ? (
                    <Button
                      variant="ghost"
                      onClick={() => onEdit(poolReport.poolReportId)}
                    >
                      <Icon as={MdModeEdit} w={6} h={6} />
                    </Button>
                  ) : null}
                  {poolReport.canDelete ? (
                    <Button
                      variant="ghost"
                      colorScheme="red"
                      onClick={() => onDelete(poolReport.poolReportId)}
                    >
                      <Icon as={MdDelete} w={6} h={6} />
                    </Button>
                  ) : null}
                </Flex>
              ) : null}
            </Flex>
          </Flex>
        </ListItem>
      ))}
    </List>
  )
}

export default PoolsDesktopTable
