import * as React from 'react'
import {Navigate} from 'react-router-dom'
import {useAuth} from '../../context/auth'
import {useUserProperties} from '../../context/property'
import {getHasUserPermissions} from '../../lib/auth'

const Acl = ({children, requiredPermissions, withRedirect = false}) => {
  const {user} = useAuth()
  const {properties} = useUserProperties()
  const [isVerified, setIsVerified] = React.useState(false)
  const [hasPermission, setHasPermission] = React.useState(false)

  React.useEffect(() => {
    const _hasPermission = getHasUserPermissions(requiredPermissions, user)

    setHasPermission(_hasPermission)
    setIsVerified(true)
  }, [requiredPermissions, user])

  if (!isVerified) {
    return null
  }

  if (isVerified && !hasPermission) {
    if (withRedirect) {
      let to
      if (user.isAdmin && !properties.length) {
        to = '/properties'
      } else if (user.isOfficer && user.isOneTimeLogin) {
        to = '/shifts'
      } else if (user.isResident) {
        to = '/permits'
      } else {
        to = '/'
      }
      return <Navigate to={to} replace />
    }
    return null
  }

  return children
}

export default Acl
