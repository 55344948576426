const PROPERTY_QUERY_KEY = ['user-property']

const getRuleValue = (propertyRules, ruleName) => {
  const rule = propertyRules.find(r => r.rule === ruleName)

  if (!rule) {
    return false
  }

  return Boolean(rule.value)
}

export {PROPERTY_QUERY_KEY, getRuleValue}
