const filterEmptyValues = obj =>
  Object.keys(obj).reduce((acc, key) => {
    if (obj[key]) {
      const value = typeof obj[key] === 'object' ? obj[key].value : obj[key]
      acc[key] = value
    }

    return acc
  }, {})

const isObjectEmpty = obj => obj && !Object.keys(obj).length

export {filterEmptyValues, isObjectEmpty}
