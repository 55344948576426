import {
  Box,
  Flex,
  Text,
  Link,
  Image,
  List,
  ListItem,
  useToken,
  useBreakpointValue,
  Icon,
} from '@chakra-ui/react'
import {Link as RouterLink, useLocation} from 'react-router-dom'
import {MdWarning} from 'react-icons/md'
import DinamicIcon from '../DinamicIcon'
import Acl from '../Acl'
import useStoreCount from '../../hooks/useStoreCount'
import {useAuth} from '../../context/auth'
import logo from '../../images/logo.png'
import {GROUP_IDS} from '../../lib/sections'

const DesktopNavbar = function ({sections}) {
  const [blue400, gray600, red400] = useToken('colors', [
    'blue.400',
    'gray.600',
    'yellow.500',
  ])
  const {user} = useAuth()
  const location = useLocation()
  const count = useStoreCount()
  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  })

  if (isMobile) {
    return null
  }

  const isOfficer = user.isOfficer || user.isPostCommander || 2 > 1

  return (
    <Box
      h="100%"
      bg="gray.200"
      w={{base: '100%', lg: '60px'}}
      overflowY={{base: 'scroll', lg: 'revert'}}
    >
      <Flex justify="center" pt={4}>
        <Image
          src={logo}
          alt="logo"
          boxSize={{
            base: '32px',
            lg: '32px',
          }}
        />
      </Flex>
      <List pt={8}>
        {sections.map(section =>
          section.items.length ? (
            <ListItem
              key={section.title}
              bg={
                section.groupSections.includes(location.pathname)
                  ? 'blue.50'
                  : 'inherit'
              }
            >
              <Box py={2}>
                <Link
                  as={RouterLink}
                  title={section.title}
                  to={section.items[0].path}
                  w="100%"
                  h="44px"
                  display="block"
                  sx={{
                    ':hover': {
                      textDecoration: 'none',
                      color: 'blue.500',
                    },
                  }}
                >
                  <Flex
                    align="center"
                    justify="center"
                    h="100%"
                    position="relative"
                  >
                    <DinamicIcon
                      fill={
                        section.groupSections.includes(location.pathname)
                          ? blue400
                          : gray600
                      }
                      name={section.icon}
                      size="32px"
                    />
                    {section.id === GROUP_IDS.reports && isOfficer && count ? (
                      <Flex
                        position="absolute"
                        top="8px"
                        right="4px"
                        boxSize="18px"
                        justify="center"
                        align="center"
                        borderRadius="50%"
                      >
                        <Icon as={MdWarning} fill={red400} w="18px" h="18px" />
                        {/* <Text fontSize="xs" color="white">
                          {count}
                        </Text> */}
                      </Flex>
                    ) : null}
                  </Flex>
                </Link>
                {section.groupSections.includes(location.pathname) ? (
                  <Box
                    p={4}
                    top="0"
                    h="100%"
                    left="60px"
                    bg="blue.50"
                    w="calc(300px - 60px)"
                    position="absolute"
                  >
                    <Text fontSize="lg" fontWeight="semibold" color="gray.700">
                      {section.title}
                    </Text>
                    <List pt={12}>
                      {section.items.map(item => (
                        <Acl
                          key={item.path}
                          requiredPermissions={item.permissions}
                        >
                          <ListItem key={item.path} py={2}>
                            <Link
                              as={RouterLink}
                              to={item.path}
                              color={
                                location.pathname === item.path
                                  ? 'blue.500'
                                  : 'gray.600'
                              }
                              sx={{
                                ':hover': {
                                  textDecoration: 'none',
                                  color: 'blue.500',
                                },
                              }}
                            >
                              <Text
                                fontSize="sm"
                                fontWeight="semibold"
                                position="relative"
                                display="inline-block"
                              >
                                {item.label}

                                {item.path === '/offlinestore' && count ? (
                                  <Flex
                                    as="span"
                                    bg="red.400"
                                    color="white"
                                    position="absolute"
                                    top="0"
                                    right="-24px"
                                    borderRadius="50%"
                                    boxSize="20px"
                                    justify="center"
                                    align="center"
                                  >
                                    <Text as="span" fontSize="xs">
                                      {count}
                                    </Text>
                                  </Flex>
                                ) : null}
                              </Text>
                            </Link>
                          </ListItem>
                        </Acl>
                      ))}
                    </List>
                  </Box>
                ) : null}
              </Box>
            </ListItem>
          ) : null
        )}
      </List>
    </Box>
  )
}

export default DesktopNavbar
