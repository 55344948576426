import {Flex, Text, useToast} from '@chakra-ui/react'
import * as React from 'react'
import {useAuth} from '../../../context/auth'
import BriefsMobileTable from './BriefsMobileTable'
import BriefsDesktopTable from './BriefsDesktopTable'
import BriefsFormModal from '../BriefsFormModal'
import Paginator from '../../Paginator'
import DeleteModal from '../../DeleteModal'
import Acl from '../../Acl'
import {
  briefPermissionsDelete,
  briefPermissionsEdit,
  getBriefsWithActions,
} from '../BriefingsBoards.helpers'
import {usePatch} from '../../../hooks/entities'
import {isObjectEmpty} from '../../../lib/objects'
import {ENTITIES, ENTITIES_KEYS} from '../../../lib/entities'
import {MODAL_KEYS} from '../../../lib/modal'

const BriefsTable = ({
  items,
  sort,
  itemsTotal,
  pages,
  pagesCount,
  currentPage,
  filters,
  onSort,
  onPageChange,
}) => {
  const [briefId, setBriefId] = React.useState()
  const [activeModal, setActiveModal] = React.useState()
  const {user} = useAuth()
  const {mutate, isLoading} = usePatch({
    id: briefId,
    entity: ENTITIES.briefings,
    currentPage,
    filters,
    sort,
  })
  const toast = useToast({
    position: 'top',
    isClosable: true,
  })

  const handleEdit = id => {
    setBriefId(id)
    setActiveModal(MODAL_KEYS.edit)
  }

  const handleDelete = id => {
    setBriefId(id)
    setActiveModal(MODAL_KEYS.delete)
  }

  const handleModalClose = () => {
    setBriefId(null)
    setActiveModal(null)
  }

  const handleUpdate = briefData => {
    mutate(
      {
        ...briefData,
        [ENTITIES_KEYS.briefings]: briefId,
      },
      {
        onSuccess() {
          toast({
            title: 'Briefing board updated!',
            status: 'success',
          })
          handleModalClose()
        },
        onError() {
          toast({
            title: 'Error updating Briefing board',
            status: 'error',
          })
        },
      }
    )
  }

  const briefings = getBriefsWithActions(items, user)

  if (!briefings?.length && isObjectEmpty(filters)) {
    return (
      <Flex justify="center" boxShadow="lg" p={4}>
        <Text>No data found for this section</Text>
      </Flex>
    )
  }

  if (!briefings?.length && !isObjectEmpty(filters)) {
    return (
      <Flex justify="center" boxShadow="lg" p={4}>
        <Text>No data found for the filters applied</Text>
      </Flex>
    )
  }

  return (
    <>
      <BriefsMobileTable
        briefings={briefings}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
      <BriefsDesktopTable
        briefings={briefings}
        sort={sort}
        onSort={onSort}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />

      <Paginator
        itemsTotal={itemsTotal}
        pages={pages}
        pagesCount={pagesCount}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />

      <Acl requiredPermissions={briefPermissionsEdit}>
        {activeModal === MODAL_KEYS.edit ? (
          <BriefsFormModal
            briefId={briefId}
            isOpen={activeModal === MODAL_KEYS.edit}
            isSubmiting={isLoading}
            onClose={handleModalClose}
            onSubmit={handleUpdate}
          />
        ) : null}
      </Acl>

      <Acl requiredPermissions={briefPermissionsDelete}>
        {activeModal === MODAL_KEYS.delete ? (
          <DeleteModal
            id={briefId}
            sort={sort}
            entityName="Briefing board"
            filters={filters}
            entity={ENTITIES.briefings}
            currentPage={currentPage}
            entityKey={ENTITIES_KEYS.briefings}
            isOpen={activeModal === MODAL_KEYS.delete}
            onClose={handleModalClose}
          />
        ) : null}
      </Acl>
    </>
  )
}

export default BriefsTable
