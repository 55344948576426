import {
  Flex,
  Icon,
  Text,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Skeleton,
} from '@chakra-ui/react'
import {MdClose} from 'react-icons/md'
import {useOfficers} from '../../hooks/auth'
import SchedulesForm from './SchedulesForm'
import {useItem} from '../../hooks/entities'
import {useProperties} from '../../hooks/properties'
import {ENTITIES, ENTITIES_KEYS} from '../../lib/entities'

const SchedulesFormModal = function ({
  officerScheduleId,
  isOpen,
  isSubmiting,
  defaultSchedule,
  onClose,
  onDelete,
  onSubmit,
}) {
  const {data: propertiesData, isLoading: isLoadingProperties} = useProperties({
    enabled: isOpen,
  })
  const {data: officersData, isLoading: isLoadingOfficers} = useOfficers({
    enabled: isOpen,
  })
  const {
    data: officerScheduleData,
    isLoading: isLoadingOfficerScheduleData,
    isFetching,
    isRefetching,
  } = useItem({
    id: officerScheduleId,
    entity: ENTITIES.officerSchedules,
    entityKey: ENTITIES_KEYS.officerSchedules,
  })

  const isLoading =
    isLoadingProperties ||
    isLoadingOfficers ||
    isLoadingOfficerScheduleData ||
    isFetching ||
    isRefetching

  const properties = propertiesData
    ? propertiesData?.properties.map(property => ({
        value: property.propertyId,
        label: property.name,
      }))
    : []

  const officers = officersData
    ? officersData?.officers.map(o => ({
        value: o.userId,
        label: `${o.firstName} ${o.lastName}`,
        properties: o.properties,
        officerSchedules: o.officerSchedules,
      }))
    : []

  const schedule = officerScheduleData?.data ?? defaultSchedule

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      size={{
        base: 'full',
        lg: 'xl',
      }}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader borderBottom="1px solid" borderColor="gray.400">
          <Flex align="center" gap={2}>
            <Button p="0" variant="ghost" onClick={onClose}>
              <Icon as={MdClose} w="24px" h="24px" />
            </Button>
            <Text>
              {officerScheduleId ? 'Update' : 'Create'} officer schedule
            </Text>
            <Button
              ml="auto"
              type="submit"
              form="user-form"
              variant="solid"
              colorScheme="blue"
              disabled={isLoading}
              isLoading={isSubmiting}
            >
              {officerScheduleId ? 'Update' : 'Create'}
            </Button>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          {isLoading ? (
            <Skeleton h="200px" />
          ) : (
            <SchedulesForm
              schedule={schedule}
              officers={officers}
              properties={properties}
              onDelete={onDelete}
              onSubmit={onSubmit}
              isSubmiting={isSubmiting}
            />
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default SchedulesFormModal
