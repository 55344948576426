import styled from '@emotion/styled'

const WrapperPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid transparent; /* to avoid margin collapse */
`

const ActionsWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`

const ActionPlaceholder = styled.div`
  background-color: ${p => p.theme.colors.gray[300]};
  width: 50px;
  height: 48px;
  border-radius: 4px;
`

const ListInnerPlaceholder = styled.div`
  background-color: ${p => p.theme.colors.gray[300]};
  width: 100%;
  height: 300px;
  border-radius: 4px;
`

const FormInnerPlaceholder = styled.div`
  margin: 32px;
  margin-top: 64px;
  width: calc(100% - 64px);
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
`

const FormElemPlaceHolder = styled.div`
  background-color: ${p => p.theme.colors.gray[300]};
  height: 40px;
  border-radius: 4px;
  width: 100%;
`
const FormSubmitPlaceholder = styled(FormElemPlaceHolder)`
  width: 80%;
`

const ListActionsPlaceholder = () => (
  <ActionsWrapper>
    <ActionPlaceholder />
    <ActionPlaceholder />
  </ActionsWrapper>
)

const ListPlaceholder = ({withActions}) => (
  <WrapperPlaceholder>
    {withActions ? <ListActionsPlaceholder /> : null}
    <ListInnerPlaceholder />
  </WrapperPlaceholder>
)

const FormPlaceholder = () => (
  <WrapperPlaceholder>
    <FormInnerPlaceholder>
      <FormElemPlaceHolder />
      <FormElemPlaceHolder />
      <FormElemPlaceHolder />
      <FormSubmitPlaceholder />
    </FormInnerPlaceholder>
  </WrapperPlaceholder>
)

export {ListPlaceholder, FormPlaceholder}
