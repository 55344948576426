import {
  Flex,
  Button,
  Input,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast,
} from '@chakra-ui/react'
import {useForm} from 'react-hook-form'
import {Link, useNavigate} from 'react-router-dom'
import {useForgot} from '../hooks/auth'
import {validateMail} from '../lib/form'

const Forgot = () => {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm()
  const toast = useToast({
    position: 'top',
    isClosable: true,
  })
  const {mutate, isLoading} = useForgot()
  const navigate = useNavigate()

  const onSubmit = email => {
    const forgotData = {email}
    mutate(forgotData, {
      onSuccess() {
        toast({
          title: 'Email sent!',
          status: 'success',
        })
        navigate('/login')
      },
      onError(error) {
        toast({
          title: error.statusMessage,
          status: 'error',
        })
      },
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <FormControl isInvalid={Boolean(errors?.email?.message)} mb={8}>
        <FormLabel>Email</FormLabel>
        <Input
          type="email"
          {...register('email', {
            validate: value => {
              if (!value) {
                return 'This field is required'
              }

              return validateMail(value) || 'Wrong mail format (jon@doe.com)'
            },
          })}
        />
        {errors?.email ? (
          <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
        ) : null}
      </FormControl>

      <Flex justify="center" gap={4} flexWrap="wrap">
        <Flex flexBasis="100%" justify="center">
          <Button
            type="submit"
            variant="solid"
            colorScheme="blue"
            isLoading={isLoading}
          >
            Send email
          </Button>
        </Flex>
        <Link to="/auth/login">
          <Text fontSize="xs" textDecoration="underline">
            Back to login
          </Text>
        </Link>
      </Flex>
    </form>
  )
}

export default Forgot
