import {Box, FormControl, FormLabel, Input, Checkbox} from '@chakra-ui/react'
import {Controller, useForm} from 'react-hook-form'
import {Select} from '../../Select'
import {
  getRulesDefaultValues,
  getSortedRules,
} from '../../../lib/form/properties'
import {toCamelCase} from '../../../lib/formatters'
import {ENTITIES_KEYS} from '../../../lib/entities'

const PropertiesFormRules = ({
  formId,
  types,
  towCompanies,
  currentValues,
  isSubmiting,
  onSaveChanges,
}) => {
  const rules = getSortedRules(types, currentValues)
  const {register, handleSubmit, control} = useForm({
    defaultValues: getRulesDefaultValues({currentValues, rules}),
  })

  const rulesOptions = {
    towCompanies,
  }

  const handleFormSubmit = values => {
    const rulesData = Object.keys(values).reduce(
      (acc, ruleId) => {
        const {type, relationship} = rules.find(
          r => r.ruleId === Number(ruleId)
        )
        const value = values[ruleId] || null

        if (type === 'select') {
          acc.rules.push({ruleId, value: value ? 1 : null})
          const entity = toCamelCase(relationship)
          acc[entity] =
            value?.map(v => ({
              [ENTITIES_KEYS[entity]]: v.value,
            })) || null
        } else {
          acc.rules.push({ruleId, value})
        }
        return acc
      },
      {rules: []}
    )

    onSaveChanges(rulesData)
  }

  return (
    <Box mt={4} pb={8} overflowY="auto">
      <form id={formId} onSubmit={handleSubmit(handleFormSubmit)}>
        {rules.map(r => (
          <FormControl key={r.ruleId} mb={4}>
            <FormLabel>{r.rule}</FormLabel>
            {r.type === 'number' ? (
              <Input
                type="number"
                min="0"
                disabled={isSubmiting}
                {...register(String(r.ruleId))}
              />
            ) : null}
            {r.type === 'boolean' ? (
              <Checkbox
                borderColor="gray.400"
                disabled={isSubmiting}
                {...register(String(r.ruleId))}
              />
            ) : null}
            {r.type === 'select' ? (
              <Controller
                control={control}
                name={String(r.ruleId)}
                render={({field}) => (
                  <Select
                    isClearable
                    isMulti
                    disabled={isSubmiting}
                    options={rulesOptions[toCamelCase(r.relationship)]}
                    {...field}
                  />
                )}
              />
            ) : null}
          </FormControl>
        ))}
      </form>
    </Box>
  )
}

export default PropertiesFormRules
