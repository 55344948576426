import {
  Box,
  Flex,
  Text,
  Button,
  Icon,
  List,
  ListItem,
  useBreakpointValue,
} from '@chakra-ui/react'
import {MdModeEdit, MdDelete} from 'react-icons/md'
import {HiChevronDown, HiChevronUp} from 'react-icons/hi'

const HouseholdsDesktopTable = ({
  households,
  sort,
  onSort,
  onEdit,
  onDelete,
}) => {
  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  })

  if (isMobile) {
    return null
  }

  const SortIcon =
    sort?.dir === 'desc' ? (
      <Icon as={HiChevronDown} />
    ) : (
      <Icon as={HiChevronUp} />
    )

  return (
    <List boxShadow="lg" fontSize="xs">
      <ListItem borderBottom="1px solid" borderColor="gray.300">
        <Flex>
          <Flex pl={4} pr={0} py={2} flex="1" fontWeight="bold" align="center">
            <Button
              flexBasis="50%"
              variant="link"
              justifyContent="flex-start"
              rightIcon={sort?.field === 'name' ? SortIcon : null}
              onClick={() => onSort && onSort('name')}
            >
              <Text color="gray.800" fontSize="sm">
                Name
              </Text>
            </Button>
            <Button
              flexBasis="50%"
              variant="link"
              justifyContent="flex-start"
              rightIcon={sort?.field === 'address' ? SortIcon : null}
              onClick={() => onSort && onSort('address')}
            >
              <Text color="gray.800" fontSize="sm">
                Address
              </Text>
            </Button>
          </Flex>
          <Box flexBasis="15%" py={2} pr={4} />
        </Flex>
      </ListItem>
      {households.map(household => (
        <ListItem
          key={household.houseHoldId}
          transitionProperty="var(--chakra-transition-property-common)"
          transitionDuration="var(--chakra-transition-duration-normal)"
          _even={{
            bg: 'gray.100',
          }}
          _hover={{
            bg: 'blue.50',
          }}
        >
          <Flex>
            <Flex
              w="100%"
              h="100%"
              minH="60px"
              pl={4}
              pr={0}
              py={2}
              flex="1"
              flexBasis="90%"
              fontSize="xs"
              color="gray.700"
            >
              <Flex align="center" w="100%">
                <Text flexBasis="50%" whiteSpace="pre-wrap">
                  {household.name}
                </Text>
                <Text flexBasis="50%" whiteSpace="pre-wrap">
                  {household.number} {household.street}
                </Text>
              </Flex>
            </Flex>
            <Flex justify="center" align="center" flexBasis="15%" py={2} pr={4}>
              {household.canEdit || household.canDelete ? (
                <Flex gap={2}>
                  {household.canEdit ? (
                    <Button
                      variant="ghost"
                      onClick={() => onEdit(household.houseHoldId)}
                    >
                      <Icon as={MdModeEdit} w={6} h={6} />
                    </Button>
                  ) : null}
                  {household.canDelete ? (
                    <Button
                      variant="ghost"
                      colorScheme="red"
                      onClick={() => onDelete(household.houseHoldId)}
                    >
                      <Icon as={MdDelete} w={6} h={6} />
                    </Button>
                  ) : null}
                </Flex>
              ) : null}
            </Flex>
          </Flex>
        </ListItem>
      ))}
    </List>
  )
}

export default HouseholdsDesktopTable
