import {
  ModalBody,
  Flex,
  Box,
  Text,
  Button,
  Icon,
  Image,
  Divider,
  List,
  ListItem,
  Skeleton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
} from '@chakra-ui/react'
import * as React from 'react'
import {MdOutlineClose} from 'react-icons/md'
import {useReactToPrint} from 'react-to-print'
import ImagesGallery from '../ImagesGallery/ImagesGallery'
import {useUserProperties} from '../../context/property'
import {useItem} from '../../hooks/entities'
import logo from '../../images/logo.jpg'
import {ENTITIES, ENTITIES_KEYS} from '../../lib/entities'
import {useViolationTypes} from '../../hooks/violations'
import {formatDate, formatTime} from '../../lib/formatters'

const printStyles = {
  '@media print': {
    '&, & *': {
      visibility: 'visible !important',
      overflow: 'visible !important',
    },
    '&': {
      padding: '16px',
      width: '100%',
      maxWidth: '377px',
      position: 'absolute',
      top: '0',
      left: '50%',
      transform: 'translateX(-50%)',
      height: '150vh',
      display: 'inline-block',
    },
  },
}

const ViolationDetail = ({isOpen, onClose, violationId}) => {
  const {data: {violationTypes: types} = {}, isLoading: isLoadingTypes} =
    useViolationTypes({
      enabled: isOpen,
    })
  const {data: {data: violation} = {}, isLoading: isLoadingViolation} = useItem(
    {
      entity: ENTITIES.violations,
      entityKey: ENTITIES_KEYS.violations,
      id: violationId,
    }
  )

  const isLoading = isLoadingTypes || isLoadingViolation
  const violationRef = React.useRef()
  const {currentProperty} = useUserProperties()

  const handlePrint = useReactToPrint({
    content: () => violationRef.current,
  })

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={{
        base: 'full',
        lg: 'xl',
      }}
    >
      <ModalOverlay />
      <ModalContent
        w={{
          base: '100%',
          lg: '600px',
        }}
        // minH="300px"
        // overflowX="hidden"
        // zIndex="9999"
      >
        <ModalCloseButton />
        {isLoading ? (
          <Box padding={8}>
            <Skeleton w="100%" h="100px" />
          </Box>
        ) : (
          <ModalBody pt="var(--chakra-space-6)">
            <Box id="imprimime" sx={printStyles} ref={violationRef}>
              <Flex pb="16px">
                <Image src={logo} alt="logo" boxSize="150px" />
                <Flex
                  mb="16px"
                  direction="column"
                  textTransform="uppercase"
                  textAlign="center"
                >
                  <Text>notice of</Text>
                  <Text fontSize="2xl" fontWeight="bold">
                    parking violation
                  </Text>
                  <Text fontSize="sm">issued by</Text>
                  <Text fontSize="lg" fontWeight="bold">
                    russell private security
                  </Text>
                  <Text>(909) 608-7233</Text>
                  <Text fontSize="sm">
                    under the authority of the homeowners association
                  </Text>
                </Flex>
              </Flex>

              <Divider
                borderBottom="2px solid"
                borderColor="gray.700"
                mb="16px"
              />

              <Box fontSize="sm">
                <Text mb={2}>
                  Your vehicle is illegally parked for one or more of the
                  following reasons under the authority of your Homeowners
                  Association’s rules and regulations and/or CVC22658(a) and may
                  be subject to being towed at the owner’s expense. If you
                  believe this citation was issued in error, please contact
                  Russell Private Security immediately; otherwise it will be
                  deemed valid by omission.
                </Text>
                <Box mb="16px">
                  <Flex mb={2} gap={2}>
                    <Text>Contract:</Text>
                    <Text flexBasis="100%" borderBottom="1px solid">
                      {currentProperty.name}
                    </Text>
                  </Flex>
                  <Flex mb={2} gap={2}>
                    <Text>Location:</Text>
                    <Text flexBasis="100%" borderBottom="1px solid">
                      {currentProperty.address}
                    </Text>
                  </Flex>
                  <Flex>
                    <Flex mb={2} gap={2} flexBasis="50%">
                      <Text>Date:</Text>
                      <Text flexBasis="100%" borderBottom="1px solid">
                        {formatDate(violation.violationDate, {
                          withHours: false,
                        })}
                      </Text>
                    </Flex>
                    <Flex mb={2} gap={2} flexBasis="50%">
                      <Text>Time:</Text>
                      <Text flexBasis="100%" borderBottom="1px solid">
                        {formatTime(violation.violationDate)}
                      </Text>
                    </Flex>
                  </Flex>
                </Box>

                <Box mb="16px">
                  <Flex>
                    <Flex flexBasis="50%">
                      <Box
                        p="4px"
                        flexBasis="100%"
                        border="2px solid"
                        borderBottom="none"
                        borderColor="gray.700"
                      >
                        Make: {violation.vehicle.make}
                      </Box>
                    </Flex>
                    <Flex flexBasis="50%">
                      <Box
                        p="4px"
                        flexBasis="100%"
                        border="2px solid"
                        borderLeft="none"
                        borderBottom="none"
                        borderColor="gray.700"
                      >
                        Model: {violation.vehicle.model}
                      </Box>
                    </Flex>
                  </Flex>

                  <Flex>
                    <Flex flexBasis="50%">
                      <Box
                        p="4px"
                        flexBasis="100%"
                        border="2px solid"
                        borderBottom="none"
                        borderColor="gray.700"
                      >
                        Color: {violation.vehicle.color}
                      </Box>
                    </Flex>
                    <Flex flexBasis="50%">
                      <Box
                        p="4px"
                        flexBasis="100%"
                        border="2px solid"
                        borderLeft="none"
                        borderBottom="none"
                        borderColor="gray.700"
                      >
                        License: {violation.vehicle.licensePlate} ST:{' '}
                        {violation.vehicle.state}
                      </Box>
                    </Flex>
                  </Flex>

                  <Flex>
                    <Flex flexBasis="50%">
                      <Box
                        p="4px"
                        flexBasis="100%"
                        border="2px solid"
                        borderBottom="none"
                        borderColor="gray.700"
                      >
                        Reg.Month: ?? YR:
                      </Box>
                    </Flex>
                    <Flex flexBasis="50%">
                      <Box
                        p="4px"
                        flexBasis="100%"
                        border="2px solid"
                        borderLeft="none"
                        borderBottom="none"
                        borderColor="gray.700"
                      >
                        Vehicle towed:{' '}
                        {violation.towCompany.towCompanyId ? 'Yes' : 'No'}
                      </Box>
                    </Flex>
                  </Flex>

                  <Box>
                    <Box
                      p="4px"
                      border="2px solid"
                      borderBottom="none"
                      borderColor="gray.700"
                    >
                      Tow Co. & PH:{' '}
                      {violation.towCompany.towCompanyId &&
                        `${violation.towCompany.name} #${violation.towCompany.telephone}`}
                    </Box>
                  </Box>
                  <Box>
                    <Box p="4px" border="2px solid" borderColor="gray.700">
                      Officer: {violation.officer.officer}
                    </Box>
                  </Box>
                </Box>

                <List mb="16px">
                  {types.map(t => (
                    <ListItem
                      key={t.violationTypeId}
                      border="1px solid"
                      borderColor="gray.700"
                      _notLast={{
                        borderBottom: 'none',
                      }}
                    >
                      <Flex>
                        <Flex
                          justify="center"
                          align="center"
                          flexBasis="5%"
                          borderRight="1px solid"
                          borderColor="gray.700"
                          minW="30px"
                          mr="4px"
                        >
                          {violation.violationTypeId === t.violationTypeId ? (
                            <Icon as={MdOutlineClose} />
                          ) : null}
                        </Flex>
                        <Text>{t.type}</Text>
                      </Flex>
                    </ListItem>
                  ))}
                </List>
                <Flex gap={2}>
                  <Flex gap={2} flexBasis="50%" align="flex-start">
                    <Text>Prior Violations Applicable:</Text>
                    <Box
                      w="40px"
                      borderBottom="1px solid"
                      borderColor="gray.700"
                    >
                      {violation.priorViolations}
                    </Box>
                  </Flex>
                  <Flex gap={2} flexBasis="50%">
                    <Flex
                      justify="center"
                      align="center"
                      border="4px solid"
                      boxSize="45px"
                      flexShrink="0"
                    >
                      {violation.violationsLeft <= 2 ? (
                        <Icon as={MdOutlineClose} w={8} h={8} />
                      ) : null}
                    </Flex>
                    <Box>
                      <Text
                        fontSize="lg"
                        fontWeight="bold"
                        textTransform="uppercase"
                      >
                        final warning
                      </Text>
                      <Text
                        fontSize="sm"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        vehicle may be towed on the next violation
                      </Text>
                    </Box>
                  </Flex>
                </Flex>

                <Box mb="16px">Notes: {violation.details}</Box>

                <Box mb="16px">
                  {violation.media?.thumbImages ? (
                    <ImagesGallery images={violation.media?.thumbImages} />
                  ) : null}
                </Box>

                <Button
                  variant="solid"
                  colorScheme="blue"
                  onClick={handlePrint}
                  sx={{
                    '@media print': {
                      '&,& *': {
                        display: 'none',
                      },
                    },
                  }}
                >
                  <Text fontSize="xs">Print</Text>
                </Button>
              </Box>
            </Box>
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  )
}

export default ViolationDetail
