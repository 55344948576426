import {Box, Alert, AlertIcon} from '@chakra-ui/react'
import * as React from 'react'
import parseAddress from 'parse-address'
import useArcgisMap from '../../../hooks/useArcgisMap'
import {getLocationDefaultValues} from '../../../lib/form/properties'

const MAP_CONTAINER_ID = 'mapContainer'

const PropertiesFormAddress = function ({
  formId,
  currentValues,
  onSaveChanges,
}) {
  const {gpsCoordinates, number, street, city, zipCode} =
    getLocationDefaultValues(currentValues)
  const [lat, lng] = gpsCoordinates ? gpsCoordinates.split(',') : []
  const addres = `${number} ${street}, ${city}, ${zipCode}`

  const search = useArcgisMap(
    MAP_CONTAINER_ID,
    Number(lat),
    Number(lng),
    addres
  )
  const [hasError, setHasError] = React.useState(false)

  const handleFormSubmit = e => {
    e.preventDefault()
    if (!search.selectedResult && !gpsCoordinates) {
      setHasError(true)
      return
    }

    setHasError(false)

    const addressData = {}

    if (search.selectedResult) {
      const parsed = parseAddress.parseLocation(search.selectedResult.name)
      const {latitude, longitude} = search.selectedResult.feature.geometry

      addressData.gpsCoordinates = `${latitude},${longitude}`
      addressData.city = parsed.city
      addressData.street = parsed.street
      addressData.number = parsed.number
      addressData.zipCode = parsed.zip
    } else {
      addressData.gpsCoordinates = gpsCoordinates
      addressData.city = city
      addressData.street = street
      addressData.number = number
      addressData.zipCode = zipCode
    }

    onSaveChanges(addressData)
  }

  return (
    <form id={formId} onSubmit={handleFormSubmit}>
      <Box h="300px" mb={2} rounded="md">
        <Box h="100%" id={MAP_CONTAINER_ID} />
      </Box>
      {hasError ? (
        <Alert status="error" rounded="md">
          <AlertIcon />
          You must locate the property in the map
        </Alert>
      ) : null}
    </form>
  )
}

export default PropertiesFormAddress
