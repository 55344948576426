const shiftPermissionsList = ['shift list', 'shift show']

const sortFields = [
  {
    key: 'officer_schedule_id',
    dir: 'desc',
    label: 'By officer Descending ',
  },
  {
    key: 'officer_schedule_id',
    dir: 'asc',
    label: 'By officer Ascending ',
  },
  {
    key: 'start_time',
    dir: 'desc',
    label: 'By date type Descending',
  },
  {
    key: 'start_time',
    dir: 'asc',
    label: 'By date type Ascending',
  },
]

const getShiftsWithActions = (shifts, user) => {
  const {isAdmin, isOfficer, isPostCommander} = user

  return shifts.map(s => {
    const isWorked = Boolean(s.shiftStarted)
    const isRead = s.userStatus === 'Read' || s.userStatus === 'Unread'
    const isPending = s.scheduleStatus === 'Pending approval'

    return {
      ...s,
      canConfirm: (isOfficer || isPostCommander) && isRead && !isWorked,
      canDecline: (isOfficer || isPostCommander) && isRead && !isWorked,
      canApprove: isAdmin && isPending,
      canReject: isAdmin && isPending,
    }
  })
}

const getShiftProperties = shift => {
  const {properties} = shift
  if (properties.length === 1) {
    return properties[0].propertyName
  }

  const propertiesNames = properties.map(p => p.propertyName)

  return `${propertiesNames.slice(0, propertiesNames.length - 1).join(',')} ${
    propertiesNames[0]
  }`
}

export {
  sortFields,
  getShiftsWithActions,
  getShiftProperties,
  shiftPermissionsList,
}
