import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  Button,
  Icon,
  Text,
  FormControl,
  FormLabel,
  Input,
  Tag,
  TagLabel,
  TagCloseButton,
  useDisclosure,
} from '@chakra-ui/react'
import {useForm} from 'react-hook-form'
import {FaFilter} from 'react-icons/fa'
import {filterEmptyValues} from '../../lib/objects'

const UserFilters = function ({filters, onFilter, onRemoveFilter}) {
  const {isOpen, onOpen, onClose} = useDisclosure()
  const {register, handleSubmit, reset} = useForm({
    defaultValues: {
      ...filters,
    },
  })

  const onSumit = values => {
    const newFilters = filterEmptyValues(values)

    if (Object.keys(newFilters).length) {
      onFilter(newFilters)
      onClose()
    }
  }

  const handleClick = key => {
    onRemoveFilter(key)
    reset({
      [key]: null,
    })
  }

  const handleClearFilters = () => {
    onFilter({})
    onClose()
    reset({
      firstName: null,
      lastName: null,
      userName: null,
    })
  }

  return (
    <>
      <Flex gap={2} align="center">
        {Object.keys(filters).map(key => (
          <Tag
            key={key}
            size="md"
            borderRadius="full"
            variant="subtle"
            colorScheme="blue"
          >
            <TagLabel>{filters[key]}</TagLabel>
            <TagCloseButton onClick={() => handleClick(key)} />
          </Tag>
        ))}
      </Flex>
      <Button
        title="Open filter modal"
        variant="ghost"
        colorScheme="blue"
        onClick={onOpen}
      >
        <Icon as={FaFilter} />
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Filters</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit(onSumit)}>
              <Flex gap={2} mb={2}>
                <FormControl flexBasis="50%">
                  <FormLabel>
                    <Text fontSize="sm">First name</Text>
                  </FormLabel>
                  <Input type="text" {...register('firstName')} />
                </FormControl>
                <FormControl flexBasis="50%">
                  <FormLabel>
                    <Text fontSize="sm">Last name</Text>
                  </FormLabel>
                  <Input type="text" {...register('lastName')} />
                </FormControl>
              </Flex>
              <FormControl mb={4}>
                <FormLabel>
                  <Text fontSize="sm">Username</Text>
                </FormLabel>
                <Input type="text" {...register('userName')} />
              </FormControl>

              <Flex justify="flex-end" gap={2}>
                <Button
                  type="button"
                  variant="outline"
                  colorScheme="blue"
                  onClick={handleClearFilters}
                >
                  <Text fontSize="sm">Clear filters</Text>
                </Button>
                <Button type="submit" variant="solid" colorScheme="blue">
                  <Text fontSize="sm">Apply filters</Text>
                </Button>
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default UserFilters
