import {Flex, Text} from '@chakra-ui/react'
import * as React from 'react'
import {IncidentsTable, IncidentsFilters} from '../components/Incidents'
import {PagePlaceholder} from '../components/Placeholder'
import MobileSort from '../components/MobileSort'
import Acl from '../components/Acl'
import CreateEntityButton from '../components/CreateEntityButton/CreateEntityButton'
import IncidentsFormModal from '../components/Incidents/IncidentsFormModal'
import useFilters from '../hooks/useFilters'
import useSort from '../hooks/useSort'
import usePagination from '../hooks/usePagination'
import {useList} from '../hooks/entities'
import {ENTITIES, ENTITIES_NAMES} from '../lib/entities'
import {
  sortFields,
  incidentPermissionsList,
  incidentPermissionsCreate,
} from '../components/Incidents/Incidents.helpers'

const IncidentsReports = () => {
  const [itemsTotal, setItemsTotal] = React.useState()
  const {pages, pagesCount, currentPage, handlePageChange} =
    usePagination(itemsTotal)
  const [sort, handleSort] = useSort('incident_date')
  const {filters, handleFilters, handleRemoveFilter} = useFilters()
  const {data, isLoading, isSuccess} = useList({
    entity: ENTITIES.incidents,
    currentPage,
    filters,
    sort,
  })

  if (isLoading) {
    return <PagePlaceholder />
  }

  if (isSuccess && itemsTotal !== data?.incidentReport?.total) {
    setItemsTotal(data?.incidentReport?.total)
  }

  return (
    <Flex gap={4} direction="column">
      <Flex align="center">
        <Text fontSize="xl" fontWeight="bold">
          Incidents reports
        </Text>
        <Flex
          gap={2}
          ml="auto"
          align="center"
          direction={{
            base: 'column',
            lg: 'row',
          }}
        >
          <Flex gap={2}>
            <IncidentsFilters
              filters={filters}
              onFilter={handleFilters}
              onRemoveFilter={handleRemoveFilter}
            />
            <MobileSort
              sort={sort}
              sortFields={sortFields}
              onSort={handleSort}
            />
          </Flex>
          <CreateEntityButton
            entity={ENTITIES.incidents}
            entityName={ENTITIES_NAMES.incidents}
            permissionCreate={incidentPermissionsCreate}
            sort={sort}
            filters={filters}
            currentPage={currentPage}
            formModal={IncidentsFormModal}
          />
        </Flex>
      </Flex>
      <IncidentsTable
        items={data?.incidentReport.data}
        sort={sort}
        filters={filters}
        pages={pages}
        itemsTotal={itemsTotal}
        pagesCount={pagesCount}
        currentPage={currentPage}
        onSort={handleSort}
        onPageChange={handlePageChange}
      />
    </Flex>
  )
}

const ProtectedIncidentsReports = () => (
  <Acl withRedirect requiredPermissions={incidentPermissionsList}>
    <IncidentsReports />
  </Acl>
)

export default ProtectedIncidentsReports
