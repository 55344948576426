import * as React from 'react'
import Pusher from 'pusher-js'
import {useAuth} from '../context/auth'

const EVENT_TYPES = {
  break: 'BreakAboutToHappen',
  eos: 'ShiftAboutToEnd',
}

const getPusherInstance = () =>
  new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
    cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
  })

const useSocket = ({channel, eventName}) => {
  const pusherRef = React.useRef()
  const {user} = useAuth()
  const [event, setEvent] = React.useState()

  React.useEffect(() => {
    if (!channel || !eventName) {
      throw Error('missing parameters')
    }

    if (!pusherRef.current) {
      pusherRef.current = getPusherInstance()
    }

    pusherRef.current
      .subscribe(channel)
      .bind(`App\\Events\\${eventName}`, data => {
        if (data.object.user_id === user.userId && !event) {
          setEvent(data)
        }
      })
  }, [channel, eventName, user, event])

  React.useEffect(
    () => () => {
      pusherRef.current.unsubscribe(channel)
    },
    [channel]
  )

  return [event, setEvent]
}

export {EVENT_TYPES}
export default useSocket
