import {
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Checkbox,
} from '@chakra-ui/react'
import * as React from 'react'
import {Controller, useForm} from 'react-hook-form'
import {Select} from '../../Select'
import {getTypeDefaultValues} from '../../../lib/form/properties'
import {isObjectEmpty} from '../../../lib/objects'

const PropertiesFormSections = ({
  formId,
  currentValues,
  types,
  isSubmiting,
  onSaveChanges,
}) => {
  const defaultValues = React.useMemo(
    () =>
      getTypeDefaultValues({
        types,
        currentValues,
      }),
    [currentValues, types]
  )
  const {
    register,
    unregister,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: {errors},
  } = useForm({
    defaultValues,
  })
  const typeRef = React.useRef()
  const typeWatch = watch('propertyTypeId')

  const handleFormSubmit = values => {
    const {
      propertyTypeId: {value: propertyTypeId},
      ...formSections
    } = values
    const sections = Object.keys(formSections).map(k => {
      const sectionId = k.replace('s-', '')
      return {
        sectionId: Number(sectionId),
        granted: formSections[k] === undefined ? 1 : Number(formSections[k]),
      }
    })

    const sectionsData = {
      propertyTypeId,
      sections,
    }

    onSaveChanges(sectionsData)
  }

  React.useEffect(() => {
    if (typeWatch) {
      const hasValues = !isObjectEmpty(currentValues)
      const isEditingCurrentValues =
        hasValues &&
        typeRef.current &&
        typeRef.current.label !== typeWatch.label
      typeRef.current = typeWatch

      typeWatch.sections.forEach(s => {
        const field = `s-${s.sectionId}`
        unregister(field, {keepValue: false})

        const value = isEditingCurrentValues
          ? Boolean(s.mandatory)
          : defaultValues[field] || Boolean(s.mandatory)
        setValue(field, value)
      })
    } else {
      reset()
    }
  }, [typeWatch, reset, unregister, setValue, defaultValues, currentValues])

  return (
    <form id={formId} onSubmit={handleSubmit(handleFormSubmit)}>
      <FormControl
        mb={2}
        isInvalid={errors?.propertyTypeId}
        isDisabled={isSubmiting}
      >
        <FormLabel>Property type</FormLabel>
        <Controller
          control={control}
          name="propertyTypeId"
          rules={{
            validate: ({value} = {}) =>
              value !== undefined || 'You must select a type',
            valueAsNumber: true,
          }}
          render={({field}) => (
            <Select
              isClearable
              isDisabled={isSubmiting}
              placeholder="Select a property type"
              options={types}
              hasError={Boolean(errors?.propertyTypeId)}
              {...field}
            />
          )}
        />
        {errors?.propertyTypeId ? (
          <FormErrorMessage>{errors.propertyTypeId.message}</FormErrorMessage>
        ) : null}
      </FormControl>
      {typeWatch ? (
        <Flex flexWrap="wrap" gap={4}>
          {typeWatch.sections.map(s => (
            <FormControl
              key={`${typeWatch.label}-${s.sectionId}`}
              w="fit-content"
              isDisabled={isSubmiting}
            >
              <FormLabel w="fit-content">{s.label}</FormLabel>
              <Checkbox
                borderColor="gray.400"
                disabled={s.mandatory}
                {...register(`s-${s.sectionId}`)}
              />
            </FormControl>
          ))}
        </Flex>
      ) : null}
    </form>
  )
}

export default PropertiesFormSections
