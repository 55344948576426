import {useAuthMutation, useAuthQuery} from './useAuthQuery'

const KEYS = {
  officers: ['officers'],
  shifts: ['refresh_shifts'],
  logout: ['logout'],
}

const useLogin = () => {
  const endpoint = `/auth/login`
  const mutationState = useAuthMutation({
    endpoint,
  })

  return mutationState
}

const useForgot = () => {
  const endpoint = `/auth/forgot`
  const mutationState = useAuthMutation({
    endpoint,
  })

  return mutationState
}

const useReset = () => {
  const endpoint = `/auth/reset`
  const mutationState = useAuthMutation({
    endpoint,
  })

  return mutationState
}

const useOfficers = ({enabled = false} = {}) => {
  const endpoint = '/officer/get'
  const keys = KEYS.officers
  const queryState = useAuthQuery({
    endpoint,
    keys,
    queryOptions: {
      enabled,
    },
  })

  return queryState
}

const useRefreshShifts = ({enabled = false} = {}) => {
  const endpoint = '/user/shift'
  const keys = KEYS.shifts
  const queryState = useAuthQuery({
    endpoint,
    keys,
    queryOptions: {
      enabled,
    },
  })

  return queryState
}

export {useLogin, useForgot, useReset, useOfficers, useRefreshShifts}
