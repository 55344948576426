import {Flex, Text} from '@chakra-ui/react'
import * as React from 'react'
import {ShiftsTable, ShiftsFilters} from '../components/Shifts'
import {PagePlaceholder} from '../components/Placeholder'
import MobileSort from '../components/MobileSort'
import Acl from '../components/Acl'
import useFilters from '../hooks/useFilters'
import useSort from '../hooks/useSort'
import usePagination from '../hooks/usePagination'
import {useList} from '../hooks/entities'
import {ENTITIES} from '../lib/entities'
import {
  shiftPermissionsList,
  sortFields,
} from '../components/Shifts/Shifts.helpers'

const Shifts = () => {
  const [itemsTotal, setItemsTotal] = React.useState()
  const {pages, pagesCount, currentPage, handlePageChange} =
    usePagination(itemsTotal)
  const [sort, handleSort] = useSort('officer_schedule_id')
  const {filters, handleFilters, handleRemoveFilter} = useFilters()
  const {data, isLoading, isSuccess, isError} = useList({
    entity: ENTITIES.shifts,
    currentPage,
    filters,
    sort,
  })

  if (isError) {
    return null
  }

  if (isLoading) {
    return <PagePlaceholder />
  }

  if (isSuccess && itemsTotal !== data?.officerSchedule?.total) {
    setItemsTotal(data?.officerSchedule?.total)
  }

  return (
    <Flex gap={4} direction="column">
      <Flex align="center">
        <Text fontSize="xl" fontWeight="bold">
          Shifts
        </Text>
        <Flex
          gap={2}
          ml="auto"
          align="center"
          direction={{
            base: 'column',
            lg: 'row',
          }}
        >
          <Flex gap={2}>
            <ShiftsFilters
              filters={filters}
              onFilter={handleFilters}
              onRemoveFilter={handleRemoveFilter}
            />
            <MobileSort
              sort={sort}
              sortFields={sortFields}
              onSort={handleSort}
            />
          </Flex>
        </Flex>
      </Flex>
      <ShiftsTable
        items={data?.officerSchedule.data}
        sort={sort}
        filters={filters}
        pages={pages}
        itemsTotal={itemsTotal}
        pagesCount={pagesCount}
        currentPage={currentPage}
        onSort={handleSort}
        onPageChange={handlePageChange}
      />
    </Flex>
  )
}

const ProtectedShifts = () => (
  <Acl withRedirect requiredPermissions={shiftPermissionsList}>
    <Shifts />
  </Acl>
)

export default ProtectedShifts
