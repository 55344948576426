import * as React from 'react'
import {toast} from 'react-toastify'
import BreakModal from './components/BreakModal'
import BreakTaken from './components/BreakTaken'
import useSocket, {EVENT_TYPES} from '../../hooks/useSocket'

const OfficerBreaks = () => {
  const [event, setEvent] = useSocket({
    channel: 'home',
    eventName: 'BreakAboutToHappenEvent',
  })
  const [isTakingBreak, setIsTakingBreak] = React.useState(false)

  const isBreakEvent = event?.type === EVENT_TYPES.break

  const handleClose = breakTaken => {
    if (breakTaken) {
      setIsTakingBreak(true)
    } else {
      setEvent(null)
    }
  }

  const handleBreakEnded = React.useCallback(() => {
    toast.info("Your break's ended, it's time to get back to work")
    setIsTakingBreak(false)
    setEvent(null)
  }, [setEvent])

  return (
    <>
      {isBreakEvent && !isTakingBreak ? (
        <BreakModal breakEvent={event} onClose={handleClose} />
      ) : null}
      {isBreakEvent && isTakingBreak ? (
        <BreakTaken breakEvent={event} onBreakEnd={handleBreakEnded} />
      ) : null}
    </>
  )
}

export default OfficerBreaks
