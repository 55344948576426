import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  Button,
  Icon,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Tag,
  TagLabel,
  TagCloseButton,
  useDisclosure,
} from '@chakra-ui/react'
import {useForm} from 'react-hook-form'
import {FaFilter} from 'react-icons/fa'
import {filterEmptyValues} from '../../lib/objects'

const DarFilters = function ({filters, onFilter, onRemoveFilter}) {
  const {isOpen, onOpen, onClose} = useDisclosure()
  const {
    register,
    watch,
    handleSubmit,
    formState: {errors},
    reset,
  } = useForm({
    defaultValues: {
      ...filters,
    },
  })
  const dateFromWatch = watch('dateFrom')

  const onSumit = values => {
    const newFilters = filterEmptyValues(values)

    if (Object.keys(newFilters).length) {
      onFilter(newFilters)
      onClose()
    }
  }

  const handleClick = key => {
    onRemoveFilter(key)
    reset({
      [key]: null,
    })
  }

  const handleClearFilters = () => {
    onFilter({})
    onClose()
    reset({
      dateFrom: null,
      dateTo: null,
      details: null,
    })
  }

  return (
    <>
      <Flex gap={2} align="center">
        {Object.keys(filters).map(key => (
          <Tag
            key={key}
            size="md"
            borderRadius="full"
            variant="subtle"
            colorScheme="blue"
          >
            <TagLabel>{filters[key]}</TagLabel>
            <TagCloseButton onClick={() => handleClick(key)} />
          </Tag>
        ))}
      </Flex>
      <Button
        title="Open filter modal"
        variant="ghost"
        colorScheme="blue"
        onClick={onOpen}
      >
        <Icon as={FaFilter} />
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Filters</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit(onSumit)}>
              <Flex gap={2} mb={2}>
                <FormControl flexBasis="50%">
                  <FormLabel>
                    <Text fontSize="sm">Date from</Text>
                  </FormLabel>
                  <Input type="date" {...register('dateFrom')} />
                </FormControl>
                <FormControl flexBasis="50%" isInvalid={errors?.dateTo}>
                  <FormLabel>
                    <Text fontSize="sm">Date to</Text>
                  </FormLabel>
                  <Input
                    type="date"
                    {...register('dateTo', {
                      validate: value => {
                        if (!dateFromWatch || !value) {
                          return true
                        }
                        const dateFrom = new Date(dateFromWatch).getTime()
                        const dateTo = new Date(value).getTime()

                        if (dateTo < dateFrom) {
                          return "'Date to' must be grater than 'Date from'"
                        }

                        return true
                      },
                    })}
                  />
                  {errors?.dateTo ? (
                    <FormErrorMessage>{errors.dateTo.message}</FormErrorMessage>
                  ) : null}
                </FormControl>
              </Flex>
              <FormControl mb={4}>
                <FormLabel>
                  <Text fontSize="sm">Details</Text>
                </FormLabel>
                <Input type="text" {...register('details')} />
              </FormControl>

              <Flex justify="flex-end" gap={2}>
                <Button
                  type="button"
                  variant="outline"
                  colorScheme="blue"
                  onClick={handleClearFilters}
                >
                  <Text fontSize="sm">Clear filters</Text>
                </Button>
                <Button type="submit" variant="solid" colorScheme="blue">
                  <Text fontSize="sm">Apply filters</Text>
                </Button>
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default DarFilters
