const getDefaultHouseholdId = permit => {
  if (!permit?.houseHoldId) {
    return ''
  }

  return {
    value: permit.houseHoldId,
    label: `${permit.householdName} - ${permit.address}`,
  }
}

const getDefaultVehicleId = permit => {
  if (!permit?.vehicleId) {
    return ''
  }

  return {
    value: permit.vehicleId,
    label: permit.licensePlate,
  }
}

const getDefaultValues = permit => {
  const houseHoldId = getDefaultHouseholdId(permit)
  const vehicleId = getDefaultVehicleId(permit)
  return {
    houseHoldId,
    vehicleId,
    startDate: permit?.startDate.split(' ')[0] || '',
    adminOverride: Boolean(permit?.adminOverride || ''),
  }
}

const DAY_MS = 24 * 60 * 60 * 1000

const getToDate = (startDate, quantity) => {
  const startTime = new Date(`${startDate}T00:00:00`).getTime()
  const endDate = new Date(startTime + DAY_MS * quantity)
  return endDate.toISOString().split('T')[0]
}

export {getDefaultValues, getToDate}
