import {Text} from '@chakra-ui/react'
import ConfirmModal from '../ConfirmModal'

const EOSEventModal = ({onPostPone, onLeaveOnTime}) => (
  <ConfirmModal
    isOpen
    title="Your shift is about to end"
    text={
      <Text as="span" fontSize="sm" ml="auto">
        Your shift is about to end. Are you leaving on time or do you want to
        postponed it?
      </Text>
    }
    onConfirm={onPostPone}
    onCancel={onLeaveOnTime}
    confirmLabel="Postpone"
    cancelLabel="Leave on time"
    closeOnlyonCancel
  />
)

export default EOSEventModal
