import * as React from 'react'
import {useAuth} from '../../../context/auth'
import {getHasUserPermissions} from '../../../lib/auth'
import {getScheduleData, schedulePermissionsEdit} from '../Schedules.helpers'
import DesktopCalendar from './DesktopCalendar'
import MobileCalendar from './MobileCalendar'

const Calendar = ({currentWeek, handleEdit, data}) => {
  const {user} = useAuth()
  const [scheduleData, setScheduleData] = React.useState(getScheduleData(data))

  const onEdit = officerScheduleId => {
    const schedule = data.find(
      d => d.officerScheduleId === Number(officerScheduleId)
    )
    if (schedule.shiftStarted) {
      return
    }
    handleEdit(officerScheduleId)
  }

  React.useEffect(() => {
    setScheduleData(getScheduleData(data))
  }, [data])

  const hasEditPemission = getHasUserPermissions(schedulePermissionsEdit, user)

  const {assigned, unassigned} = scheduleData

  return (
    <>
      <DesktopCalendar
        currentWeek={currentWeek}
        unassigned={unassigned}
        assigned={assigned}
        hasEditPemission={hasEditPemission}
        onEdit={onEdit}
      />
      <MobileCalendar
        currentWeek={currentWeek}
        unassigned={unassigned}
        assigned={assigned}
        hasEditPemission={hasEditPemission}
        onEdit={onEdit}
      />
    </>
  )
}

export default Calendar
