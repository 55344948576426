import {useAuthQuery} from './useAuthQuery'

const KEYS = {
  properties: ['properties'],
  towCompanies: ['tow-companies'],
  types: ['property-types'],
}

const useProperties = ({enabled = false} = {}) => {
  const queryState = useAuthQuery({
    keys: KEYS.properties,
    endpoint: '/property',
    queryOptions: {
      enabled,
    },
  })

  return queryState
}

const useTowCompanies = ({enabled = false} = {}) => {
  const queryState = useAuthQuery({
    keys: KEYS.towCompanies,
    endpoint: '/towcompany',
    queryOptions: {
      enabled,
    },
  })

  return queryState
}

const usePropertyTypes = ({enabled = false} = {}) => {
  const queryState = useAuthQuery({
    keys: KEYS.types,
    endpoint: '/propertytype',
    queryOptions: {
      enabled,
    },
  })

  return queryState
}

export {useProperties, useTowCompanies, usePropertyTypes}
