import * as React from 'react'
import {Flex, Button} from '@chakra-ui/react'
import {useQuery} from 'react-query'
import {useLocation, useNavigate} from 'react-router-dom'
import {authFetcher} from '../lib/fetcher'
import {useAuth} from '../context/auth'
import {getUserData} from '../lib/auth'

const Auth = () => {
  const {handleUser} = useAuth()
  const router = useLocation()
  const navigate = useNavigate()
  const token = router.search.split('token=')[1]
  const {data, isSuccess, isError} = useQuery(
    ['authlogin'],
    () => authFetcher(token),
    {
      retry: false,
    }
  )

  React.useEffect(() => {
    if (isError) {
      navigate('auth/login')
    }
    if (isSuccess) {
      handleUser(getUserData(data))
      navigate('/shifts')
    }
  }, [data, isSuccess, isError, navigate, handleUser])

  return (
    <Flex justify="center" mt={4}>
      <Button
        variant="solid"
        colorScheme="blue"
        isLoading
        loadingText="Login in"
      />
    </Flex>
  )
}

export default Auth
