import {
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useToast,
} from '@chakra-ui/react'
import {useDelete} from '../../hooks/entities'

const DeleteModal = ({
  id,
  sort,
  entity,
  filters,
  entityKey,
  entityName,
  currentPage,
  isOpen,
  onClose,
}) => {
  const {mutate, isLoading} = useDelete({
    id,
    entity,
    currentPage,
    filters,
    sort,
  })
  const toast = useToast({
    position: 'top',
    isClosable: true,
  })

  const handleConfirm = () => {
    const deleteItem = {
      [entityKey]: id,
    }
    mutate(deleteItem, {
      onSuccess() {
        toast({
          title: `${entityName} Deleted!`,
          status: 'success',
        })
        onClose()
      },
      onError() {
        toast({
          title: `Error deleting ${entityName}`,
          status: 'error',
        })
      },
    })
  }

  const handleCancel = () => {
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Confirm deletion</ModalHeader>
        <ModalCloseButton />
        <ModalBody>Are you sure you want to delete it?</ModalBody>
        <ModalFooter>
          <Flex gap={2}>
            <Button
              variant="outline"
              onClick={handleCancel}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              variant="solid"
              colorScheme="blue"
              onClick={handleConfirm}
              isLoading={isLoading}
            >
              Confirm
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default DeleteModal
