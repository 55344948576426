import {useDisclosure, useToast} from '@chakra-ui/react'
import * as React from 'react'
import {useNavigate} from 'react-router-dom'
import SchedulesFormModal from '../components/Schedules/SchedulesFormModal'
import ConfirmModal from '../components/ConfirmModal'
import {useAuth} from '../context/auth'
import {usePost} from '../hooks/entities'
import {getFullDateToDb} from '../lib/form'
import {ENTITIES} from '../lib/entities'
import {usePosition} from '../context/position'

const OfficerShift = () => {
  const navigate = useNavigate()
  const {getCoordinates} = usePosition()
  const {user, handleUser, handleLogout} = useAuth()
  const {isOpen: isConfirmModalOpen, onOpen, onClose} = useDisclosure()
  const {mutate, isLoading} = usePost({
    entity: ENTITIES.officerSchedules,
  })
  const toast = useToast({
    position: 'top',
    isClosable: true,
  })
  const handlePost = async scheduleData => {
    const {latitude, longitude} = await getCoordinates()
    const gpsCoordinates = `${latitude},${longitude}`
    mutate(
      {
        ...scheduleData,
        gpsCoordinates,
      },
      {
        onSuccess({data: [shifts]}) {
          toast({
            title: 'Officer schedule created!',
            status: 'success',
          })
          handleUser({...user, shifts})
        },
        onError() {
          toast({
            title: 'Error creating officer schedule',
            status: 'error',
          })
        },
      }
    )
  }

  const handleCancel = () => {
    onOpen()
  }

  const handleLeave = () => {
    handleLogout()
  }

  const handleStay = () => {
    onClose()
  }

  React.useEffect(() => {
    if (user?.shifts?.officerScheduleId) {
      navigate('/')
    }
  }, [navigate, user])

  const defaultSchedule = {
    startTime: getFullDateToDb(),
    officerId: user.userId,
  }

  return (
    <>
      <SchedulesFormModal
        isOpen
        isSubmiting={isLoading}
        defaultSchedule={defaultSchedule}
        onClose={handleCancel}
        onSubmit={handlePost}
      />
      <ConfirmModal
        closeOnlyonCancel
        isOpen={isConfirmModalOpen}
        title="Shift warning"
        text="You can't continue without a shift, otherwise you will be logout of the application."
        cancelLabel="Leave"
        confirmLabel="Stay"
        onCancel={handleLeave}
        onConfirm={handleStay}
        blockScrollOnMount={false}
      />
    </>
  )
}

export default OfficerShift
