import {
  Box,
  Flex,
  Text,
  Button,
  Icon,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  useBreakpointValue,
} from '@chakra-ui/react'
import {MdModeEdit} from 'react-icons/md'
import {
  getItemDetail,
  getItemType,
  STORE_STATUS,
} from '../../../lib/offlineStore'

const OfflineMobileTable = ({
  isLoading,
  items,
  vehiclesData,
  darData,
  violationTypesData,
  incidentTypesData,
  onEdit,
}) => {
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  })

  if (isDesktop) {
    return null
  }

  return (
    <Box
      boxShadow="lg"
      display={{
        lg: 'none',
      }}
    >
      <Flex fontSize="sm" px={4} py={2}>
        <Flex gap={2} flex="1">
          <Text fontWeight="bold" flexBasis="50%">
            Type
          </Text>
          <Text fontWeight="bold" flexBasis="50%">
            Status
          </Text>
          <Box boxSize="1rem" />
        </Flex>
      </Flex>
      <Accordion allowToggle>
        {items.map(item => (
          <AccordionItem key={item.tempId}>
            <AccordionButton fontSize="sm">
              <Flex flex="1" textAlign="start" gap={2} align="center">
                <Text flexBasis="50%">{getItemType(item.type)}</Text>
                <Text flexBasis="50%">{item.status}</Text>
              </Flex>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel
              fontSize="sm"
              position="relative"
              overflow="visible"
            >
              <Flex align="center">
                <Flex direction="column" gap={2} mb={2} flex="1">
                  <Box>
                    <Text fontWeight="bold" as="span" mr={2}>
                      Details:
                    </Text>
                    {getItemDetail(
                      item,
                      vehiclesData,
                      darData,
                      violationTypesData,
                      incidentTypesData
                    )}
                  </Box>
                  <Box>
                    <Text fontWeight="bold" as="span" mr={2}>
                      Error:
                    </Text>
                    {item.error || '-'}
                  </Box>
                </Flex>

                <Flex direction="column" justify="end" gap={2} align="center">
                  {item.status === STORE_STATUS.pending ? (
                    <Button
                      size="sm"
                      variant="ghost"
                      isLoading={isLoading}
                      onClick={() => onEdit(item)}
                    >
                      <Icon as={MdModeEdit} w={6} h={6} />
                    </Button>
                  ) : null}
                </Flex>
              </Flex>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  )
}

export default OfflineMobileTable
