import {
  Flex,
  Text,
  Button,
  Icon,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalBody,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import * as React from 'react'
import {MdImportExport} from 'react-icons/md'
import WeekPicker from './WeekPicker'
import {formatDate} from '../../lib/formatters'
import {
  getCurrentWeek,
  getDatesOfWeek,
  getDefaultWeek,
} from '../../lib/form/schedules'
import {getDateToDb} from '../../lib/form'
import {useImportWeek} from '../../hooks/schedules'
import {ENTITIES} from '../../lib/entities'

const ImportWeekModal = ({filters}) => {
  const {isOpen, onOpen, onClose} = useDisclosure()
  const [originWeek, setOriginWeek] = React.useState({
    week: getDefaultWeek(),
    dates: getCurrentWeek(new Date()),
  })
  const [destinationWeek, setDestinationWeek] = React.useState({})
  const {mutate, reset, isLoading} = useImportWeek({
    entity: ENTITIES.officerSchedules,
    filters,
  })
  const toast = useToast({
    position: 'top',
    isClosable: true,
  })

  const handleOriginWeekChange = week => {
    const dates = getDatesOfWeek(week)
    setOriginWeek({
      week: week[1],
      dates,
    })
  }

  const handleDestionationWeekChange = week => {
    const dates = getDatesOfWeek(week)
    setDestinationWeek({
      week: week[1],
      dates,
    })
  }

  const handleImportOpen = () => {
    onOpen()
  }

  const handleImportClose = () => {
    onClose()
  }

  const handleImportWeek = () => {
    const weekData = {
      from: getDateToDb(originWeek.dates[0], {withHours: false}),
      to: getDateToDb(originWeek.dates.at(-1), {withHours: false}),
      copy_from: getDateToDb(destinationWeek.dates[0], {withHours: false}),
    }

    mutate(weekData, {
      onSuccess() {
        toast({
          title: "The week's been imported successfully!",
          status: 'success',
        })
        handleImportClose()
        reset()
      },
      onError() {
        toast({
          title: "Oops, couldn't import the selected week",
          status: 'error',
        })
      },
    })
  }

  return (
    <>
      <Button variant="solid" colorScheme="blue" onClick={handleImportOpen}>
        <Icon as={MdImportExport} />
      </Button>
      {isOpen ? (
        <Modal isOpen={isOpen} isCentered onClose={handleImportClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Import schedule</ModalHeader>
            <ModalBody>
              <Flex direction="column">
                <Flex direction="column">
                  Import from:
                  <Flex align="center" gap={4}>
                    <WeekPicker
                      handleWeekChange={handleOriginWeekChange}
                      currentWeek={originWeek.week}
                    />
                    <Text fontSize="sm">
                      {formatDate(originWeek.dates[0], {withHours: false})} -{' '}
                      {formatDate(
                        originWeek.dates[originWeek.dates.length - 1],
                        {
                          withHours: false,
                        }
                      )}
                    </Text>
                  </Flex>
                </Flex>
                <Flex direction="column">
                  Import to:
                  <Flex align="center" gap={4}>
                    <WeekPicker
                      handleWeekChange={handleDestionationWeekChange}
                      currentWeek={destinationWeek.week}
                    />
                    {destinationWeek?.week ? (
                      <Text fontSize="sm">
                        {formatDate(destinationWeek.dates[0], {
                          withHours: false,
                        })}{' '}
                        -{' '}
                        {formatDate(
                          destinationWeek.dates[
                            destinationWeek.dates.length - 1
                          ],
                          {
                            withHours: false,
                          }
                        )}
                      </Text>
                    ) : (
                      '-'
                    )}
                  </Flex>
                </Flex>
              </Flex>
              <Flex justify="flex-end" gap={2}>
                <Button
                  variant="outline"
                  colorScheme="blue"
                  type="button"
                  onClick={handleImportClose}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <Button
                  variant="solid"
                  colorScheme="blue"
                  type="button"
                  onClick={handleImportWeek}
                  isLoading={isLoading}
                >
                  Import
                </Button>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      ) : null}
    </>
  )
}
export default ImportWeekModal
