import {Flex, Box, Text, Button, Icon, Skeleton} from '@chakra-ui/react'
import {MdModeEdit, MdDelete} from 'react-icons/md'
import {useItem} from '../../../hooks/entities'
import {ENTITIES, ENTITIES_KEYS} from '../../../lib/entities'
import {formatDate} from '../../../lib/formatters'

const UsersMobileItem = ({
  isExpanded,
  userId,
  canEdit,
  canDelete,
  onEdit,
  onDelete,
}) => {
  const {data, isLoading} = useItem({
    entity: ENTITIES.users,
    id: isExpanded ? userId : undefined,
    entityKey: ENTITIES_KEYS.users,
  })

  if (!isExpanded) {
    return null
  }

  if (isLoading) {
    return (
      <Flex>
        <Skeleton w="100%" h="150px" />
      </Flex>
    )
  }

  const {data: user} = data

  return (
    <Flex align="center">
      <Flex direction="column" gap={2} mb={2} flex="1">
        <Box>
          <Text fontWeight="bold" as="span" mr={2}>
            Created at:
          </Text>
          {formatDate(user.createdAt)}
        </Box>
        {user.properties.length ? (
          <Box>
            <Text fontWeight="bold" as="span" mr={2}>
              Properties:
            </Text>
            {user.properties.map(p => p.name).join(', ')}
          </Box>
        ) : null}
      </Flex>
      <Flex direction="column" justify="end" gap={2} align="center">
        {canEdit ? (
          <Button size="sm" variant="ghost" onClick={() => onEdit(user.userId)}>
            <Icon as={MdModeEdit} w={6} h={6} />
          </Button>
        ) : null}
        {canDelete ? (
          <Button
            size="sm"
            variant="ghost"
            colorScheme="red"
            onClick={() => onDelete(user.userId)}
          >
            <Icon as={MdDelete} w={6} h={6} />
          </Button>
        ) : null}
      </Flex>
    </Flex>
  )
}

export default UsersMobileItem
