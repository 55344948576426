import * as React from 'react'
import {usePagination as useAjnaPagination} from '@ajna/pagination'
import {useUserProperties} from '../context/property'
import {ITEMS_PER_PAGE} from '../lib/entities'

const usePagination = itemsTotal => {
  const {currentProperty} = useUserProperties()
  const {pages, pagesCount, currentPage, setCurrentPage} = useAjnaPagination({
    total: itemsTotal,
    limits: {
      outer: 2,
      inner: 2,
    },
    initialState: {
      pageSize: ITEMS_PER_PAGE,
      isDisabled: false,
      currentPage: 1,
    },
  })

  React.useEffect(() => {
    setCurrentPage(1)
  }, [currentProperty, setCurrentPage])

  const handlePageChange = page => {
    setCurrentPage(page)
  }

  return {
    pages,
    pagesCount,
    currentPage,
    handlePageChange,
  }
}

export default usePagination
