import {objToCamelCase, objToSnakeCase} from './formatters'

const {REACT_APP_API_URL} = process.env

const getPromise = (endpoint, user, options = {}) => {
  const config = {
    method: 'GET',
    ...options,
    headers: {
      ...options?.headers,
      Authorization: user ? `Bearer ${user.accessToken}` : undefined,
      'Content-type': 'application/json',
      Accept: 'application/json',
    },
  }

  if (options.body) {
    config.method = options.method || 'POST'
    config.body = JSON.stringify(objToSnakeCase(options.body))
  }

  const url = `${REACT_APP_API_URL}${endpoint}`
  return fetch(url, config)
    .then(response => {
      if (!response.ok) {
        throw response
      }

      return response.json()
    })
    .then(data => objToCamelCase(data))
}

const authFetcher = token => {
  if (!token) {
    return Promise.reject(new Error('No token provided'))
  }

  const url = `${REACT_APP_API_URL}/auth/officer_token?token=${token}`
  return fetch(url)
    .then(response => {
      if (!response.ok) {
        throw response
      }

      return response.json()
    })
    .then(data => objToCamelCase(data))
}

export {getPromise, authFetcher}
