import * as React from 'react'
import {useAuth} from '../context/auth'
import {useUserProperties} from '../context/property'
import {getHasUserPermissions} from '../lib/auth'
import {NAVBAR_GROUPS} from '../lib/sections'

const useSections = () => {
  const {user} = useAuth()
  const [sections, setSections] = React.useState([])
  const {currentProperty} = useUserProperties()

  React.useEffect(() => {
    const hiredTypes =
      currentProperty?.propertyHiredType
        .filter(({granted}) => granted)
        .map(({permissions, ...props}) => ({
          ...props,
          permissions: [permissions],
        })) ?? []

    const _sections = NAVBAR_GROUPS.map(group => ({
      ...group,
      items: [
        ...group.items,
        ...hiredTypes.filter(ht => group.groupSections.includes(ht.path)),
      ]
        .filter(i => getHasUserPermissions(i.permissions, user))
        .sort((a, b) => (a.position > b.position ? 1 : -1)),
    }))

    setSections(_sections)
  }, [user, currentProperty])

  return sections
}

export default useSections
