const getDefaultCode = (poolReport, codes) => {
  if (!poolReport) {
    return ''
  }

  return codes.find(c => c.value === poolReport.dailyActivityReportCodeId)
}

const getDefaultType = (poolReport, types) => {
  if (!poolReport) {
    return ''
  }

  return types.find(t => t.value === poolReport.incidentReportTypeId)
}

const getDefaultPool = (poolReport, pools) => {
  if (!poolReport) {
    return ''
  }

  return pools.find(p => p.value === poolReport.propertyPool.propertyPoolId)
}

const getDefaultResidents = poolReport => {
  if (!poolReport) {
    return null
  }

  return poolReport.poolDetails?.residents?.map(r => ({
    value: r.userId,
    label: `${r.firstName} ${r.lastName}`,
  }))
}

const getDefaultValues = ({poolReport, codes, types, pools}) => {
  const dailyActivityReportCodeId = getDefaultCode(poolReport, codes)
  const incidentReportTypeId = getDefaultType(poolReport, types)
  const propertyPoolId = getDefaultPool(poolReport, pools)
  const residents = getDefaultResidents(poolReport)
  return {
    dailyActivityReportCodeId,
    incidentReportTypeId,
    propertyPoolId,
    residents,
    details: poolReport?.details || '',
  }
}

export {getDefaultValues}
