import {
  Box,
  Flex,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  useBreakpointValue,
} from '@chakra-ui/react'
import UsersMobileItem from './UsersMobileItem'

const UsersMobileTable = ({users, onEdit, onDelete}) => {
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  })

  if (isDesktop) {
    return null
  }

  return (
    <Box
      boxShadow="lg"
      display={{
        lg: 'none',
      }}
    >
      <Flex fontSize="sm" px={4} py={2}>
        <Flex gap={2} flex="1">
          <Text fontWeight="bold" flexBasis="50%">
            Username
          </Text>
          <Text fontWeight="bold" flexBasis="50%">
            Type
          </Text>
          <Box boxSize="1rem" />
        </Flex>
      </Flex>
      <Accordion allowToggle>
        {users.map(user => (
          <AccordionItem key={user.userId}>
            {({isExpanded}) => (
              <>
                <AccordionButton fontSize="sm">
                  <Flex flex="1" textAlign="start" gap={2} align="center">
                    <Text flexBasis="50%">{user.userName}</Text>
                    <Box flexBasis="50%">{user.roleName}</Box>
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel
                  fontSize="sm"
                  position="relative"
                  overflow="visible"
                >
                  <UsersMobileItem
                    isExpanded={isExpanded}
                    canEdit={user.canEdit}
                    canDelete={user.canDelete}
                    userId={user.userId}
                    onEdit={onEdit}
                    onDelete={onDelete}
                  />
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  )
}

export default UsersMobileTable
