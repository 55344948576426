import {getEntitiesWithActions} from '../../lib/entities'

const darPermissionsList = ['dar list', 'dar show']
const darPermissionsCreate = ['dar create', 'dar store']
const darPermissionsEdit = ['dar update']
const darPermissionsMutate = [...darPermissionsCreate, ...darPermissionsEdit]
const darPermissionsDelete = ['dar delete']

const canEditValidation = entity => entity.automaticDar === 0

const canDeleteValidation = entity => entity.automaticDar === 0

const getDarsWithActions = (dars, user) =>
  getEntitiesWithActions(
    dars,
    user,
    darPermissionsEdit,
    darPermissionsDelete,
    canEditValidation,
    canDeleteValidation
  )

const sortFields = [
  {
    key: 'dar_date',
    dir: 'desc',
    label: 'By date descending',
  },
  {
    key: 'dar_date',
    dir: 'asc',
    label: 'By date ascending',
  },
  {
    key: 'user_id',
    dir: 'desc',
    label: 'By user descending',
  },
  {
    key: 'user_id',
    dir: 'asc',
    label: 'By user ascending',
  },
  {
    key: 'code_type',
    dir: 'desc',
    label: 'By code descending',
  },
  {
    key: 'code_type',
    dir: 'asc',
    label: 'By code ascending',
  },
]

export {
  darPermissionsCreate,
  darPermissionsList,
  darPermissionsDelete,
  darPermissionsEdit,
  darPermissionsMutate,
  getDarsWithActions,
  sortFields,
}
