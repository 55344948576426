import {formatDate} from '../../lib/formatters'

const schedulePermissionsList = ['officerschedule list', 'officerschedule show']
const schedulePermissionsCreate = [
  'officerschedule create',
  'officerschedule store',
]
const schedulePermissionsEdit = ['officerschedule update']
const schedulePermissionsDelete = ['officerschedule delete']

const DAYS_INDEX = ['0', '1', '2', '3', '4', '5', '6']

const getDatesOfWeek = value => {
  if (!value) return []
  const [year, week] = value
  const currentDay = (week - 1) * 7 - 5 // 1st of January + 7 days for each week
  const firstDayOfWeek = new Date(year, 0, currentDay)

  const dates = [firstDayOfWeek]

  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < 7; i++) {
    const weekDate = new Date(firstDayOfWeek)
    weekDate.setDate(weekDate.getDate() + i)
    dates.push(weekDate)
  }
  return dates
}

const getScheduleData = data => {
  const scheduleData = data
    .sort((a, b) => {
      const aDate = new Date(a.startTime).getTime()
      const bDate = new Date(b.endTime).getTime()

      return aDate > bDate ? 1 : -1
    })
    .map(d => ({
      ...d,
      startTime: formatDate(d.startTime),
      endTime: formatDate(d.endTime),
    }))
    .reduce((acc, d) => {
      const day = new Date(d.startTime).getDay()

      const key = d.officerName || 'unassigned'

      if (!(key in acc)) {
        acc[key] = {}
      }

      if (!(day in acc[key])) {
        acc[key][day] = []
      }

      acc[key][day].push({
        ...d,
        startTime: d.startTime.split(',')[1],
        endTime: d.endTime.split(',')[1],
      })
      return acc
    }, {})

  Object.keys(scheduleData).forEach(officer => {
    const officerWeekSchedDaysIndex = Object.keys(scheduleData[officer])
    const officerWeekSchedDays = officerWeekSchedDaysIndex.length
    if (officerWeekSchedDays < 7) {
      const missingDaysIndex = DAYS_INDEX.filter(
        d => !officerWeekSchedDaysIndex.includes(d)
      )
      missingDaysIndex.forEach(d => {
        scheduleData[officer][d] = '-'
      })
    }
  })

  const {unassigned, ...assigned} = scheduleData
  return {unassigned: unassigned ?? {}, assigned: assigned ?? {}}
}

export {
  schedulePermissionsList,
  schedulePermissionsCreate,
  schedulePermissionsEdit,
  schedulePermissionsDelete,
  getDatesOfWeek,
  getScheduleData,
}
