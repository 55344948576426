import * as React from 'react'
import {useQuery, useMutation, useQueryClient} from 'react-query'
import {useAuth} from '../context/auth'
import {useUserProperties} from '../context/property'
import {getPromise} from '../lib/fetcher'
import useConnectionStatus from './useConnectionStatus'

const useAuthQuery = ({
  keys,
  endpoint,
  options,
  queryOptions: {includeProperty = true, enabled = true, ...queryOptions} = {},
}) => {
  const {user, clearUser} = useAuth()
  const {currentProperty} = useUserProperties()
  const isOnline = useConnectionStatus()
  const queryKeys = React.useMemo(
    () => [...keys, currentProperty?.propertyId],
    [currentProperty, keys]
  )

  const separator = endpoint.includes('?') ? '&' : '?'
  const propertyParam = `${separator}context_property_id=${currentProperty?.propertyId}`
  const path = includeProperty ? `${endpoint}${propertyParam}` : endpoint

  const queryState = useQuery(
    queryKeys,
    () => getPromise(path, user, options),
    {
      retry: false,
      enabled: isOnline && enabled,
      ...queryOptions,
    }
  )

  React.useEffect(() => {
    if (queryState.isError && queryState.error.status === 401) {
      clearUser()
    }
  }, [clearUser, queryState])

  return queryState
}

const useAuthMutation = ({endpoint, invalidationKeys, method}) => {
  const queryClient = useQueryClient()
  const {user, clearUser} = useAuth()

  const mutationState = useMutation(
    mutateData => getPromise(endpoint, user, {method, body: mutateData}),
    {
      onSuccess() {
        if (invalidationKeys?.length) {
          invalidationKeys.forEach(invalidation => {
            queryClient.invalidateQueries(invalidation)
          })
        }
      },
    }
  )

  React.useEffect(() => {
    if (mutationState.isError && mutationState.error.status === 401) {
      clearUser()
    }
  }, [clearUser, mutationState])

  return mutationState
}

export {useAuthQuery, useAuthMutation}
