import {getHasUserPermissions} from './auth'

const ITEMS_PER_PAGE = 10

const ENTITIES = {
  dar: 'dailyactivityreport',
  users: 'user',
  properties: 'property',
  towCompanies: 'towCompanies',
  incidents: 'incidentreport',
  households: 'household',
  vehicles: 'vehicle',
  permits: 'paperlesspermit',
  violations: 'vehicleviolation',
  pools: 'property_pool',
  poolreports: 'poolreport',
  officerSchedules: 'officerschedule',
  briefings: 'briefingboard',
  shifts: 'shift',
}

const ENTITIES_NAMES = {
  dar: 'DAR',
  users: 'User',
  properties: 'Property',
  incidents: 'Incident report',
  households: 'Households',
  vehicles: 'Vehicle',
  permits: 'Paperless permit',
  violations: 'Vehicle violation',
  pools: 'Property pool',
  poolreports: 'Pool report',
  officerSchedules: 'Officer schedule',
  briefings: 'Briefing board',
  shifts: 'shift',
}

const ENTITIES_KEYS = {
  dar: 'dailyActivityReportId',
  users: 'userId',
  properties: 'propertyId',
  towCompanies: 'towCompanyId',
  incidents: 'incidentReportId',
  households: 'houseHoldId',
  vehicles: 'vehicleId',
  permits: 'paperlessPermitId',
  violations: 'vehicleViolationId',
  pools: 'propertyPoolId',
  poolreports: 'poolReportId',
  officerSchedules: 'officerScheduleId',
  briefings: 'briefingBoardId',
  shifts: 'officerScheduleId',
}

const makeGetCanEditEntity =
  (user, entityPermissionsEdit, editValidation) => entity => {
    const canEdit = editValidation ? editValidation(entity) : true

    return canEdit ? getHasUserPermissions(entityPermissionsEdit, user) : false
  }

const makeGetCanDeleteEntity =
  (user, entityPermissionsDelete, deleteValidation) => entity => {
    const canDelete = deleteValidation ? deleteValidation(entity) : true

    return canDelete
      ? getHasUserPermissions(entityPermissionsDelete, user)
      : false
  }

const makeGetEntityActions = (
  user,
  editPermission,
  deletePermission,
  editValidation,
  deleteValidation
) => {
  const getCanEditEntity = makeGetCanEditEntity(
    user,
    editPermission,
    editValidation
  )
  const getCanDeleteEntity = makeGetCanDeleteEntity(
    user,
    deletePermission,
    deleteValidation
  )

  return entity => {
    const canEdit = getCanEditEntity(entity)
    const canDelete = getCanDeleteEntity(entity)

    return {
      canEdit,
      canDelete,
    }
  }
}

const getEntitiesWithActions = (
  entities,
  user,
  editPermission,
  deletePermission,
  editValidation,
  deleteValidation
) => {
  try {
    const getEntityActions = makeGetEntityActions(
      user,
      editPermission,
      deletePermission,
      editValidation,
      deleteValidation
    )
    return entities.map(entity => ({
      ...entity,
      ...getEntityActions(entity),
    }))
  } catch (e) {
    // do nothing
  }
}
export {
  ITEMS_PER_PAGE,
  ENTITIES,
  ENTITIES_NAMES,
  ENTITIES_KEYS,
  getEntitiesWithActions,
}
