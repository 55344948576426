import {
  Flex,
  Icon,
  Text,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Skeleton,
} from '@chakra-ui/react'
import {MdClose} from 'react-icons/md'
import IncidentsForm from './IncidentsForm'
import {useDarCodes} from '../../hooks/dar'
import {useIncidentTypes} from '../../hooks/incidents'
import {useItem} from '../../hooks/entities'
import {ENTITIES, ENTITIES_KEYS} from '../../lib/entities'

const IncidentsFormModal = function ({
  incidentId,
  itemData,
  isOpen,
  isSubmiting,
  onClose,
  onSubmit,
}) {
  const {data: typeData, isLoading: isLoadingTypes} = useIncidentTypes({
    enabled: isOpen,
  })
  const {data: codesData, isLoading: isLoadingCodes} = useDarCodes({
    enabled: isOpen,
  })
  const {
    data: incidentData,
    isLoading: isLoadingIncident,
    isFetching,
    isRefetching,
  } = useItem({
    id: incidentId,
    entity: ENTITIES.incidents,
    entityKey: ENTITIES_KEYS.incidents,
  })

  const isLoading =
    isLoadingTypes ||
    isLoadingCodes ||
    isLoadingIncident ||
    isFetching ||
    isRefetching

  const types = typeData
    ? typeData.incidentReportTypes.map(({incidentReportTypeId, type}) => ({
        value: incidentReportTypeId,
        label: type,
      }))
    : []

  const codes = codesData
    ? codesData.dailyActivityReportCodes.map(
        ({dailyActivityReportCodeId, type}) => ({
          value: dailyActivityReportCodeId,
          label: type,
        })
      )
    : []

  const incident = itemData || incidentData?.data

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      size={{
        base: 'full',
        lg: 'lg',
      }}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader borderBottom="1px solid" borderColor="gray.400">
          <Flex align="center" gap={2}>
            <Button p="0" variant="ghost" onClick={onClose}>
              <Icon as={MdClose} w="24px" h="24px" />
            </Button>
            <Text>{incidentId ? 'Update' : 'Create'} Incident report</Text>
            <Button
              ml="auto"
              type="submit"
              form="incidents-form"
              variant="solid"
              colorScheme="blue"
              disabled={isLoading}
              isLoading={isSubmiting}
            >
              {incidentId ? 'Update' : 'Create'}
            </Button>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          {isLoading ? (
            <Skeleton h="200px" />
          ) : (
            <IncidentsForm
              incident={incident}
              types={types}
              codes={codes}
              onSubmit={onSubmit}
              isSubmiting={isSubmiting}
            />
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default IncidentsFormModal
