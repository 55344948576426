import {
  Box,
  Flex,
  Text,
  Button,
  Icon,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  useBreakpointValue,
} from '@chakra-ui/react'
import {MdOutlineCheck, MdOutlineClose} from 'react-icons/md'
import {formatDate} from '../../../lib/formatters'
import {getShiftProperties} from '../Shifts.helpers'

const UsersMobileTable = ({
  shifts,
  onConfirm,
  onReject,
  onApprove,
  onDecline,
}) => {
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  })

  if (isDesktop) {
    return null
  }

  return (
    <Box
      boxShadow="lg"
      display={{
        lg: 'none',
      }}
    >
      <Flex fontSize="sm" px={4} py={2}>
        <Flex gap={2} flex="1">
          <Text fontWeight="bold" flexBasis="33%">
            Property
          </Text>
          <Text fontWeight="bold" flexBasis="33%">
            Officer
          </Text>
          <Text fontWeight="bold" flexBasis="33%">
            Shift date
          </Text>
          <Box boxSize="1rem" />
        </Flex>
      </Flex>
      <Accordion allowToggle>
        {shifts.map(shift => (
          <AccordionItem key={shift.officerScheduleId}>
            <AccordionButton fontSize="sm">
              <Flex flex="1" textAlign="start" gap={2} align="center">
                <Text flexBasis="33%">{getShiftProperties(shift)}</Text>
                <Text flexBasis="33%">{shift.officerName}</Text>
                <Text flexBasis="33%">{formatDate(shift.startTime)}</Text>
              </Flex>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel
              fontSize="sm"
              position="relative"
              overflow="visible"
            >
              <Flex align="center">
                <Flex direction="column" gap={2} mb={2} flex="1">
                  <Box>
                    <Text fontWeight="bold" as="span" mr={2}>
                      Status:
                    </Text>
                    Manager: {shift.scheduleStatus} - Officer:{' '}
                    {shift.userStatus}
                  </Box>
                </Flex>
                <Flex direction="column" gap={2} mb={2} flex="1">
                  <Box>
                    <Text fontWeight="bold" as="span" mr={2}>
                      Worked hs:
                    </Text>
                    Estimated: {shift.timeExpectedToBeWork} - Worked:{' '}
                    {shift.timeWorked || '-'}
                  </Box>
                </Flex>
                <Flex direction="column" justify="end" gap={2} align="center">
                  {shift.canConfirm ? (
                    <Button
                      variant="ghost"
                      colorScheme="green"
                      title="Confirm shift"
                      onClick={() => onConfirm(shift.officerScheduleId)}
                    >
                      <Icon as={MdOutlineCheck} w={6} h={6} />
                    </Button>
                  ) : null}
                  {shift.canDecline ? (
                    <Button
                      variant="ghost"
                      colorScheme="red"
                      title="Reject shift"
                      onClick={() => onDecline(shift.officerScheduleId)}
                    >
                      <Icon as={MdOutlineClose} w={6} h={6} />
                    </Button>
                  ) : null}
                  {shift.canApprove ? (
                    <Button
                      variant="ghost"
                      colorScheme="green"
                      title="Approve shift"
                      onClick={() => onApprove(shift.officerScheduleId)}
                    >
                      <Icon as={MdOutlineCheck} w={6} h={6} />
                    </Button>
                  ) : null}
                  {shift.canReject ? (
                    <Button
                      variant="ghost"
                      colorScheme="red"
                      title="Decline shift"
                      onClick={() => onReject(shift.officerScheduleId)}
                    >
                      <Icon as={MdOutlineClose} w={6} h={6} />
                    </Button>
                  ) : null}
                </Flex>
              </Flex>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  )
}

export default UsersMobileTable
