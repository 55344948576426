import {
  Box,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  useToast,
} from '@chakra-ui/react'
import {useLocation} from 'react-router-dom'
import {useMarkBriefing} from '../../hooks/briefings'

const OfficerBriefing = ({briefingData}) => {
  const {mutate, isLoading} = useMarkBriefing()
  const location = useLocation()
  const toast = useToast()

  if (!briefingData) {
    return null
  }

  const briefData = briefingData.unreadNotifications[0]

  const handleConfirm = () => {
    const notification = {
      notificationId: briefData.notificationId,
    }
    mutate(notification, {
      onError() {
        toast({
          title: 'Error confirmin brief',
          status: 'error',
        })
      },
    })
  }

  const showBriefings =
    location.pathname !== '/officer-shift' &&
    briefingData.unreadNotifications.length

  return showBriefings ? (
    <Modal isOpen={showBriefings} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text fontWeight="bold" fontSize="lg">
            Unread briefing boards
          </Text>
        </ModalHeader>
        <ModalBody pb={6}>
          <Box mb={4}>
            <Text>{briefData.data?.brief}</Text>
          </Box>
          <Box>
            <Button
              ml="auto"
              fontSize="sm"
              variant="solid"
              display="block"
              colorScheme="blue"
              isLoading={isLoading}
              onClick={handleConfirm}
            >
              Confirm brief{' '}
              {briefingData.unreadNotifications.length > 1 ? (
                <Text fontSize="xs" as="span">
                  ({briefingData.unreadNotifications.length} left)
                </Text>
              ) : null}
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  ) : null
}

export default OfficerBriefing
