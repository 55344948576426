import {getEntitiesWithActions} from '../../lib/entities'

const permitPermissionsList = ['paperlesspermit list', 'paperlesspermit show']
const permitPermissionsCreate = [
  'paperlesspermit create',
  'paperlesspermit store',
]
const permitPermissionsEdit = ['paperlesspermit update']
const permitPermissionsDelete = ['paperlesspermit delete']

const sortFields = [
  {
    key: 'user_id',
    dir: 'desc',
    label: 'By resident Descending ',
  },
  {
    key: 'user_id',
    dir: 'asc',
    label: 'By resident Ascending ',
  },
  {
    key: 'house_hold_id',
    dir: 'desc',
    label: 'By address Descending',
  },
  {
    key: 'house_hold_id',
    dir: 'asc',
    label: 'By address Ascending',
  },
  {
    key: 'vehicle_id',
    dir: 'desc',
    label: 'By lic. plate Descending',
  },
  {
    key: 'vehicle_id',
    dir: 'asc',
    label: 'By lic. plate Ascending',
  },
  {
    key: 'start_date',
    dir: 'desc',
    label: 'By date Descending',
  },
  {
    key: 'start_date',
    dir: 'asc',
    label: 'By date Ascending',
  },
]

const getPermitsWithActions = (permits, user) =>
  getEntitiesWithActions(
    permits,
    user,
    permitPermissionsEdit,
    permitPermissionsDelete
  )

export {
  permitPermissionsList,
  permitPermissionsCreate,
  permitPermissionsEdit,
  permitPermissionsDelete,
  getPermitsWithActions,
  sortFields,
}
