import {Box, Button, Icon, useDisclosure} from '@chakra-ui/react'
import * as React from 'react'
import {GiHamburgerMenu} from 'react-icons/gi'
import {MdClose} from 'react-icons/md'
import {useLocation} from 'react-router-dom'
import DesktopNavbar from './DesktopNavbar'
import MobileNavbar from './MobileNavbar'
import {useAuth} from '../../context/auth'
import useEndOfShift from '../../hooks/useEndOfShift'
import useSections from '../../hooks/useSections'
import {mobileStyles} from './Navbar.helpers'

const Navbar = function () {
  const {handleLogout} = useAuth()
  const {isOpen, onToggle, onClose} = useDisclosure()
  const location = useLocation()
  const locationRef = React.useRef(location.pathname)
  const handleEndOfShift = useEndOfShift()
  const sections = useSections()

  React.useEffect(() => {
    if (isOpen && location.pathname !== locationRef.current) {
      locationRef.current = location.pathname
      onClose()
    }
  }, [isOpen, location, onClose])

  return (
    <>
      <Box
        bg="white"
        gridArea="nav"
        display={{
          base: isOpen ? 'block' : 'none',
          lg: 'block',
        }}
        sx={mobileStyles}
      >
        <Box as="nav" h="100%" borderRight="1px solid" borderColor="gray.300">
          <DesktopNavbar
            sections={sections}
            onLogout={handleLogout}
            onEndOfShift={handleEndOfShift}
          />
          <MobileNavbar
            sections={sections}
            onLogout={handleLogout}
            onEndOfShift={handleEndOfShift}
          />
        </Box>
      </Box>
      <Button
        display={{lg: 'none'}}
        position="absolute"
        top={4}
        left={4}
        zIndex="var(--chakra-zIndices-modal)"
        onClick={onToggle}
      >
        <Icon as={isOpen ? MdClose : GiHamburgerMenu} />
      </Button>
    </>
  )
}

export default Navbar
