import * as React from 'react'
import ConfirmModal from '../../ConfirmModal'
import {useBreakMutation} from '../queries'
import {BREAK_TYPES} from '../helpers'

const BreakTaken = ({breakEvent, onBreakEnd}) => {
  const breakMutation = useBreakMutation()
  const [breakDuration, setBreakDuration] = React.useState(
    breakEvent.object.detail.break_duration
  )
  const intervalRef = React.useRef()

  const handleCancel = () => {
    if (breakEvent) {
      clearInterval(intervalRef.current)
      setBreakDuration(null)
      intervalRef.current = null
      breakMutation.mutate({
        officerBreakId: breakEvent.object.detail.officer_break_id,
        action: BREAK_TYPES.canceled,
      })
      onBreakEnd()
    }
  }

  React.useEffect(() => {
    if (breakEvent) {
      intervalRef.current = setInterval(() => {
        const newBreakDuration = breakDuration - 1
        if (newBreakDuration) {
          setBreakDuration(old => old - 1)
        } else {
          breakMutation.mutate({
            officerBreakId: breakEvent.object.detail.officer_break_id,
            action: BREAK_TYPES.ended,
          })
          onBreakEnd()
          clearInterval(intervalRef.current)
          setBreakDuration(null)
          intervalRef.current = null
        }
      }, 1000 * 60)
    } else if (intervalRef.current) {
      clearInterval(intervalRef.current)
      setBreakDuration(null)
      intervalRef.current = null
    }
  }, [breakDuration, breakEvent, breakMutation, onBreakEnd])

  return (
    <ConfirmModal
      isOpen
      title="Break"
      text={`your break will end in ${breakDuration} minute(s)`}
      onCancel={handleCancel}
      closeOnlyonCancel
    />
  )
}

export default BreakTaken
