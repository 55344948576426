import {Flex, Text, useToast} from '@chakra-ui/react'
import * as React from 'react'
import {useAuth} from '../../../context/auth'
import HouseholdsDesktopTable from './HouseholdsDesktopTable'
import HouseholdsMobileTable from './HouseholdsMobileTable'
import Paginator from '../../Paginator'
import DeleteModal from '../../DeleteModal'
import HouseholdsFormModal from '../HouseholdsFormModal'
import Acl from '../../Acl'
import {
  householdPermissionsDelete,
  householdPermissionsEdit,
  getHouseholdsWithActions,
} from '../Households.helpers'
import {usePatch} from '../../../hooks/entities'
import {isObjectEmpty} from '../../../lib/objects'
import {ENTITIES, ENTITIES_KEYS} from '../../../lib/entities'
import {MODAL_KEYS} from '../../../lib/modal'

const HouseholdsTable = ({
  items,
  sort,
  itemsTotal,
  pages,
  pagesCount,
  currentPage,
  filters,
  onSort,
  onPageChange,
}) => {
  const [householdId, sethouseholdId] = React.useState()
  const [activeModal, setActiveModal] = React.useState()
  const {user} = useAuth()
  const {mutate, isLoading} = usePatch({
    id: householdId,
    entity: ENTITIES.households,
    currentPage,
    filters,
    sort,
  })
  const toast = useToast({
    position: 'top',
    isClosable: true,
  })

  const handleEdit = id => {
    sethouseholdId(id)
    setActiveModal(MODAL_KEYS.edit)
  }

  const handleDelete = id => {
    sethouseholdId(id)
    setActiveModal(MODAL_KEYS.delete)
  }

  const handleModalClose = () => {
    sethouseholdId(null)
    setActiveModal(null)
  }

  const handleUpdate = householdData => {
    mutate(
      {
        ...householdData,
        [ENTITIES_KEYS.households]: householdId,
      },
      {
        onSuccess() {
          toast({
            title: 'Household updated!',
            status: 'success',
          })
          handleModalClose()
        },
        onError() {
          toast({
            title: 'Error updating household',
            status: 'error',
          })
        },
      }
    )
  }

  const households = getHouseholdsWithActions(items, user)

  if (!households?.length && isObjectEmpty(filters)) {
    return (
      <Flex justify="center" boxShadow="lg" p={4}>
        <Text>No data found for this section</Text>
      </Flex>
    )
  }

  if (!households?.length && !isObjectEmpty(filters)) {
    return (
      <Flex justify="center" boxShadow="lg" p={4}>
        <Text>No data found for the filters applied</Text>
      </Flex>
    )
  }

  return (
    <>
      <HouseholdsMobileTable
        households={households}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
      <HouseholdsDesktopTable
        households={households}
        sort={sort}
        onSort={onSort}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />

      <Paginator
        itemsTotal={itemsTotal}
        pages={pages}
        pagesCount={pagesCount}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />

      <Acl requiredPermissions={householdPermissionsEdit}>
        {activeModal === MODAL_KEYS.edit ? (
          <HouseholdsFormModal
            householdId={householdId}
            isOpen={activeModal === MODAL_KEYS.edit}
            isSubmiting={isLoading}
            onClose={handleModalClose}
            onSubmit={handleUpdate}
          />
        ) : null}
      </Acl>

      <Acl requiredPermissions={householdPermissionsDelete}>
        {activeModal === MODAL_KEYS.delete ? (
          <DeleteModal
            id={householdId}
            sort={sort}
            entityName="Household"
            filters={filters}
            entity={ENTITIES.households}
            currentPage={currentPage}
            entityKey={ENTITIES_KEYS.households}
            isOpen={activeModal === MODAL_KEYS.delete}
            onClose={handleModalClose}
          />
        ) : null}
      </Acl>
    </>
  )
}

export default HouseholdsTable
