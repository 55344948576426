import {
  Box,
  Flex,
  Text,
  Button,
  Icon,
  List,
  ListItem,
  Checkbox,
  useBreakpointValue,
} from '@chakra-ui/react'
import {MdModeEdit, MdDelete} from 'react-icons/md'
import {HiChevronDown, HiChevronUp} from 'react-icons/hi'
import {formatDate} from '../../../lib/formatters'

const getIsDeleteDisabled = date => {
  const dateTime = new Date(`${date} UTC`).getTime()
  return dateTime < Date.now()
}

const PermitsDesktopTable = ({
  permits,
  selectedPermits,
  sort,
  onSort,
  onEdit,
  onDelete,
  onPermitSelect,
}) => {
  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  })

  if (isMobile) {
    return null
  }

  const handleAll = ({target: {checked}}) => {
    if (checked) {
      const permitsIds = permits
        .filter(p => !getIsDeleteDisabled(p.startDate))
        .map(p => p.paperlessPermitId)
      onPermitSelect(permitsIds)
    } else {
      onPermitSelect([])
    }
  }

  const handleSelectPermit = id => {
    onPermitSelect(id)
  }

  const SortIcon =
    sort?.dir === 'desc' ? (
      <Icon as={HiChevronDown} />
    ) : (
      <Icon as={HiChevronUp} />
    )

  const areAllSelected = permits.length === selectedPermits.length

  return (
    <List boxShadow="lg" fontSize="xs">
      <ListItem borderBottom="1px solid" borderColor="gray.300">
        <Flex>
          <Flex
            pl={4}
            pr={0}
            py={2}
            flex="1"
            flexBasis="90%"
            fontWeight="bold"
            align="center"
          >
            <Checkbox
              borderColor="gray.400"
              flexBasis="5%"
              isChecked={areAllSelected}
              onChange={handleAll}
            />
            <Button
              flexBasis="25%"
              variant="link"
              justifyContent="flex-start"
              rightIcon={sort?.field === 'user_id' ? SortIcon : null}
              onClick={() => onSort && onSort('user_id')}
            >
              <Text color="gray.800" fontSize="sm">
                Resident
              </Text>
            </Button>
            <Button
              flexBasis="25%"
              variant="link"
              justifyContent="flex-start"
              rightIcon={sort?.field === 'house_hold_id' ? SortIcon : null}
              onClick={() => onSort && onSort('house_hold_id')}
            >
              <Text color="gray.800" fontSize="sm">
                Address
              </Text>
            </Button>
            <Button
              flexBasis="20%"
              variant="link"
              justifyContent="flex-start"
              rightIcon={sort?.field === 'vehicle_id' ? SortIcon : null}
              onClick={() => onSort && onSort('vehicle_id')}
            >
              <Text color="gray.800" fontSize="sm">
                Lic. plate
              </Text>
            </Button>
            <Button
              flexBasis="25%"
              variant="link"
              justifyContent="flex-start"
              rightIcon={sort?.field === 'start_date' ? SortIcon : null}
              onClick={() => onSort && onSort('start_date')}
            >
              <Text color="gray.800" fontSize="sm">
                Date
              </Text>
            </Button>
          </Flex>
          <Box flexBasis="15%" py={2} pr={4} />
        </Flex>
      </ListItem>
      {permits.map(permit => (
        <ListItem
          key={permit.paperlessPermitId}
          transitionProperty="var(--chakra-transition-property-common)"
          transitionDuration="var(--chakra-transition-duration-normal)"
          _even={{
            bg: 'gray.100',
          }}
          _hover={{
            bg: 'blue.50',
          }}
        >
          <Flex>
            <Flex
              w="100%"
              h="100%"
              minH="60px"
              pl={4}
              pr={0}
              py={2}
              flex="1"
              flexBasis="90%"
              fontSize="xs"
              color="gray.700"
            >
              <Flex align="center" w="100%">
                <Checkbox
                  borderColor="gray.400"
                  flexBasis="5%"
                  isDisabled={getIsDeleteDisabled(permit.startDate)}
                  isChecked={selectedPermits.includes(permit.paperlessPermitId)}
                  onChange={() => handleSelectPermit(permit.paperlessPermitId)}
                />
                <Text flexBasis="25%" whiteSpace="pre-wrap">
                  {permit.firstName
                    ? `${permit.firstName} ${permit.lastName}`
                    : ''}
                </Text>
                <Text flexBasis="25%" whiteSpace="pre-wrap">
                  {permit.number} {permit.street}
                </Text>
                <Text flexBasis="20%" whiteSpace="pre-wrap">
                  {permit.licensePlate}
                </Text>
                <Text flexBasis="25%" whiteSpace="pre-wrap">
                  {formatDate(permit.startDate, {
                    isUTC: false,
                    withHours: false,
                  })}
                </Text>
              </Flex>
            </Flex>
            <Flex justify="center" align="center" flexBasis="15%" py={2} pr={4}>
              {permit.canEdit || permit.canDelete ? (
                <Flex gap={2}>
                  {permit.canEdit ? (
                    <Button
                      variant="ghost"
                      onClick={() => onEdit(permit.paperlessPermitId)}
                    >
                      <Icon as={MdModeEdit} w={6} h={6} />
                    </Button>
                  ) : null}
                  {permit.canDelete &&
                  !getIsDeleteDisabled(permit.startDate) ? (
                    <Button
                      variant="ghost"
                      colorScheme="red"
                      onClick={() => onDelete(permit.paperlessPermitId)}
                    >
                      <Icon as={MdDelete} w={6} h={6} />
                    </Button>
                  ) : null}
                </Flex>
              ) : null}
            </Flex>
          </Flex>
        </ListItem>
      ))}
    </List>
  )
}

export default PermitsDesktopTable
