import {useToast} from '@chakra-ui/react'
import * as React from 'react'
import Calendar from './Calendar/Calendar'
import SchedulesFormModal from './SchedulesFormModal'
import Acl from '../Acl'
import DeleteModal from '../DeleteModal'
import {usePatch} from '../../hooks/entities'
import {MODAL_KEYS} from '../../lib/modal'
import {ENTITIES, ENTITIES_KEYS} from '../../lib/entities'
import {schedulePermissionsDelete} from './Schedules.helpers'

const Schedules = ({items, filters, currentWeek}) => {
  const [scheduleId, setScheduleId] = React.useState()
  const [activeModal, setActiveModal] = React.useState()
  const {mutate, isLoading} = usePatch({
    id: scheduleId,
    entity: ENTITIES.officerSchedules,
    filters,
  })
  const toast = useToast()

  const handleModalClose = () => {
    setScheduleId(null)
    setActiveModal(null)
  }

  const handleEdit = id => {
    setScheduleId(id)
    setActiveModal(MODAL_KEYS.edit)
  }

  const handleDelete = id => {
    setScheduleId(id)
    setActiveModal(MODAL_KEYS.delete)
  }

  const handleUpdate = scheduleData => {
    mutate(
      {
        [ENTITIES_KEYS.officerSchedules]: scheduleId,
        ...scheduleData,
      },
      {
        onSuccess() {
          toast({
            title: 'Officer schedule updated!',
            status: 'success',
          })
          handleModalClose()
        },
        onError() {
          toast({
            title: 'Error updating officer schedule',
            status: 'error',
          })
        },
      }
    )
  }

  return (
    <>
      <Calendar
        currentWeek={currentWeek.dates}
        handleEdit={handleEdit}
        data={items}
      />

      {activeModal === MODAL_KEYS.edit ? (
        <SchedulesFormModal
          officerScheduleId={scheduleId}
          isOpen={activeModal === MODAL_KEYS.edit}
          isSubmiting={isLoading}
          onClose={handleModalClose}
          onDelete={handleDelete}
          onSubmit={handleUpdate}
        />
      ) : null}

      <Acl requiredPermissions={schedulePermissionsDelete}>
        {activeModal === MODAL_KEYS.delete ? (
          <DeleteModal
            id={scheduleId}
            entityName="Officer schedule"
            filters={filters}
            entity={ENTITIES.officerSchedules}
            entityKey={ENTITIES_KEYS.officerSchedules}
            isOpen={activeModal === MODAL_KEYS.delete}
            onClose={handleModalClose}
          />
        ) : null}
      </Acl>
    </>
  )
}

export default Schedules
