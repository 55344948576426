import {useAuthQuery} from './useAuthQuery'

const KEYS = {
  pools: ['properties-pools'],
}

const usePools = ({enabled = false}) => {
  const queryState = useAuthQuery({
    keys: KEYS.pools,
    endpoint: '/property_pool',
    queryOptions: {
      enabled,
    },
  })

  return queryState
}

export {usePools}
