import * as React from 'react'
import {Text} from '@chakra-ui/react'
import ConfirmModal from '../../ConfirmModal'
import {useBreakMutation} from '../queries'
import {BREAK_TYPES} from '../helpers'

const MAX_TIME_TO_ACTION = 15000

const BreackModal = ({breakEvent, onClose}) => {
  const [timeLeft, setTimeLeft] = React.useState(MAX_TIME_TO_ACTION)
  const breakMutation = useBreakMutation()
  const intervalRef = React.useRef()

  const onCancel = React.useCallback(() => {
    clearInterval(intervalRef.current)
    breakMutation.mutate({
      officerBreakId: breakEvent.object.detail.officer_break_id,
      action: BREAK_TYPES.postponed,
    })
    onClose()
  }, [breakEvent, breakMutation, onClose])

  const onConfirm = () => {
    clearInterval(intervalRef.current)
    breakMutation.mutate({
      officerBreakId: breakEvent.object.detail.officer_break_id,
      action: BREAK_TYPES.taken,
    })
    onClose(true)
  }

  React.useEffect(() => {
    intervalRef.current = setInterval(() => {
      const newTimeLeft = timeLeft - 1000
      if (newTimeLeft) {
        setTimeLeft(newTimeLeft)
      } else {
        onCancel()
      }
    }, 1000)

    return () => {
      clearInterval(intervalRef.current)
    }
  }, [onCancel, timeLeft])

  const {
    object: {
      detail: {remaining_postponed_times: remainingPostponedTimes, mandatory},
    },
  } = breakEvent

  const cantPostpone = mandatory === 1 && remainingPostponedTimes === 0

  const modalText = cantPostpone
    ? "It's time for your break and you can't postpone it anymore"
    : "It's time for your break. Are you taking it?"

  return (
    <ConfirmModal
      isOpen
      title="Time to take a break!"
      text={
        <>
          {modalText}
          <p>
            <Text fontSize="sm" ml="auto">
              {timeLeft / 1000} second(s) remaining
            </Text>
          </p>
        </>
      }
      onConfirm={onConfirm}
      onCancel={cantPostpone ? undefined : onCancel}
      closeOnlyonCancel
    />
  )
}

export default BreackModal
