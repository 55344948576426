import {getEntitiesWithActions} from '../../lib/entities'

const incidentPermissionsList = ['incidentreport list', 'incidentreport show']
const incidentPermissionsCreate = [
  'incidentreport create',
  'incidentreport store',
]
const incidentPermissionsEdit = ['incidentreport update']
const incidentPermissionsMutate = [
  ...incidentPermissionsCreate,
  ...incidentPermissionsEdit,
]
const incidentPermissionsDelete = ['incidentreport delete']

const canEditValidation = entity => entity.automaticDar === 0

const canDeleteValidation = entity => entity.automaticDar === 0

const getIncidentsWithActions = (incidents, user) =>
  getEntitiesWithActions(
    incidents,
    user,
    incidentPermissionsEdit,
    incidentPermissionsDelete,
    canEditValidation,
    canDeleteValidation
  )

const sortFields = [
  {
    key: 'incident_date',
    dir: 'desc',
    label: 'By date descending',
  },
  {
    key: 'incident_date',
    dir: 'asc',
    label: 'By date ascending',
  },
  {
    key: 'officer_id',
    dir: 'desc',
    label: 'By officer descending',
  },
  {
    key: 'officer_id',
    dir: 'asc',
    label: 'By officer ascending',
  },
]

export {
  incidentPermissionsCreate,
  incidentPermissionsList,
  incidentPermissionsDelete,
  incidentPermissionsEdit,
  incidentPermissionsMutate,
  getIncidentsWithActions,
  sortFields,
}
