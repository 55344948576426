import {
  Box,
  Flex,
  Text,
  Button,
  Icon,
  List,
  ListItem,
  useBreakpointValue,
} from '@chakra-ui/react'
import {MdModeEdit, MdDelete} from 'react-icons/md'
import {HiChevronDown, HiChevronUp} from 'react-icons/hi'

const UsersDesktopTable = ({users, sort, onSort, onEdit, onDelete}) => {
  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  })

  if (isMobile) {
    return null
  }

  const SortIcon =
    sort?.dir === 'desc' ? (
      <Icon as={HiChevronDown} />
    ) : (
      <Icon as={HiChevronUp} />
    )

  return (
    <List boxShadow="lg" fontSize="xs">
      <ListItem borderBottom="1px solid" borderColor="gray.300">
        <Flex>
          <Flex
            pl={4}
            pr={0}
            py={2}
            flex="1"
            flexBasis="90%"
            fontWeight="bold"
            align="center"
          >
            <Button
              flexBasis="50%"
              variant="link"
              justifyContent="flex-start"
              rightIcon={sort?.field === 'user_name' ? SortIcon : null}
              onClick={() => onSort && onSort('user_name')}
            >
              <Text color="gray.800" fontSize="sm">
                Username
              </Text>
            </Button>
            <Button
              flexBasis="25%"
              variant="link"
              justifyContent="flex-start"
              rightIcon={sort?.field === 'role_name' ? SortIcon : null}
              onClick={() => onSort && onSort('role_name')}
            >
              <Text color="gray.800" fontSize="sm">
                Type
              </Text>
            </Button>
            <Button
              flexBasis="25%"
              variant="link"
              justifyContent="flex-start"
              rightIcon={sort?.field === 'status' ? SortIcon : null}
              onClick={() => onSort && onSort('status')}
            >
              <Text color="gray.800" fontSize="sm">
                Status
              </Text>
            </Button>
          </Flex>
          <Box flexBasis="15%" py={2} pr={4} />
        </Flex>
      </ListItem>
      {users.map(user => (
        <ListItem
          key={user.userId}
          transitionProperty="var(--chakra-transition-property-common)"
          transitionDuration="var(--chakra-transition-duration-normal)"
          _even={{
            bg: 'gray.100',
          }}
          _hover={{
            bg: 'blue.50',
          }}
        >
          <Flex>
            <Flex
              w="100%"
              h="100%"
              minH="60px"
              pl={4}
              pr={0}
              py={2}
              flex="1"
              flexBasis="90%"
              fontSize="xs"
              color="gray.700"
            >
              <Flex align="center" w="100%">
                <Text flexBasis="50%" whiteSpace="pre-wrap">
                  {user.userName}
                </Text>
                <Text flexBasis="25%" whiteSpace="pre-wrap">
                  {user.roleName}
                </Text>
                <Text flexBasis="25%" whiteSpace="pre-wrap">
                  {user.status}
                </Text>
              </Flex>
            </Flex>
            <Flex justify="center" align="center" flexBasis="15%" py={2} pr={4}>
              {user.canEdit || user.canDelete ? (
                <Flex gap={2}>
                  {user.canEdit ? (
                    <Button variant="ghost" onClick={() => onEdit(user.userId)}>
                      <Icon as={MdModeEdit} w={6} h={6} />
                    </Button>
                  ) : null}
                  {user.canDelete ? (
                    <Button
                      variant="ghost"
                      colorScheme="red"
                      onClick={() => onDelete(user.userId)}
                    >
                      <Icon as={MdDelete} w={6} h={6} />
                    </Button>
                  ) : null}
                </Flex>
              ) : null}
            </Flex>
          </Flex>
        </ListItem>
      ))}
    </List>
  )
}

export default UsersDesktopTable
