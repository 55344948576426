import {
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react'
import * as React from 'react'

const ImagesGallery = ({images}) => {
  const [modalImageIndex, setModalImageIndex] = React.useState(null)
  const handleArrows = React.useCallback(
    e => {
      if (e.key === 'ArrowRight' && modalImageIndex < images.length - 1) {
        setModalImageIndex(modalImageIndex + 1)
      }
      if (e.key === 'ArrowLeft' && modalImageIndex > 0) {
        setModalImageIndex(modalImageIndex - 1)
      }
    },
    [images, modalImageIndex]
  )

  const handleClick = ({currentTarget}) => {
    setModalImageIndex(Number(currentTarget.dataset.index))
  }

  const handleClose = () => {
    setModalImageIndex(null)
    document.removeEventListener('keydown', handleArrows)
  }

  React.useEffect(() => {
    document.addEventListener('keydown', handleArrows)

    return () => {
      document.removeEventListener('keydown', handleArrows)
    }
  }, [handleArrows])

  return (
    <Flex mt={8} gap={2}>
      {images?.map((image, index) => (
        <Button
          p="0"
          h="100%"
          variant="ghost"
          key={`image-gallery ${index}`}
          type="button"
          data-index={index}
          onClick={handleClick}
        >
          <Image src={image} alt="" boxSize="100px" rounded="sm" />
        </Button>
      ))}
      {modalImageIndex !== null ? (
        <Modal
          isOpen
          isCentered
          onClose={handleClose}
          blockScrollOnMount={false}
        >
          <ModalOverlay />
          <ModalContent w="fit-content">
            <ModalBody p="0" w="fit-content">
              <Flex
                h="100%"
                bg="gray.800"
                w="fit-content"
                justify="center"
                align="center"
                padding={8}
              >
                <Image src={images[modalImageIndex]} alt="" />
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      ) : null}
    </Flex>
  )
}

export default ImagesGallery
