import {
  Box,
  Flex,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  useBreakpointValue,
} from '@chakra-ui/react'
import {formatDate} from '../../../lib/formatters'
import DarMobileItem from './DarMobileItem'

const DarMobileTable = ({dars, onEdit, onDelete}) => {
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  })

  if (isDesktop) {
    return null
  }

  return (
    <Box
      boxShadow="lg"
      display={{
        lg: 'none',
      }}
    >
      <Flex fontSize="sm" px={4} py={2}>
        <Flex gap={2} flex="1">
          <Text fontWeight="bold" flexBasis="40%">
            Date
          </Text>
          <Text fontWeight="bold" flexBasis="40%">
            Type
          </Text>
          <Text fontWeight="bold" flexBasis="20%">
            Code
          </Text>
          <Box boxSize="1rem" />
        </Flex>
      </Flex>
      <Accordion allowToggle>
        {dars.map(dar => (
          <AccordionItem key={dar.dailyActivityReportId}>
            {({isExpanded}) => (
              <>
                <AccordionButton fontSize="sm">
                  <Flex flex="1" textAlign="start" gap={2} align="center">
                    <Flex flexBasis="40%" direction="column">
                      <span>{formatDate(dar.darDate).split(',')[0]}</span>
                      <span>{formatDate(dar.darDate).split(',')[1]}</span>
                    </Flex>
                    <Box flexBasis="40%">{dar.type}</Box>
                    <Box flexBasis="20%">
                      <Box
                        boxSize="12px"
                        bg={`${dar.codeType.toLowerCase()}`}
                        borderRadius="50%"
                      />
                    </Box>
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel
                  fontSize="sm"
                  position="relative"
                  overflow="visible"
                >
                  <DarMobileItem
                    isExpanded={isExpanded}
                    canEdit={dar.canEdit}
                    canDelete={dar.canDelete}
                    dailyActivityReportId={dar.dailyActivityReportId}
                    onEdit={onEdit}
                    onDelete={onDelete}
                  />
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  )
}

export default DarMobileTable
