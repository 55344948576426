import {Flex, Box, Text, Button, Icon, Skeleton} from '@chakra-ui/react'
import {MdModeEdit, MdDelete} from 'react-icons/md'
import ImagesGallery from '../../ImagesGallery'
import {useItem} from '../../../hooks/entities'
import {ENTITIES, ENTITIES_KEYS} from '../../../lib/entities'

const DarMobileItem = ({
  isExpanded,
  dailyActivityReportId,
  canEdit,
  canDelete,
  onEdit,
  onDelete,
}) => {
  const {data, isLoading} = useItem({
    entity: ENTITIES.dar,
    id: isExpanded ? dailyActivityReportId : undefined,
    entityKey: ENTITIES_KEYS.dar,
  })

  if (!isExpanded) {
    return null
  }

  if (isLoading) {
    return (
      <Flex>
        <Skeleton w="100%" h="150px" />
      </Flex>
    )
  }

  const {data: dar} = data

  return (
    <Flex align="center">
      <Flex direction="column" gap={2} mb={2} flex="1">
        <Box>
          <Text fontWeight="bold" as="span" mr={2}>
            User:
          </Text>
          {dar.userName}
        </Box>
        {dar.includeGpsCoordinates ? (
          <Box>
            <Text fontWeight="bold" as="span" mr={2}>
              GPS Coordinates:
            </Text>
            {dar.gpsCoordinates.replace(',', ', ')}
          </Box>
        ) : null}
        <Box>
          <Text fontWeight="bold" as="span" mr={2}>
            Details:
          </Text>
          {dar.details}
        </Box>
        {dar.thumbImages ? (
          <Box>
            <ImagesGallery images={dar.thumbImages} />
          </Box>
        ) : null}
      </Flex>
      <Flex direction="column" justify="end" gap={2} align="center">
        {canEdit ? (
          <Button
            size="sm"
            variant="ghost"
            onClick={() => onEdit(dar.dailyActivityReportId)}
          >
            <Icon as={MdModeEdit} w={6} h={6} />
          </Button>
        ) : null}
        {canDelete ? (
          <Button
            size="sm"
            variant="ghost"
            colorScheme="red"
            onClick={() => onDelete(dar.dailyActivityReportId)}
          >
            <Icon as={MdDelete} w={6} h={6} />
          </Button>
        ) : null}
      </Flex>
    </Flex>
  )
}

export default DarMobileItem
