import {Flex, Text, useToast} from '@chakra-ui/react'
import * as React from 'react'
import {getMany} from 'idb-keyval'
import OfflineDesktopTable from './OfflineDesktopTable'
import Paginator from '../../Paginator'
import {STORE_STATUS} from '../../../lib/offlineStore'
import {DarFormModal} from '../../Dar'
import {IncidentsFormModal} from '../../Incidents'
import {PoolReportFormModal} from '../../PoolReports'
import {ViolationFormModal} from '../../Violations'
import OfflineMobileTable from './OfflineMobileTable'
import {useAllVehicles} from '../../../hooks/vehicles'
import {useDarTypes} from '../../../hooks/dar'
import {useViolationTypes} from '../../../hooks/violations'
import {useIncidentTypes} from '../../../hooks/incidents'
import {useBulkStore} from '../../../hooks/entities'
import useConnectionStatus from '../../../hooks/useConnectionStatus'
import {ENTITIES} from '../../../lib/entities'

const OfflineTable = ({
  store,
  itemsTotal,
  pages,
  pagesCount,
  currentPage,
  onUpdate,
  onBulkUpdate,
  onPageChange,
}) => {
  const {data: vehiclesData} = useAllVehicles({enabled: true})
  const {data: darData} = useDarTypes({
    enabled: true,
  })
  const {data: violationTypesData} = useViolationTypes({
    enabled: true,
  })
  const {data: incidentTypesData} = useIncidentTypes({
    enabled: true,
  })
  const {mutate, isLoading, reset} = useBulkStore()
  const isOnline = useConnectionStatus()
  const [item, setItem] = React.useState()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const toast = React.useCallback(
    useToast({
      position: 'top',
      isClosable: true,
    }),
    []
  )
  const items = React.useMemo(
    () =>
      Object.keys(store)
        .filter(k => store[k])
        .reduce(
          (acc, k) => [...acc, ...store[k].map(d => ({...d, type: k}))],
          []
        ),
    [store]
  )

  const handleEdit = _item => {
    setItem(_item)
  }

  const handleSubmit = data => {
    onUpdate(item.type, item.tempId, data)
    setItem(null)
  }

  const handleModalClose = () => {
    setItem(null)
  }

  React.useEffect(() => {
    if (isOnline && !isLoading) {
      const run = async () => {
        const pendingItems = items.filter(
          i => i.status === STORE_STATUS.pending
        )
        if (pendingItems.length) {
          const imagesIds = pendingItems.reduce((acc, item) => {
            if (item.data.images) {
              return [...acc, ...item.data.images]
            }
            return acc
          }, [])
          const encodedImages = await getMany(imagesIds)

          const data = pendingItems.reduce((acc, item) => {
            if (!acc[item.type]) {
              acc[item.type] = []
            }

            let images
            if (item.data.images) {
              images = item.data.images.map((_, index) => encodedImages[index])
            }
            return {
              ...acc,
              [item.type]: [
                ...acc[item.type],
                {
                  ...item.data,
                  images,
                  tempId: item.tempId,
                },
              ],
            }
          }, {})

          mutate(data, {
            onSuccess(resItems) {
              let allItemsCreated = true
              const _items = Object.keys(resItems)
                .filter(key => resItems[key]?.length)
                .reduce((acc, key) => {
                  if (!allItemsCreated) {
                    allItemsCreated = resItems[key].some(
                      _item => _item.status !== 1
                    )
                  }

                  return {
                    ...acc,
                    [key]: resItems[key],
                  }
                }, {})

              onBulkUpdate(_items)

              toast({
                title: allItemsCreated
                  ? 'Items created successfully'
                  : 'Some items were created, please check for errors in your items',
              })
              reset()
            },
            onError() {
              toast({
                title: "Couldn't create the items, try again later",
                status: 'error',
              })
            },
          })
        }
      }
      run()
    }
  }, [isLoading, isOnline, items, mutate, onBulkUpdate, reset, toast])

  let ItemFormModal
  if (item) {
    switch (item.type) {
      case ENTITIES.dar:
        ItemFormModal = DarFormModal
        break
      case ENTITIES.incidents:
        ItemFormModal = IncidentsFormModal
        break
      case ENTITIES.poolreports:
        ItemFormModal = PoolReportFormModal
        break
      case ENTITIES.violations:
        ItemFormModal = ViolationFormModal
        break
      default:
        ItemFormModal = null
    }
  }

  if (!items.length) {
    return (
      <Flex justify="center" boxShadow="lg" p={4}>
        <Text>No data found for this section</Text>
      </Flex>
    )
  }

  return (
    <>
      <OfflineDesktopTable
        isLoading={isLoading}
        items={items}
        vehiclesData={vehiclesData}
        darData={darData}
        violationTypesData={violationTypesData}
        incidentTypesData={incidentTypesData}
        onEdit={handleEdit}
      />
      <OfflineMobileTable
        isLoading={isLoading}
        items={items}
        vehiclesData={vehiclesData}
        darData={darData}
        violationTypesData={violationTypesData}
        incidentTypesData={incidentTypesData}
        onEdit={handleEdit}
      />

      <Paginator
        pages={pages}
        itemsTotal={itemsTotal}
        pagesCount={pagesCount}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
      {item ? (
        <ItemFormModal
          isOpen={Boolean(item)}
          itemData={item.data}
          onSubmit={handleSubmit}
          onClose={handleModalClose}
        />
      ) : null}
    </>
  )
}

export default OfflineTable
