import * as React from 'react'
import {useAuthQuery} from './useAuthQuery'

const KEYS = {
  allVehicles: ['vehicles'],
  vehicles: criteria => ['vehicles', criteria],
}

const useAllVehicles = ({enabled = false} = {}) => {
  const queryState = useAuthQuery({
    keys: KEYS.allVehicles,
    endpoint: '/vehicle/getvehiclebyplate',
    queryOptions: {
      enabled,
    },
  })

  return queryState
}

const filterVehicles = (input, vehicles) =>
  vehicles.filter(v => v.licensePlate.toLowerCase().includes(input))
const formatVehiclesOptions = vehicles =>
  vehicles.map(v => ({
    value: v.vehicleId,
    label: `${v.licensePlate} - ${v.make} ${v.model}`,
  }))

const THROTTLE_MS = 400

const useVehicleByCriteria = householdId => {
  const [criteria, setCriteria] = React.useState()
  const timeoutRef = React.useRef()
  const callbackRef = React.useRef()
  const {data: {vehicles: allVehicles} = {}} = useAllVehicles()
  const [vehicles, setVehicles] = React.useState([])
  const {data, isSuccess, isFetching} = useAuthQuery({
    keys: KEYS.vehicles(criteria),
    endpoint: `/vehicle/getvehiclebyplate?license_plate=${criteria}&house_hold_id=${householdId}`,
    queryOptions: {
      enabled: Boolean(criteria),
    },
  })

  const handleLoadOptions = React.useCallback(
    (input, callback) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef)
      }

      if (!input.trim() || input.length < 3) {
        return
      }

      if (!navigator.onLine) {
        const trimmed = input.trim().toLowerCase()
        const filteredVehicles = filterVehicles(trimmed, allVehicles)
        const options = formatVehiclesOptions(filteredVehicles)

        callback(options)
        setVehicles(filteredVehicles)
        return
      }

      callbackRef.current = callback

      setTimeout(() => {
        setCriteria(input)
      }, THROTTLE_MS)
    },
    [allVehicles]
  )

  React.useEffect(() => {
    if (isSuccess && !isFetching) {
      const {vehicles: _vehicles} = data
      const options = formatVehiclesOptions(_vehicles)

      callbackRef.current(options)
      setVehicles(_vehicles)
    }
  }, [isSuccess, isFetching, data])

  return {vehicles, handleLoadOptions}
}

export {useAllVehicles, useVehicleByCriteria}
