const USER_ROLES = {
  admin: 'Admin',
  supervisor: 'Supervisor',
  postCommander: 'Post Commander',
  propertyManager: 'Property Manager',
  callCenter: 'Call Center',
  officer: 'Officer',
  resident: 'Resident',
  boardOfDirectors: 'Board of Directors',
}

const getHasUserPermissions = (requiredPermissions, user) => {
  let verifiedStatus = false
  for (const permission of user.roles[0].permissions) {
    if (requiredPermissions.some(req => req === permission.name)) {
      verifiedStatus = true
      break
    }
  }

  return verifiedStatus
}

const getUserData = ({user, ...responseData}) => {
  const {name: role} = user.roles[0]
  const userData = {
    ...responseData,
    ...user,
    isAdmin: role === USER_ROLES.admin,
    isSupervisor: role === USER_ROLES.supervisor,
    isPostCommander: role === USER_ROLES.postCommander,
    isPropertyManager: role === USER_ROLES.propertyManager,
    isCallCenter: role === USER_ROLES.callCenter,
    isOfficer: role === USER_ROLES.officer,
    isResident: role === USER_ROLES.resident,
    isBoardOfDirectors: role === USER_ROLES.boardOfDirectors,
  }

  return userData
}

export {USER_ROLES, getHasUserPermissions, getUserData}
