import {Flex, Box, Text, Button, Icon, Skeleton} from '@chakra-ui/react'
import {MdModeEdit, MdDelete} from 'react-icons/md'
import ImagesGallery from '../../ImagesGallery'
import {useItem} from '../../../hooks/entities'
import {ENTITIES, ENTITIES_KEYS} from '../../../lib/entities'

const PoolReportsMobileItem = ({
  isExpanded,
  poolReportId,
  canEdit,
  canDelete,
  onEdit,
  onDelete,
}) => {
  const {data, isLoading} = useItem({
    entity: ENTITIES.poolreports,
    id: isExpanded ? poolReportId : undefined,
    entityKey: ENTITIES_KEYS.poolreports,
  })

  if (!isExpanded) {
    return null
  }

  if (isLoading) {
    return (
      <Flex>
        <Skeleton w="100%" h="150px" />
      </Flex>
    )
  }

  const {data: poolReport} = data

  return (
    <Flex align="center">
      <Flex direction="column" gap={2} mb={2} flex="1">
        <Box>
          <Text fontWeight="bold" as="span" mr={2}>
            Officer:
          </Text>
          {poolReport.officerName}
        </Box>
        <Box>
          <Text fontWeight="bold" as="span" mr={2}>
            Details:
          </Text>
          {poolReport.details}
        </Box>
        {poolReport.media?.thumbImages ? (
          <Box>
            <ImagesGallery images={poolReport.media?.thumbImages} />
          </Box>
        ) : null}
      </Flex>

      <Flex direction="column" justify="end" gap={2} align="center">
        {canEdit ? (
          <Button
            size="sm"
            variant="ghost"
            onClick={() => onEdit(poolReport.poolReportId)}
          >
            <Icon as={MdModeEdit} w={6} h={6} />
          </Button>
        ) : null}
        {canDelete ? (
          <Button
            size="sm"
            variant="ghost"
            colorScheme="red"
            onClick={() => onDelete(poolReport.poolReportId)}
          >
            <Icon as={MdDelete} w={6} h={6} />
          </Button>
        ) : null}
      </Flex>
    </Flex>
  )
}

export default PoolReportsMobileItem
