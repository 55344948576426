import {getEntitiesWithActions} from '../../lib/entities'

const householdPermissionsList = ['household list', 'household show']
const householdPermissionsCreate = ['household create', 'household store']
const householdPermissionsEdit = ['household update']
const householdPermissionsDelete = ['household delete']

const getHouseholdsWithActions = (households, user) =>
  getEntitiesWithActions(
    households,
    user,
    householdPermissionsEdit,
    householdPermissionsDelete
  )

const sortFields = [
  {
    key: 'name',
    dir: 'desc',
    label: 'By name descending',
  },
  {
    key: 'name',
    dir: 'asc',
    label: 'By name ascending',
  },
  {
    key: 'address',
    dir: 'desc',
    label: 'By address descending',
  },
  {
    key: 'address',
    dir: 'asc',
    label: 'By address ascending',
  },
]

export {
  householdPermissionsCreate,
  householdPermissionsList,
  householdPermissionsDelete,
  householdPermissionsEdit,
  getHouseholdsWithActions,
  sortFields,
}
