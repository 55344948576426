import * as React from 'react'
import {useQuery} from 'react-query'
import {getPromise} from '../lib/fetcher'
import {setStorageItem} from '../lib/localStorage'

const AuthContext = React.createContext()
AuthContext.displayName = 'AuthContext'

const AUTH_STORAGE_KEY = 'starproaccess:auth_session'
const MOUNTED_KEY = 'starproaccess:application_mounted'

const AuthProvider = ({children}) => {
  const [user, setUser] = React.useState(() => {
    const userStorage = localStorage.getItem(AUTH_STORAGE_KEY)
    return userStorage ? JSON.parse(userStorage) : null
  })
  const [isLogginOut, setIsLogginOut] = React.useState(false)
  const {isSuccess, isFetching, isError, error} = useQuery(
    ['logout'],
    () => getPromise('/auth/logout', user),
    {
      retry: false,
      enabled: isLogginOut,
    }
  )

  const clearUser = React.useCallback(() => {
    Object.keys(localStorage).forEach(key => {
      localStorage.setItem(key, null)
    })
    setUser(null)
  }, [])

  const handleLogout = React.useCallback(() => {
    setIsLogginOut(true)
  }, [])

  const handleUser = React.useCallback(userData => {
    setUser(userData)
    setStorageItem(AUTH_STORAGE_KEY, userData)
  }, [])

  const value = React.useMemo(
    () => ({
      user,
      handleUser,
      handleLogout,
      clearUser,
    }),
    [user, handleUser, handleLogout, clearUser]
  )

  React.useEffect(() => {
    const mounted = localStorage.getItem(MOUNTED_KEY)
    if (user && !mounted) {
      /** so app can cache assests correctly */
      localStorage.setItem(MOUNTED_KEY, true)
      window.location.reload()
    }
  }, [user])

  React.useEffect(() => {
    if (isSuccess && !isFetching) {
      clearUser()
      setIsLogginOut(false)
    }
  }, [clearUser, isFetching, isSuccess])

  React.useEffect(() => {
    if (isError && error.status === 401) {
      clearUser()
      setIsLogginOut(false)
    }
  }, [isError, error, clearUser])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const useAuth = () => {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw Error('useAuth must be used within an AuthProvider')
  }
  return context
}

export {useAuth}
export default AuthProvider
