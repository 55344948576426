import {Flex, Text} from '@chakra-ui/react'
import * as React from 'react'
import {PoolsTable, PoolsFilters} from '../components/Pools'
import {PagePlaceholder} from '../components/Placeholder'
import Acl from '../components/Acl'
import CreateEntityButton from '../components/CreateEntityButton/CreateEntityButton'
import PoolFormModal from '../components/Pools/PoolFormModal'
import useFilters from '../hooks/useFilters'
import useSort from '../hooks/useSort'
import usePagination from '../hooks/usePagination'
import {useList} from '../hooks/entities'
import {ENTITIES, ENTITIES_NAMES} from '../lib/entities'
import {
  poolPermissionsCreate,
  poolPermissionsList,
} from '../components/Pools/Pools.helpers'

const Pools = () => {
  const [itemsTotal, setItemsTotal] = React.useState()
  const {pages, pagesCount, currentPage, handlePageChange} =
    usePagination(itemsTotal)
  const [sort, handleSort] = useSort('pool_name')
  const {filters, handleFilters, handleRemoveFilter} = useFilters()
  const {data, isLoading, isSuccess} = useList({
    entity: ENTITIES.pools,
    currentPage,
    filters,
    sort,
  })

  if (isLoading) {
    return <PagePlaceholder />
  }

  if (isSuccess && itemsTotal !== data?.propertyPools.total) {
    setItemsTotal(data?.propertyPools.total)
  }

  return (
    <Flex gap={4} direction="column">
      <Flex align="center">
        <Text fontSize="xl" fontWeight="bold">
          Property pools
        </Text>
        <Flex
          gap={2}
          ml="auto"
          align="center"
          direction={{
            base: 'column',
            lg: 'row',
          }}
        >
          <Flex gap={2}>
            <PoolsFilters
              filters={filters}
              onFilter={handleFilters}
              onRemoveFilter={handleRemoveFilter}
            />
          </Flex>
          <CreateEntityButton
            entity={ENTITIES.pools}
            entityName={ENTITIES_NAMES.pools}
            permissionCreate={poolPermissionsCreate}
            sort={sort}
            filters={filters}
            currentPage={currentPage}
            formModal={PoolFormModal}
          />
        </Flex>
      </Flex>
      <PoolsTable
        items={data?.propertyPools.data}
        sort={sort}
        filters={filters}
        pages={pages}
        itemsTotal={itemsTotal}
        pagesCount={pagesCount}
        currentPage={currentPage}
        onSort={handleSort}
        onPageChange={handlePageChange}
      />
    </Flex>
  )
}

const ProtectedPools = () => (
  <Acl withRedirect requiredPermissions={poolPermissionsList}>
    <Pools />
  </Acl>
)

export default ProtectedPools
