import {
  Box,
  Flex,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Button,
  useToast,
} from '@chakra-ui/react'
import * as React from 'react'
import {Controller, useForm} from 'react-hook-form'
import {Select} from '../Select'
import {
  getCurrentWeek,
  getDefaultValues,
  getDefaultWeek,
  getIsOverlapingShifts,
  getScheduleDate,
  getUserOptions,
  WEEK_DAYS,
} from '../../lib/form/schedules'
import {getDatesOfWeek, schedulePermissionsDelete} from './Schedules.helpers'
import WeekPicker from './WeekPicker'
import {getDateToDb, getFullDateToDb} from '../../lib/form'
import {formatDate} from '../../lib/formatters'
import Acl from '../Acl'

const SchedulesForm = ({
  schedule,
  officers,
  properties,
  onDelete,
  onSubmit,
  isSubmiting,
}) => {
  const {
    register,
    control,
    reset,
    watch,
    setValue,
    setError,
    getValues,
    handleSubmit,
    formState: {errors},
  } = useForm({
    defaultValues: getDefaultValues({
      schedule,
      officers,
      properties,
    }),
  })
  const [weekdayError, setWeekdayError] = React.useState(null)
  const [currentWeek, setCurrentWeek] = React.useState({
    week: getDefaultWeek(),
    dates: getCurrentWeek(new Date()),
  })
  const toast = useToast({
    position: 'top',
    isClosable: true,
  })

  const propertyIdWatch = watch('propertyId')

  const filteredOfficers = getUserOptions(officers, propertyIdWatch?.value)

  const isEditingSchedule = Boolean(schedule)
  const isUserDisabled = !propertyIdWatch || isEditingSchedule

  const handleWeekChange = week => {
    const dates = getDatesOfWeek(week)
    setCurrentWeek({
      week: week[1],
      dates,
    })
  }

  const setWeekdaysSelected = state => {
    WEEK_DAYS.forEach(day => {
      setValue(day, state)
    })
  }

  const handleSelectAll = () => {
    setWeekdaysSelected(true)
  }

  const handleClearAll = () => {
    setWeekdaysSelected(false)
  }

  const handleFormSubmit = async values => {
    try {
      if (!getValues(WEEK_DAYS).some(Boolean) && !isEditingSchedule) {
        setWeekdayError('You must select at least one day')
        return
      }

      setWeekdayError(null)

      const {
        propertyId: {value: propertyId},
        users,
        startTime,
        endTime,
        ...weekDaysData
      } = values

      const scheduleData = {
        propertyId,
        dates: [],
      }

      if (users) {
        scheduleData.users = users.map(({value}) => ({
          userId: value,
        }))
      }

      if (isEditingSchedule) {
        const dateStr = schedule.startTime.slice(0, 10)
        const {startDate, endDate} = getScheduleDate(
          dateStr,
          startTime,
          endTime
        )

        scheduleData.dates.push({
          startDate: getFullDateToDb(startDate),
          endDate: getFullDateToDb(endDate),
        })
      } else {
        currentWeek.dates.forEach((dateObj, i) => {
          const weekDayKey = WEEK_DAYS[i]
          if (weekDaysData[weekDayKey]) {
            const dateStr = getDateToDb(dateObj, {withHours: false})
            const {startDate, endDate} = getScheduleDate(
              dateStr,
              startTime,
              endTime
            )

            scheduleData.dates.push({
              startDate: getFullDateToDb(startDate),
              endDate: getFullDateToDb(endDate),
            })
          }
        })
      }

      if (scheduleData.users?.length) {
        const IsOverlapingShifts = getIsOverlapingShifts(scheduleData, officers)

        if (IsOverlapingShifts) {
          setError('users', {
            type: 'custom',
            message: 'Some users have an overlaping schedule',
          })
          return
        }
      }

      onSubmit(scheduleData)
    } catch (e) {
      toast({
        title: 'Oops, there is some error in your data',
      })
    }
  }

  const handleDelete = () => {
    onDelete(schedule.officerScheduleId)
  }

  React.useEffect(() => {
    if (!isEditingSchedule) {
      setValue('users', [])
    }
  }, [propertyIdWatch, isEditingSchedule, setValue])

  React.useEffect(
    () => () => {
      reset()
    },
    [reset]
  )

  return (
    <form id="user-form" onSubmit={handleSubmit(handleFormSubmit)}>
      {isEditingSchedule ? (
        <Box mb={6}>
          <Text fontSize="lg">
            {formatDate(
              schedule.startTime,
              {withHours: false},
              {weekday: 'long'}
            )}
          </Text>
        </Box>
      ) : null}
      <Flex mb={2} gap={2}>
        <FormControl
          flexBasis="50%"
          isInvalid={errors?.propertyId}
          isDisabled={isSubmiting}
        >
          <FormLabel>Property</FormLabel>
          <Controller
            control={control}
            name="propertyId"
            rules={{
              validate: value =>
                value !== undefined || 'You must select a property',
            }}
            render={({field}) => (
              <Select
                isClearable
                isDisabled={
                  (isEditingSchedule && schedule.propertyId) || isSubmiting
                }
                placeholder="Select a property"
                options={properties}
                hasError={Boolean(errors?.propertyId)}
                {...field}
              />
            )}
          />
          {errors?.propertyId ? (
            <FormErrorMessage>{errors.propertyId.message}</FormErrorMessage>
          ) : null}
        </FormControl>

        <FormControl
          flexBasis="50%"
          isInvalid={errors?.users}
          isDisabled={isSubmiting}
        >
          <FormLabel>Officers</FormLabel>
          <Controller
            control={control}
            name="users"
            render={({field}) => (
              <Select
                isClearable
                isMulti
                isDisabled={isUserDisabled || isSubmiting}
                placeholder="Select officers"
                options={filteredOfficers}
                hasError={Boolean(errors?.users)}
                {...field}
              />
            )}
          />
          {errors?.users ? (
            <FormErrorMessage>{errors.users.message}</FormErrorMessage>
          ) : null}
        </FormControl>
      </Flex>

      <Flex mb={2} gap={2}>
        <FormControl flexBasis="50%" isInvalid={errors?.startTime?.message}>
          <FormLabel>Begin</FormLabel>
          <Input
            type="time"
            {...register('startTime', {
              required: 'You must enter a begin time',
            })}
          />
          {errors?.startTime ? (
            <FormErrorMessage>{errors?.startTime.message}</FormErrorMessage>
          ) : null}
        </FormControl>
        <FormControl flexBasis="50%" isInvalid={errors?.endTime?.message}>
          <FormLabel>End</FormLabel>
          <Input
            type="time"
            {...register('endTime', {
              required: 'You must enter a end time',
            })}
          />
          {errors?.endTime ? (
            <FormErrorMessage>{errors?.endTime.message}</FormErrorMessage>
          ) : null}
        </FormControl>
      </Flex>

      {!isEditingSchedule ? (
        <>
          <Flex align="center" mt={6} mb={4}>
            <Text fontSize="lg" fontWeight="semibold">
              Add to
            </Text>
            <Flex ml="auto" gap={4} align="center">
              <WeekPicker
                handleWeekChange={handleWeekChange}
                currentWeek={currentWeek.week}
              />
              <Button
                variant="solid"
                colorScheme="blue"
                type="button"
                fontSize="xs"
                onClick={handleSelectAll}
              >
                Select all
              </Button>
              <Button
                variant="ghost"
                colorScheme="blue"
                type="button"
                fontSize="xs"
                onClick={handleClearAll}
              >
                Clear all
              </Button>
            </Flex>
          </Flex>
          <Flex gap={4} mb={4}>
            {currentWeek.dates.map(date => (
              <FormControl key={date} w="fit-content">
                <Flex align="center" gap={2}>
                  <input
                    type="checkbox"
                    {...register(WEEK_DAYS[date.getDay()])}
                  />
                  <FormLabel fontSize="sm" m="0">
                    {new Intl.DateTimeFormat('en-US', {
                      weekday: 'short',
                      day: '2-digit',
                    }).format(date.getTime())}
                  </FormLabel>
                </Flex>
              </FormControl>
            ))}
          </Flex>
          {weekdayError ? (
            <FormControl isInvalid>
              <FormErrorMessage>{weekdayError}</FormErrorMessage>
            </FormControl>
          ) : null}
        </>
      ) : null}
      {isEditingSchedule ? (
        <Acl requiredPermissions={schedulePermissionsDelete}>
          <Flex justify="center" mt={8}>
            <Button
              variant="outline"
              colorScheme="red"
              type="button"
              onClick={handleDelete}
            >
              Delete shift
            </Button>
          </Flex>
        </Acl>
      ) : null}
    </form>
  )
}

export default SchedulesForm
