import {Flex, Text, Button} from '@chakra-ui/react'

const PositionError = () => {
  const handleReload = () => {
    window.location.reload()
  }

  return (
    <Flex
      w={{base: '100%', lg: '600px'}}
      h="100%"
      mx="0"
      ml="auto"
      mr="auto"
      p={8}
      direction="column"
      justify="center"
      align="center"
      gap={8}
    >
      <Text as="h1" fontSize="lg" fontWeight="bold">
        Stars pro access
      </Text>
      <Text as="h2" textAlign="align">
        You must enabled geolocation permissions to continue
      </Text>
      <Button variant="solid" colorScheme="blue" w="40%" onClick={handleReload}>
        Reload
      </Button>
    </Flex>
  )
}

export default PositionError
