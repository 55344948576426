import {Flex, Text, useToast} from '@chakra-ui/react'
import * as React from 'react'
import {useAuth} from '../../../context/auth'
import Paginator from '../../Paginator'
import DeleteModal from '../../DeleteModal'
import UsersDesktopTable from './UsersDesktopTable'
import UsersMobileTable from './UsersMobileTable'
import {getUsersWithActions} from '../Users.helpers'
import {usePatch} from '../../../hooks/entities'
import {isObjectEmpty} from '../../../lib/objects'
import {ENTITIES, ENTITIES_KEYS} from '../../../lib/entities'
import {MODAL_KEYS} from '../../../lib/modal'
import UserFormModal from '../UserFormModal'

const UsersTable = ({
  items,
  sort,
  itemsTotal,
  pages,
  pagesCount,
  currentPage,
  filters,
  onSort,
  onPageChange,
}) => {
  const [userId, setUserId] = React.useState()
  const [activeModal, setActiveModal] = React.useState()
  const {user} = useAuth()
  const {mutate, isLoading} = usePatch({
    id: userId,
    entity: ENTITIES.users,
    currentPage,
    filters,
    sort,
  })
  const toast = useToast({
    position: 'top',
    isClosable: true,
  })

  const handleDetails = id => {
    setUserId(id)
    setActiveModal(MODAL_KEYS.details)
  }

  const handleEdit = id => {
    setUserId(id)
    setActiveModal(MODAL_KEYS.edit)
  }

  const handleDelete = id => {
    setUserId(id)
    setActiveModal(MODAL_KEYS.delete)
  }

  const handleModalClose = () => {
    setUserId(null)
    setActiveModal(null)
  }

  const handleUpdate = userData => {
    mutate(
      {
        ...userData,
        userId,
      },
      {
        onSuccess() {
          toast({
            title: 'User updated!',
            status: 'success',
          })
          handleModalClose()
        },
        onError() {
          toast({
            title: 'Error updating user',
            status: 'error',
          })
        },
      }
    )
  }

  const users = getUsersWithActions(items, user)

  if (!users?.length && isObjectEmpty(filters)) {
    return (
      <Flex justify="center" boxShadow="lg" p={4}>
        <Text>No data found for this section</Text>
      </Flex>
    )
  }

  if (!users?.length && !isObjectEmpty(filters)) {
    return (
      <Flex justify="center" boxShadow="lg" p={4}>
        <Text>No data found for the filters applied</Text>
      </Flex>
    )
  }

  return (
    <>
      <UsersMobileTable
        users={users}
        onDetails={handleDetails}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
      <UsersDesktopTable
        users={users}
        sort={sort}
        onSort={onSort}
        onDetails={handleDetails}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />

      <Paginator
        itemsTotal={itemsTotal}
        pages={pages}
        pagesCount={pagesCount}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />

      {activeModal === MODAL_KEYS.edit ? (
        <UserFormModal
          userId={userId}
          isOpen={activeModal === MODAL_KEYS.edit}
          isSubmiting={isLoading}
          onClose={handleModalClose}
          onSubmit={handleUpdate}
        />
      ) : null}

      {activeModal === MODAL_KEYS.delete ? (
        <DeleteModal
          id={userId}
          sort={sort}
          entityName="User"
          filters={filters}
          entity={ENTITIES.users}
          currentPage={currentPage}
          entityKey={ENTITIES_KEYS.users}
          isOpen={activeModal === MODAL_KEYS.delete}
          onClose={handleModalClose}
        />
      ) : null}
    </>
  )
}

export default UsersTable
