import {Flex, Icon, Text, Button, useToast} from '@chakra-ui/react'
import * as React from 'react'
import {MdDelete} from 'react-icons/md'
import {PermitsTable, PermitsFilters} from '../components/Permits'
import {PagePlaceholder} from '../components/Placeholder'
import MobileSort from '../components/MobileSort'
import Acl from '../components/Acl'
import CreateEntityButton from '../components/CreateEntityButton/CreateEntityButton'
import PermitFormModal from '../components/Permits/PermitFormModal'
import useFilters from '../hooks/useFilters'
import useSort from '../hooks/useSort'
import usePagination from '../hooks/usePagination'
import {useBulkDelete, useList} from '../hooks/entities'
import {ENTITIES, ENTITIES_KEYS, ENTITIES_NAMES} from '../lib/entities'
import {
  sortFields,
  permitPermissionsList,
  permitPermissionsCreate,
} from '../components/Permits/Permits.helpers'

const Permits = () => {
  const [itemsTotal, setItemsTotal] = React.useState()
  const {pages, pagesCount, currentPage, handlePageChange} =
    usePagination(itemsTotal)
  const [sort, handleSort] = useSort('user_id')
  const {filters, handleFilters, handleRemoveFilter} = useFilters()
  const [selectedPermits, setSelectedPermits] = React.useState([])
  const {mutate, isLoading: isDeleting} = useBulkDelete({
    entity: ENTITIES.permits,
    currentPage,
    filters,
    sort,
  })
  const {data, isLoading, isSuccess} = useList({
    entity: ENTITIES.permits,
    currentPage,
    filters,
    sort,
  })
  const toast = useToast({
    position: 'top',
    isClosable: true,
  })

  const handleSelectedPermit = values => {
    if (Array.isArray(values)) {
      setSelectedPermits(values)
    } else {
      const index = selectedPermits.findIndex(p => p === values)
      if (index !== -1) {
        setSelectedPermits(permits => {
          permits.splice(index, 1)
          return [...permits]
        })
      } else {
        setSelectedPermits(permits => [...permits, values])
      }
    }
  }

  const handleBulkDelete = () => {
    mutate(
      selectedPermits.map(p => ({[ENTITIES_KEYS.permits]: p})),
      {
        onSuccess() {
          toast({
            title: 'Permits deleted!',
            status: 'success',
          })
        },
        onError() {
          toast({
            title: 'Error deleting permits',
            status: 'error',
          })
        },
      }
    )
  }

  if (isLoading) {
    return <PagePlaceholder />
  }

  if (isSuccess && itemsTotal !== data?.paperlessPermits.total) {
    setItemsTotal(data?.paperlessPermits.total)
  }

  return (
    <Flex gap={4} direction="column">
      <Flex align="center">
        <Text fontSize="xl" fontWeight="bold">
          Paperless permits
        </Text>
        <Flex
          gap={2}
          ml="auto"
          align="center"
          direction={{
            base: 'column',
            lg: 'row',
          }}
        >
          <Flex gap={2}>
            <Button
              variant="ghost"
              colorScheme="red"
              disabled={!selectedPermits.length}
              onClick={handleBulkDelete}
              isLoading={isDeleting}
            >
              <Icon as={MdDelete} />
            </Button>
            <PermitsFilters
              filters={filters}
              onFilter={handleFilters}
              onRemoveFilter={handleRemoveFilter}
            />
            <MobileSort
              sort={sort}
              sortFields={sortFields}
              onSort={handleSort}
            />
          </Flex>
          <CreateEntityButton
            entity={ENTITIES.permits}
            entityName={ENTITIES_NAMES.permits}
            permissionCreate={permitPermissionsCreate}
            sort={sort}
            filters={filters}
            currentPage={currentPage}
            formModal={PermitFormModal}
          />
        </Flex>
      </Flex>
      <PermitsTable
        items={data?.paperlessPermits.data}
        sort={sort}
        filters={filters}
        pages={pages}
        itemsTotal={itemsTotal}
        pagesCount={pagesCount}
        currentPage={currentPage}
        selectedPermits={selectedPermits}
        onSort={handleSort}
        onPageChange={handlePageChange}
        onPermitSelect={handleSelectedPermit}
      />
    </Flex>
  )
}

const ProtectedPermits = () => (
  <Acl withRedirect requiredPermissions={permitPermissionsList}>
    <Permits />
  </Acl>
)

export default ProtectedPermits
