const formatDate = (
  date,
  {withHours = true, isUTC = true} = {},
  customOptions = {}
) => {
  let time
  if (date instanceof Date) {
    time = date.getTime()
  } else if (typeof date === 'string') {
    /** safari does not suppor '-' as divider for dates */
    let stringDate = date.replace(/-/g, '/')
    if (isUTC) {
      stringDate = `${stringDate} UTC`
    }
    time = new Date(stringDate).getTime()
  }
  const options = {
    year: '2-digit',
    month: 'numeric',
    day: 'numeric',
    ...customOptions,
  }
  if (withHours) {
    options.hour = 'numeric'
    options.minute = 'numeric'
  }
  return new Intl.DateTimeFormat('en-US', options).format(time)
}

const formatTime = date => {
  /** safari does not suppor '-' as divider for dates */
  const stringDate = date.replace(/-/g, '/')
  const time = new Date(stringDate).getTime()

  const options = {
    hour: 'numeric',
    minute: 'numeric',
  }

  return new Intl.DateTimeFormat('en-US', options).format(time)
}

const toCamelCase = str =>
  str
    .split(/[ _]/g)
    .map((t, i) => {
      if (i === 0) {
        return t.toLowerCase()
      }
      const cap = t[0].toUpperCase()
      return cap + t.substring(1).toLowerCase()
    })
    .join('')

const toSnakeCase = string =>
  string
    .replace(/([a-z])([A-Z])/g, '$1_$2')
    .replace(/[\s_]+/g, '_')
    .toLowerCase()

const objToSnakeCase = formData =>
  Object.keys(formData).reduce((acc, key) => {
    const newKey = toSnakeCase(key)
    let value = formData[key]

    if (value && typeof value === 'object' && !Array.isArray(value)) {
      value = objToSnakeCase(value)
    } else if (
      Array.isArray(value) &&
      value.some(e => typeof e === 'object' && !Array.isArray(e))
    ) {
      value = value.map(objToSnakeCase)
    }

    return {
      ...acc,
      [newKey]: value,
    }
  }, {})

const objToCamelCase = obj =>
  Object.keys(obj).reduce((acc, key) => {
    const newKey = toCamelCase(key)
    let value = obj[key]
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      value = objToCamelCase(value)
    } else if (
      Array.isArray(value) &&
      value.some(e => typeof e === 'object' && !Array.isArray(e))
    ) {
      value = value.map(objToCamelCase)
    }

    return {
      ...acc,
      [newKey]: value,
    }
  }, {})

const formatQueryParams = params =>
  params
    ? Object.keys(params).reduce(
        (acc, key) =>
          acc
            ? `${acc}&${toSnakeCase(key)}=${params[key]}`
            : `${toSnakeCase(key)}=${params[key]}`,
        ''
      )
    : ''

export {
  formatDate,
  formatTime,
  formatQueryParams,
  objToCamelCase,
  objToSnakeCase,
  toCamelCase,
  toSnakeCase,
}
