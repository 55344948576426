import {Flex} from '@chakra-ui/react'
import PropertyMenu from './PropertyMenu'
import UserMenu from './UserMenu'

const Header = () => (
  <Flex
    as="header"
    align="center"
    justify="center"
    borderBottom="1px solid"
    borderColor="gray.300"
    py={4}
    gridArea="header"
    px={{
      base: '64px',
      lg: '32px',
    }}
  >
    <PropertyMenu />
    <UserMenu />
  </Flex>
)

export default Header
