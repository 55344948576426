import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Textarea,
  useToast,
} from '@chakra-ui/react'
import * as React from 'react'
import {useForm} from 'react-hook-form'
import {useUserProperties} from '../../context/property'
import {usePosition} from '../../context/position'
import {getDefaultValues} from '../../lib/form/briefs'

const BriefsForm = function ({brief, isSubmiting, onSubmit}) {
  const {getCoordinates} = usePosition()
  const {currentProperty} = useUserProperties()
  const {
    register,
    reset,
    handleSubmit,
    formState: {errors},
  } = useForm({
    defaultValues: getDefaultValues(brief),
  })
  const toast = useToast({
    position: 'top',
    isClosable: true,
  })

  const handleFormSubmit = async values => {
    try {
      const {propertyId} = currentProperty
      const {latitude, longitude} = await getCoordinates()
      const gpsCoordinates = `${latitude},${longitude}`

      const briefData = {
        ...values,
        propertyId,
        gpsCoordinates,
      }

      onSubmit(briefData)
    } catch (e) {
      toast({
        title: 'Oops, there is some error in your data',
      })
    }
  }

  React.useEffect(
    () => () => {
      reset()
    },
    [reset]
  )

  return (
    <form id="brief-form" onSubmit={handleSubmit(handleFormSubmit)}>
      <FormControl
        flexBasis="50%"
        isInvalid={errors?.dailyActivityReportTypeId}
        isDisabled={isSubmiting}
      >
        <FormLabel>Brief</FormLabel>
        <Textarea
          {...register('brief', {required: 'This field is required'})}
        />
        {errors?.dailyActivityReportTypeId ? (
          <FormErrorMessage>
            {errors.dailyActivityReportTypeId.message}
          </FormErrorMessage>
        ) : null}
      </FormControl>
    </form>
  )
}

export default BriefsForm
