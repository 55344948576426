import {
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
} from '@chakra-ui/react'

const ConfirmModal = ({
  title,
  text,
  cancelLabel = 'Cancel',
  confirmLabel = 'Confirm',
  closeOnlyonCancel = false,
  isOpen,
  onConfirm,
  onCancel,
  blockScrollOnMount = true,
}) => (
  <Modal
    isCentered
    isOpen={isOpen}
    onClose={closeOnlyonCancel ? () => {} : onCancel}
    blockScrollOnMount={blockScrollOnMount}
  >
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>{title}</ModalHeader>
      {closeOnlyonCancel ? null : <ModalCloseButton />}
      <ModalBody>
        <Text fontSize="sm">{text}</Text>
      </ModalBody>
      <ModalFooter>
        {onCancel ? (
          <Button variant="outline" colorScheme="blue" onClick={onCancel}>
            {cancelLabel}
          </Button>
        ) : null}
        {onConfirm ? (
          <Button variant="solid" colorScheme="blue" ml={2} onClick={onConfirm}>
            {confirmLabel}
          </Button>
        ) : null}
      </ModalFooter>
    </ModalContent>
  </Modal>
)

export default ConfirmModal
