import {useAuthQuery} from './useAuthQuery'

const KEYS = {
  roles: ['roles'],
}

const useRoles = ({enabled = false}) => {
  const rolesState = useAuthQuery({
    keys: KEYS.roles,
    endpoint: '/role',
    queryOptions: {
      enabled,
    },
  })

  return rolesState
}

export {useRoles}
