import {
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast,
  Checkbox,
  Textarea,
  Input,
} from '@chakra-ui/react'
import * as React from 'react'
import {Controller, useForm} from 'react-hook-form'
import {AsyncSelect, Select} from '../Select'
import {FilesInput} from '../Commons'
import {getDefaultValues} from '../../lib/form/poolReports'
import {useUserProperties} from '../../context/property'
import {usePosition} from '../../context/position'
import {getFullDateToDb, imagesRgx} from '../../lib/form'
import {useHouseholdsByCriteria} from '../../hooks/households'
import {useAuth} from '../../context/auth'

const PoolReportForm = ({
  poolReport,
  pools,
  codes,
  types,
  isSubmiting,
  onSubmit,
}) => {
  const {user} = useAuth()
  const [files, setFiles] = React.useState(poolReport?.media?.thumbImages ?? [])
  const {getCoordinates} = usePosition()
  const {currentProperty} = useUserProperties()
  const {handleLoadOptions} = useHouseholdsByCriteria()
  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: {errors},
  } = useForm({
    defaultValues: getDefaultValues({poolReport, codes, types, pools}),
  })
  const toast = useToast({
    position: 'top',
    isClosable: true,
  })

  const handleFiles = selectedFiles => {
    setFiles(selectedFiles)
  }

  const handleFormSubmit = async values => {
    try {
      const {propertyId} = currentProperty
      const {latitude, longitude} = await getCoordinates()
      const gpsCoordinates = `${latitude},${longitude}`

      const {
        incidentReportTypeId: {value: incidentReportTypeId} = {},
        dailyActivityReportCodeId: {value: dailyActivityReportCodeId} = {},
        propertyPoolId: {value: propertyPoolId} = {},
        details,
        sendNotification,
      } = values

      const images = files.filter(file => file.startsWith('data:image/'))
      let imagesDel

      if (poolReport?.thumbImages) {
        imagesDel = poolReport.thumbImages.filter(
          i => !files.find(f => f === i)
        )
      }

      let residents = values.residents?.map(({value}) => ({userId: value}))
      let residentsDel
      if (poolReport) {
        residentsDel = poolReport.poolDetails?.residents.filter(
          r => !residents.find(p => p.userId === r.userId)
        )
        residents = residents.filter(
          r =>
            !poolReport.poolDetails?.residents.find(p => p.userId === r.userId)
        )
      }

      const poolReportData = {
        propertyId,
        incidentReportTypeId,
        dailyActivityReportCodeId,
        propertyPoolId,
        details,
        images,
        imagesDel,
        gpsCoordinates,
        residents,
        residentsDel,
        sendNotification: Number(sendNotification) || 0,
      }

      if (values.date) {
        poolReportData.poolReportDate = getFullDateToDb(new Date(values.date))
      }

      onSubmit(poolReportData)
    } catch (e) {
      toast({
        title: 'Oops, there is some error in your data',
      })
    }
  }

  React.useEffect(
    () => () => {
      reset()
      setFiles([])
    },
    [reset]
  )

  return (
    <form id="poolreport-form" onSubmit={handleSubmit(handleFormSubmit)}>
      <Flex gap={2} mb={2}>
        <FormControl
          flexBasis="50%"
          isInvalid={errors?.incidentReportTypeId}
          isDisabled={isSubmiting}
        >
          <FormLabel>Incident type</FormLabel>
          <Controller
            control={control}
            name="incidentReportTypeId"
            rules={{
              validate: ({value} = {}) =>
                value !== undefined || 'You must select a DAR type',
              valueAsNumber: true,
            }}
            render={({field}) => (
              <Select
                isClearable
                isDisabled={isSubmiting}
                placeholder="Select a type"
                options={types}
                {...field}
              />
            )}
          />
          {errors?.incidentReportTypeId ? (
            <FormErrorMessage>
              {errors.incidentReportTypeId.message}
            </FormErrorMessage>
          ) : null}
        </FormControl>
        <FormControl
          flexBasis="50%"
          isInvalid={errors?.dailyActivityReportCodeId}
          isDisabled={isSubmiting}
        >
          <FormLabel>Code</FormLabel>
          <Controller
            control={control}
            name="dailyActivityReportCodeId"
            rules={{
              validate: ({value} = {}) =>
                value !== undefined || 'You must select a code',
              valueAsNumber: true,
            }}
            render={({field}) => (
              <Select
                isClearable
                isDisabled={isSubmiting}
                placeholder="Select a code"
                options={codes}
                {...field}
              />
            )}
          />
          {errors?.dailyActivityReportCodeId ? (
            <FormErrorMessage>
              {errors.dailyActivityReportCodeId.message}
            </FormErrorMessage>
          ) : null}
        </FormControl>
      </Flex>

      {user.isAdmin ? (
        <FormControl mb={2}>
          <FormLabel>Date</FormLabel>
          <Input type="date" isDisabled={isSubmiting} {...register('date')} />
        </FormControl>
      ) : null}

      <FormControl
        mb={2}
        isInvalid={errors?.propertyPoolId}
        isDisabled={isSubmiting}
      >
        <FormLabel>Pool</FormLabel>
        <Controller
          control={control}
          name="propertyPoolId"
          rules={{
            validate: ({value} = {}) =>
              value !== undefined || 'You must select a pool',
            valueAsNumber: true,
          }}
          render={({field}) => (
            <Select
              isClearable
              isDisabled={isSubmiting}
              placeholder="Select a code"
              options={pools}
              {...field}
            />
          )}
        />
        {errors?.propertyPoolId ? (
          <FormErrorMessage>{errors.propertyPoolId.message}</FormErrorMessage>
        ) : null}
      </FormControl>

      <FormControl mb={2} isDisabled={isSubmiting}>
        <FormLabel>Household (optional)</FormLabel>
        <Controller
          control={control}
          name="residents"
          render={({field}) => (
            <AsyncSelect
              isClearable
              isSearchable
              cacheOptions
              isMulti
              placeholder="Select a household or a resident"
              loadOptions={handleLoadOptions}
              {...field}
            />
          )}
        />
      </FormControl>

      <FormControl mb={8} isInvalid={errors?.details} isDisabled={isSubmiting}>
        <FormLabel>Details</FormLabel>
        <Textarea
          resize="none"
          {...register('details', {required: 'This field is required'})}
        />
        {errors?.details ? (
          <FormErrorMessage>{errors.details.message}</FormErrorMessage>
        ) : null}
      </FormControl>

      <FilesInput
        files={files}
        onChange={handleFiles}
        validFileExtensionsReg={imagesRgx}
        extensionErrorMessage="Wrong file extensions. Please select a jpeg, png or bmp file."
      />

      <FormControl isDisabled={isSubmiting}>
        <FormLabel>Send notification to prop. manager</FormLabel>
        <Checkbox borderColor="gray.400" {...register('sendNotification')} />
      </FormControl>
    </form>
  )
}

export default PoolReportForm
