import {getEntitiesWithActions} from '../../lib/entities'

const poolPermissionsList = ['property_pool list', 'property_pool show']
const poolPermissionsCreate = ['property_pool create', 'property_pool store']
const poolPermissionsEdit = ['property_pool update']
const poolPermissionsDelete = ['property_pool delete']

const getPoolsWithActions = (vehicles, user) =>
  getEntitiesWithActions(
    vehicles,
    user,
    poolPermissionsEdit,
    poolPermissionsDelete
  )

export {
  poolPermissionsList,
  poolPermissionsCreate,
  poolPermissionsEdit,
  poolPermissionsDelete,
  getPoolsWithActions,
}
