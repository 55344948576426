import * as React from 'react'
import useSocket, {EVENT_TYPES} from '../../hooks/useSocket'
import EOSEventModal from './EOSEventModal'
import EOSPostPone from './EOSPostPone'

const OfficerEOS = () => {
  const [event, setEvent] = useSocket({
    channel: 'home',
    eventName: 'ShiftAboutToEndEvent',
  })
  const [isPostponing, setIsPostponing] = React.useState(false)

  const isEOSEvent = event?.type === EVENT_TYPES.eos

  const handleIsPostPoning = () => {
    setIsPostponing(true)
  }

  const handleClose = () => {
    setEvent(null)
    setIsPostponing(false)
  }

  return (
    <>
      {isEOSEvent && !isPostponing ? (
        <EOSEventModal
          onPostPone={handleIsPostPoning}
          onLeaveOnTime={handleClose}
        />
      ) : null}
      {isEOSEvent && isPostponing ? (
        <EOSPostPone event={event} onClose={handleClose} />
      ) : null}
    </>
  )
}

export default OfficerEOS
