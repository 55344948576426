import {
  Flex,
  Button,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast,
} from '@chakra-ui/react'
import {useForm} from 'react-hook-form'
import {useNavigate, useParams} from 'react-router-dom'
import {useReset} from '../hooks/auth'

const Reset = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: {errors},
  } = useForm()
  const params = useParams()
  const navigate = useNavigate()
  const {mutate, isLoading} = useReset()
  const toast = useToast({
    position: 'top',
    isClosable: true,
  })

  const passwordWatch = watch('password')

  const onSubmit = formData => {
    const resetData = {
      ...formData,
      token: params.token,
    }

    mutate(resetData, {
      onSuccess() {
        toast({
          title: 'Pasword reseted successfully!',
          status: 'success',
        })
        navigate('/login')
      },
      onError(error) {
        toast({
          title: error.statusText,
          status: 'error',
        })
      },
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl isInvalid={errors?.password} mb={2}>
        <FormLabel>Password</FormLabel>
        <Input
          type="password"
          {...register('password', {required: 'This field is required'})}
        />
        {errors?.password ? (
          <FormErrorMessage>{errors.password.message}</FormErrorMessage>
        ) : null}
      </FormControl>
      <FormControl isInvalid={errors?.passwordConfirm} mb={8}>
        <FormLabel>Confirm password</FormLabel>
        <Input
          type="password"
          {...register('passwordConfirm', {
            validate: value => {
              if (!value) {
                return 'This field is required'
              }

              return value === passwordWatch || 'The passwords does not match'
            },
          })}
        />
        {errors?.passwordConfirm ? (
          <FormErrorMessage>{errors.passwordConfirm.message}</FormErrorMessage>
        ) : null}
      </FormControl>
      <Flex justify="center">
        <Button
          type="submit"
          variant="solid"
          colorScheme="blue"
          isLoading={isLoading}
        >
          Reset password
        </Button>
      </Flex>
    </form>
  )
}

export default Reset
