const getDefaultVehicle = violation => {
  if (!violation) {
    return ''
  }

  return {
    value: violation.vehicle.vehicleId,
    label: violation.vehicle.licensePlate,
  }
}

const getDefaultType = (violation, types) => {
  if (!violation) {
    return ''
  }

  return types.find(t => t.value === violation.violationTypeId)
}

const getDefaultTowCompany = (violation, tows) => {
  if (!violation) {
    return ''
  }

  return tows.find(t => t.value === violation.towCompanyId)
}

const getDefaultCode = (violation, codes) => {
  if (!violation) {
    return ''
  }

  return codes.find(t => t.value === violation.dailyActivityReportCodeId)
}

const getDefaultValues = ({violation, types, tows, codes}) => {
  const vehicleId = getDefaultVehicle(violation)
  const violationTypeId = getDefaultType(violation, types)
  const towCompanyId = getDefaultTowCompany(violation, tows)
  const dailyActivityReportCodeId = getDefaultCode(violation, codes)

  return {
    licensePlate: violation?.vehicle?.licensePlate ?? '',
    details: violation?.details ?? '',
    sendNotification: violation?.sendNotification ?? '',
    vehicleId,
    violationTypeId,
    towCompanyId,
    dailyActivityReportCodeId,
  }
}

export {getDefaultValues}
