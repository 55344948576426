import {
  Flex,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast,
} from '@chakra-ui/react'
import {useForm} from 'react-hook-form'
import {useUserProperties} from '../../context/property'
import {getDefaultValues} from '../../lib/form/households'
import {validateMail} from '../../lib/form'

const HouseholdsForm = ({household, isSubmiting, onSubmit}) => {
  const {currentProperty} = useUserProperties()
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    defaultValues: getDefaultValues(household),
  })
  const toast = useToast({
    position: 'top',
    isClosable: true,
  })

  const handleFormSubmit = async values => {
    try {
      const {propertyId} = currentProperty

      const householdData = {
        ...values,
        propertyId,
      }

      onSubmit(householdData)
    } catch (e) {
      toast({
        title: 'Oops, there is some error in your data',
      })
    }
  }

  return (
    <form
      id="household-form"
      onSubmit={handleSubmit(handleFormSubmit)}
      noValidate
    >
      <Flex gap={2} mb={2}>
        <FormControl
          flexBasis="50%"
          isInvalid={errors?.name}
          isDisabled={isSubmiting}
        >
          <FormLabel>Name</FormLabel>
          <Input
            type="text"
            {...register('name', {
              required: 'This field is required',
            })}
          />
          {errors?.name ? (
            <FormErrorMessage>{errors.name.message}</FormErrorMessage>
          ) : null}
        </FormControl>
        <FormControl
          flexBasis="50%"
          isInvalid={errors?.email}
          isDisabled={isSubmiting}
        >
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            {...register('email', {
              validate: value => {
                if (!value) {
                  return 'This field is required'
                }

                return validateMail(value) || 'Wrong format: jon@doe.com'
              },
            })}
          />
          {errors?.email ? (
            <FormErrorMessage>{errors.email.message}</FormErrorMessage>
          ) : null}
        </FormControl>
      </Flex>

      <Flex gap={2} mb={2}>
        <FormControl
          flexBasis="50%"
          isInvalid={errors?.number}
          isDisabled={isSubmiting}
        >
          <FormLabel>Number</FormLabel>
          <Input
            type="text"
            {...register('number', {required: 'This field is required'})}
          />
          {errors?.number ? (
            <FormErrorMessage>{errors.number.message}</FormErrorMessage>
          ) : null}
        </FormControl>

        <FormControl
          flexBasis="50%"
          isInvalid={errors?.street}
          isDisabled={isSubmiting}
        >
          <FormLabel>Street</FormLabel>
          <Input
            type="text"
            {...register('street', {required: 'This field is required'})}
          />
          {errors?.street ? (
            <FormErrorMessage>{errors.street.message}</FormErrorMessage>
          ) : null}
        </FormControl>
      </Flex>

      <Flex gap={2} mb={8}>
        <FormControl
          flexBasis="50%"
          isInvalid={errors?.firstName}
          isDisabled={isSubmiting}
        >
          <FormLabel>First name</FormLabel>
          <Input
            type="text"
            {...register('firstName', {
              required: 'This field is required',
            })}
          />
          {errors?.firstName ? (
            <FormErrorMessage>{errors.firstName.message}</FormErrorMessage>
          ) : null}
        </FormControl>
        <FormControl
          flexBasis="50%"
          isInvalid={errors?.lastName}
          isDisabled={isSubmiting}
        >
          <FormLabel>Last name</FormLabel>
          <Input
            type="text"
            {...register('lastName', {required: 'This field is required'})}
          />
          {errors?.lastName ? (
            <FormErrorMessage>{errors.lastName.message}</FormErrorMessage>
          ) : null}
        </FormControl>
      </Flex>
    </form>
  )
}

export default HouseholdsForm
