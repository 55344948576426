import {useAuthQuery} from './useAuthQuery'
import {ENTITIES} from '../lib/entities'

const KEYS = {
  codes: ['dar_codes'],
  types: ['dar_types'],
}

const useDarCodes = ({enabled = false}) => {
  const darCodesState = useAuthQuery({
    keys: KEYS.codes,
    endpoint: `/${ENTITIES.dar}code`,
    queryOptions: {
      enabled,
    },
  })

  return darCodesState
}

const useDarTypes = ({enabled = false}) => {
  const darTypesState = useAuthQuery({
    keys: KEYS.types,
    endpoint: `/${ENTITIES.dar}type`,
    queryOptions: {
      enabled,
    },
  })

  return darTypesState
}

export {useDarCodes, useDarTypes}
