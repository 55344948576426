import {Box} from '@chakra-ui/react'
import * as React from 'react'
import {Navigate, Outlet, useLocation} from 'react-router-dom'
import Header from '../Header'
import Breaks from '../OfficerBreaks'
import Navbar from '../Navbar'
import OfficerBriefing from '../OfficerBriefing'
import Loader from '../Loader'
import OfficerEOS from '../OfficerEOS'
import useAppInit from '../../hooks/useAppInit'
import {useAuth} from '../../context/auth'
import {useUnreadBriefings} from '../../hooks/briefings'
import {useUserProperties} from '../../context/property'

const Authenticated = () => {
  const {user} = useAuth()
  const location = useLocation()
  const isLoadingAppData = useAppInit()
  const {currentProperty} = useUserProperties()
  const fetchUnreadBriefs = React.useMemo(
    () =>
      Boolean(
        user &&
          (user?.isOfficer || user?.isPostCommander) &&
          user?.shifts?.officerScheduleId
      ),
    [user]
  )

  const {data: briefingData, isLoading: isLoadingBriefing} = useUnreadBriefings(
    {enabled: fetchUnreadBriefs}
  )

  if (!user) {
    return <Navigate to="auth/login" replace />
  }

  if (
    (user.isOfficer || user.isPostCommander) &&
    !user.shifts &&
    !user.isOneTimeLogin &&
    location.pathname !== '/officer-shift'
  ) {
    return <Navigate to="officer-shift" replace />
  }

  if (isLoadingBriefing || isLoadingAppData) {
    return <Loader />
  }

  const isOfficer =
    (user.isOfficer || user.isPostCommander) && user.shifts?.officerScheduleId

  return (
    <Box
      display={{
        base: 'flex',
        lg: 'grid',
      }}
      flexDirection={{
        base: 'column',
        lg: 'revert',
      }}
      as="main"
      h="100%"
      gridTemplateColumns="300px auto"
      gridTemplateRows="80px auto"
      gridTemplateAreas={`
          'nav header'
          'nav content'
        `}
    >
      <Header />
      <Navbar />
      <Box p="32px" overflowX="hidden" overflowY="auto" gridArea="content">
        {isOfficer && currentProperty?.propertyId ? (
          <>
            <Breaks />
            <OfficerEOS />
            <OfficerBriefing briefingData={briefingData} />
          </>
        ) : null}
        <Outlet />
      </Box>
    </Box>
  )
}

export default Authenticated
