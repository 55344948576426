import {Flex, Text, useToast} from '@chakra-ui/react'
import * as React from 'react'
import {useAuth} from '../../../context/auth'
import Paginator from '../../Paginator'
import ShiftsDesktopTable from './ShiftsDesktopTable'
import ShiftsMobileTable from './ShiftsMobileTable'
import {getShiftsWithActions} from '../Shifts.helpers'
import {usePatch} from '../../../hooks/entities'
import {isObjectEmpty} from '../../../lib/objects'
import {ENTITIES, ENTITIES_KEYS} from '../../../lib/entities'

const ShiftsTable = ({
  items,
  sort,
  itemsTotal,
  pages,
  pagesCount,
  currentPage,
  filters,
  onSort,
  onPageChange,
}) => {
  const {user} = useAuth()
  const {mutate, isLoading, isError} = usePatch({
    entity: ENTITIES.shifts,
    currentPage,
    filters,
    sort,
  })
  const toast = useToast({
    position: 'top',
    isClosable: true,
  })

  const handleUpdate = React.useCallback(
    shiftData => {
      mutate(
        {
          ...shiftData,
        },
        {
          onSuccess() {
            toast({
              title: 'Shift status updated!',
              status: 'success',
            })
          },
          onError() {
            toast({
              title: "Error updating the shift's status",
              status: 'error',
            })
          },
        }
      )
    },
    [mutate, toast]
  )

  // admin actions
  const handleApprove = shiftId => {
    const statusData = {
      schedule_status: 1,
      [ENTITIES_KEYS.shifts]: shiftId,
    }
    handleUpdate(statusData)
  }
  const handleReject = shiftId => {
    const statusData = {
      schedule_status: 3,
      [ENTITIES_KEYS.shifts]: shiftId,
    }
    handleUpdate(statusData)
  }

  // officer actions
  const handleConfirm = shiftId => {
    const statusData = {
      user_status: 3,
      [ENTITIES_KEYS.shifts]: shiftId,
    }
    handleUpdate(statusData)
  }
  const handleDecline = shiftId => {
    const statusData = {
      user_status: 4,
      [ENTITIES_KEYS.shifts]: shiftId,
    }
    handleUpdate(statusData)
  }

  const shifts = getShiftsWithActions(items, user)

  React.useEffect(() => {
    if (user.isOfficer) {
      const unreadsIds = items
        .filter(i => i.userStatus === 'Unread')
        .map(i => i.officerScheduleId)

      if (unreadsIds.length && !isLoading && !isError) {
        const key = `${ENTITIES_KEYS.shifts}s`
        const statusData = {
          user_status: 2,
          [key]: unreadsIds,
        }
        handleUpdate(statusData)
      }
    }
  }, [handleUpdate, items, user, isLoading, isError])

  if (!shifts?.length && isObjectEmpty(filters)) {
    return (
      <Flex justify="center" boxShadow="lg" p={4}>
        <Text>No data found for this section</Text>
      </Flex>
    )
  }

  if (!shifts?.length && !isObjectEmpty(filters)) {
    return (
      <Flex justify="center" boxShadow="lg" p={4}>
        <Text>No data found for the filters applied</Text>
      </Flex>
    )
  }

  return (
    <>
      <ShiftsMobileTable
        shifts={shifts}
        onConfirm={handleConfirm}
        onReject={handleReject}
        onApprove={handleApprove}
        onDecline={handleDecline}
      />
      <ShiftsDesktopTable
        shifts={shifts}
        sort={sort}
        onSort={onSort}
        onConfirm={handleConfirm}
        onReject={handleReject}
        onApprove={handleApprove}
        onDecline={handleDecline}
      />

      <Paginator
        itemsTotal={itemsTotal}
        pages={pages}
        pagesCount={pagesCount}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
    </>
  )
}

export default ShiftsTable
