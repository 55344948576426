const APP_KEYS = {
  currentProperty: 'starproaccess:current_property',
  userProperties: 'starproaccess:user_properties',
}

const getStorageItem = key => {
  const data = localStorage.getItem(key)
  return data ? JSON.parse(data) : null
}

const setStorageItem = (key, item) => {
  localStorage.setItem(key, JSON.stringify(item))
}

export {APP_KEYS, getStorageItem, setStorageItem}
