const getDefaultUserId = vehicle => {
  if (!vehicle) {
    return ''
  }
  return {
    value: vehicle.userId,
    label: vehicle.userName,
  }
}
const getDefaultValues = vehicle => ({
  userId: getDefaultUserId(vehicle),
  licensePlate: vehicle?.licensePlate || '',
  make: vehicle?.make || '',
  model: vehicle?.model || '',
  color: vehicle?.color || '',
  state: vehicle?.state || '',
})

export {getDefaultValues}
