import * as React from 'react'
import PositionError from '../components/PositionError/PositionError'

const PositionContext = React.createContext()
PositionContext.displayName = 'PositionContext'

const permissionStatus = {
  idle: 'idle',
  granted: 'granted',
  denied: 'denied',
}

const positionOptions = {
  enableHighAccuracy: true,
  timeout: 1000 * 10, // 10 seconds
  maximumAge: 0, // no cached position
}

const PositionProvider = ({children}) => {
  const [geoPermission, setGeoPermission] = React.useState(
    permissionStatus.idle
  )

  React.useEffect(() => {
    if (geoPermission === permissionStatus.idle) {
      const onSuccess = () => {
        setGeoPermission(permissionStatus.granted)
      }
      const onError = error => {
        setGeoPermission(permissionStatus.denied)
      }
      navigator.geolocation.getCurrentPosition(onSuccess, onError)
    }
  }, [geoPermission])

  const getCoordinates = React.useCallback(
    () =>
      new Promise((resolve, reject) => {
        if (!navigator.geolocation) {
          reject(new Error('Geolocation not available'))
        }

        navigator.geolocation.getCurrentPosition(
          ({coords: {latitude, longitude}}) => {
            resolve({latitude, longitude})
          },
          error => {
            if (error.code === error.PERMISSION_DENIED) {
              setGeoPermission(permissionStatus.denied)
            }
            reject(error)
          },
          positionOptions
        )
      }),
    []
  )

  const value = React.useMemo(() => ({getCoordinates}), [getCoordinates])

  if (geoPermission === permissionStatus.granted) {
    return (
      <PositionContext.Provider value={value}>
        {children}
      </PositionContext.Provider>
    )
  }
  if (geoPermission === permissionStatus.idle) {
    return null
  }

  return <PositionError />
}

const usePosition = () => {
  const context = React.useContext(PositionContext)
  if (context === undefined) {
    throw new Error('usePosition must be used within a PositionProvider')
  }
  return context
}

export {usePosition}
export default PositionProvider
