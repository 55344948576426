const mobileStyles = {
  h: {
    base: '100%',
    lg: 'revert',
  },
  w: {
    base: '100%',
    lg: 'revert',
  },
  position: {
    base: 'absolute',
    lg: 'revert',
  },
  top: {
    base: '0',
    lg: 'revert',
  },
  left: {
    base: '0',
    lg: 'revert',
  },
  zIndex: {
    base: 'var(--chakra-zIndices-modal)',
    lg: 'revert',
  },
}

export {mobileStyles}
