import * as React from 'react'
import {useQuery} from 'react-query'
import {useAuth} from './auth'
import {getPromise} from '../lib/fetcher'
import {APP_KEYS, getStorageItem, setStorageItem} from '../lib/localStorage'
import Loader from '../components/Loader'
import {getRuleValue, PROPERTY_QUERY_KEY} from '../lib/property'

const PropertyContext = React.createContext()
PropertyContext.displayName = 'PropertyContext'

const PropertyProvider = ({children}) => {
  const {user, clearUser} = useAuth()
  const [currentProperty, setCurrentProperty] = React.useState(() => {
    const propertyId = getStorageItem(APP_KEYS.currentProperty)
    if (!propertyId) {
      return null
    }

    const storeProps = getStorageItem(APP_KEYS.userProperties)
    return storeProps.find(p => p.propertyId === propertyId)
  })
  const [properties, setProperties] = React.useState(
    () => getStorageItem(APP_KEYS.userProperties) || []
  )
  const {
    data: {properties: _properties} = {},
    isLoading,
    isError,
    error,
  } = useQuery(PROPERTY_QUERY_KEY, () => getPromise('/user/properties', user), {
    retry: false,
    enabled: Boolean(
      user &&
        ((!user.isOfficer && !user.isPostCommander) ||
          ((user.isOfficer || user.isPostCommander) &&
            Boolean(user.shifts?.officerScheduleId)))
    ),
  })

  const handlePropertyChange = React.useCallback(
    propertyId => {
      const property = _properties.find(
        p => p.propertyId === Number(propertyId)
      )

      setStorageItem(APP_KEYS.currentProperty, Number(propertyId))
      setCurrentProperty(property)
    },
    [_properties]
  )

  React.useEffect(() => {
    if (_properties) {
      const propertyId = getStorageItem(APP_KEYS.currentProperty)
      let property = propertyId
        ? _properties.find(p => p.propertyId === propertyId)
        : _properties[0]

      property = property || _properties[0]

      setStorageItem(APP_KEYS.userProperties, _properties)
      setStorageItem(APP_KEYS.currentProperty, Number(property.propertyId))
      setProperties(_properties)
      setCurrentProperty(property)
    }
  }, [_properties])

  React.useEffect(() => {
    if (
      isError &&
      error.status === 401 &&
      user &&
      !user?.isOfficer &&
      !user?.isPostCommander
    ) {
      clearUser()
    }
  }, [isError, error, clearUser, user])

  const value = React.useMemo(() => {
    const isPermitsByHousehold =
      currentProperty &&
      getRuleValue(currentProperty.propertyRules, 'Permits by HouseHold')

    const isPermitsByPlate =
      currentProperty &&
      getRuleValue(currentProperty.propertyRules, 'Permits by License Plate')

    return {
      isPermitsByHousehold,
      isPermitsByPlate,
      properties,
      currentProperty,
      handlePropertyChange,
    }
  }, [currentProperty, handlePropertyChange, properties])

  if (
    isError &&
    error.status === 401 &&
    user &&
    !user.isOfficer &&
    !user.isPostCommander
  ) {
    return null
  }

  if ((user?.isResident && !currentProperty) || isLoading) {
    return <Loader />
  }

  return (
    <PropertyContext.Provider value={value}>
      {children}
    </PropertyContext.Provider>
  )
}

const useUserProperties = () => {
  const context = React.useContext(PropertyContext)
  if (context === undefined) {
    throw Error('useUserProperties must be used within a PropertyContext')
  }
  return context
}

export {useUserProperties}
export default PropertyProvider
