import {
  Flex,
  Icon,
  Text,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Skeleton,
} from '@chakra-ui/react'
import {MdClose} from 'react-icons/md'
import PropertiesFormStepper from './PropertiesFormStepper'
import {usePropertyTypes, useTowCompanies} from '../../../hooks/properties'
import {useItem} from '../../../hooks/entities'
import {ENTITIES, ENTITIES_KEYS} from '../../../lib/entities'

const PropertiesFormModal = function ({
  propertyId,
  isOpen,
  isSubmiting,
  onClose,
  onSubmit,
}) {
  const {data: typesData, isLoading: isLoadingTypes} = usePropertyTypes({
    enabled: isOpen,
  })
  const {data: towCompaniesData, isLoading: isLoadingTowCompanies} =
    useTowCompanies({
      enabled: isOpen,
    })
  const {
    data: propertyData,
    isLoading: isLoadingProperty,
    isFetching,
    isRefetching,
  } = useItem({
    id: propertyId,
    entity: ENTITIES.properties,
    entityKey: ENTITIES_KEYS.properties,
  })

  const isLoading =
    isLoadingTypes ||
    isLoadingTowCompanies ||
    isLoadingProperty ||
    isFetching ||
    isRefetching

  const types = typesData
    ? typesData.propertyTypes.map(t => ({
        value: t.propertyTypeId,
        label: t.type,
        sections: t.sections,
        rules: t.rules,
      }))
    : []

  const towCompanies = towCompaniesData
    ? towCompaniesData.towCompanies.map(t => ({
        value: t.towCompanyId,
        label: t.name,
      }))
    : []

  const property = propertyData?.data

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      size={{
        base: 'full',
        lg: 'lg',
      }}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader borderBottom="1px solid" borderColor="gray.400">
          <Flex align="center" gap={2}>
            <Button p="0" variant="ghost" onClick={onClose}>
              <Icon as={MdClose} w="24px" h="24px" />
            </Button>
            <Text>{propertyId ? 'Update' : 'Create'} property</Text>
            {/* <Button
              ml="auto"
              type="submit"
              form="dar-form"
              variant="solid"
              colorScheme="blue"
              disabled={isLoading}
              isLoading={isSubmiting}
            >
              {propertyId ? 'Update' : 'Create'}
            </Button> */}
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          {isLoading ? (
            <Skeleton h="200px" />
          ) : (
            <PropertiesFormStepper
              property={property}
              types={types}
              towCompanies={towCompanies}
              onSubmit={onSubmit}
              isSubmiting={isSubmiting}
            />
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default PropertiesFormModal
