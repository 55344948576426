import {
  Box,
  Flex,
  Text,
  Button,
  Icon,
  List,
  ListItem,
  useBreakpointValue,
} from '@chakra-ui/react'
import {MdOutlineCheck, MdOutlineClose} from 'react-icons/md'
import {HiChevronDown, HiChevronUp} from 'react-icons/hi'
import {getShiftProperties} from '../Shifts.helpers'
import {formatDate} from '../../../lib/formatters'

const ShiftsDesktopTable = ({
  shifts,
  sort,
  onSort,
  onConfirm,
  onReject,
  onApprove,
  onDecline,
}) => {
  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  })

  if (isMobile) {
    return null
  }

  const SortIcon =
    sort?.dir === 'desc' ? (
      <Icon as={HiChevronDown} />
    ) : (
      <Icon as={HiChevronUp} />
    )

  return (
    <List boxShadow="lg" fontSize="xs">
      <ListItem borderBottom="1px solid" borderColor="gray.300">
        <Flex>
          <Flex
            pl={4}
            pr={0}
            py={2}
            flex="1"
            flexBasis="90%"
            fontWeight="bold"
            align="center"
          >
            <Text
              flexBasis="20%"
              color="gray.800"
              fontSize="sm"
              fontWeight="semibold"
            >
              Property
            </Text>
            <Button
              flexBasis="20%"
              variant="link"
              justifyContent="flex-start"
              rightIcon={sort?.field === 'officer_id' ? SortIcon : null}
              onClick={() => onSort && onSort('officer_id')}
            >
              <Text color="gray.800" fontSize="sm">
                Officer
              </Text>
            </Button>
            <Button
              flexBasis="20%"
              variant="link"
              justifyContent="flex-start"
              rightIcon={sort?.field === 'start_time' ? SortIcon : null}
              onClick={() => onSort && onSort('start_time')}
            >
              <Text color="gray.800" fontSize="sm">
                Shift date
              </Text>
            </Button>
            <Button
              flexBasis="20%"
              variant="link"
              justifyContent="flex-start"
              rightIcon={sort?.field === 'schedule_status' ? SortIcon : null}
              onClick={() => onSort && onSort('schedule_status')}
            >
              <Text color="gray.800" fontSize="sm">
                Status
              </Text>
            </Button>
            <Text
              flexBasis="20%"
              color="gray.800"
              fontSize="sm"
              fontWeight="semibold"
            >
              Worked hs.
            </Text>
          </Flex>
          <Box flexBasis="15%" py={2} pr={4} />
        </Flex>
      </ListItem>
      {shifts.map(shift => (
        <ListItem
          key={shift.officerScheduleId}
          transitionProperty="var(--chakra-transition-property-common)"
          transitionDuration="var(--chakra-transition-duration-normal)"
          _even={{
            bg: 'gray.100',
          }}
          _hover={{
            bg: 'blue.50',
          }}
        >
          <Flex>
            <Flex
              w="100%"
              h="100%"
              minH="60px"
              pl={4}
              pr={0}
              py={2}
              flex="1"
              flexBasis="90%"
              fontSize="xs"
              color="gray.700"
            >
              <Flex align="center" w="100%">
                <Text flexBasis="20%" whiteSpace="pre-wrap">
                  {getShiftProperties(shift)}
                </Text>
                <Text flexBasis="20%" whiteSpace="pre-wrap">
                  {shift.officerName}
                </Text>
                <Text flexBasis="20%" whiteSpace="pre-wrap">
                  {formatDate(shift.startTime)}
                </Text>
                <Box flexBasis="20%" whiteSpace="pre-wrap">
                  <Text>Manager: {shift.scheduleStatus}</Text>
                  <Text>Officer: {shift.userStatus}</Text>
                </Box>
                <Box flexBasis="20%" whiteSpace="pre-wrap">
                  <Text>Estimated: {shift.timeExpectedToBeWork}</Text>
                  <Text>Worked: {shift.timeWorked || '-'}</Text>
                </Box>
              </Flex>
            </Flex>
            <Flex justify="center" align="center" flexBasis="15%" py={2} pr={4}>
              {/* officer actions */}
              {shift.canConfirm ? (
                <Button
                  variant="ghost"
                  colorScheme="green"
                  title="Confirm shift"
                  onClick={() => onConfirm(shift.officerScheduleId)}
                >
                  <Icon as={MdOutlineCheck} w={6} h={6} />
                </Button>
              ) : null}
              {shift.canDecline ? (
                <Button
                  variant="ghost"
                  colorScheme="red"
                  title="Reject shift"
                  onClick={() => onDecline(shift.officerScheduleId)}
                >
                  <Icon as={MdOutlineClose} w={6} h={6} />
                </Button>
              ) : null}
              {/* admin actions */}
              {shift.canApprove ? (
                <Button
                  variant="ghost"
                  colorScheme="green"
                  title="Approve shift"
                  onClick={() => onApprove(shift.officerScheduleId)}
                >
                  <Icon as={MdOutlineCheck} w={6} h={6} />
                </Button>
              ) : null}
              {shift.canReject ? (
                <Button
                  variant="ghost"
                  colorScheme="red"
                  title="Decline shift"
                  onClick={() => onReject(shift.officerScheduleId)}
                >
                  <Icon as={MdOutlineClose} w={6} h={6} />
                </Button>
              ) : null}
            </Flex>
          </Flex>
        </ListItem>
      ))}
    </List>
  )
}

export default ShiftsDesktopTable
