import {
  Box,
  Flex,
  Text,
  Button,
  Icon,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  useBreakpointValue,
} from '@chakra-ui/react'
import {MdModeEdit, MdDelete} from 'react-icons/md'

const VehiclesMobileTable = ({vehicles, onEdit, onDelete}) => {
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  })

  if (isDesktop) {
    return null
  }

  return (
    <Box
      boxShadow="lg"
      display={{
        lg: 'none',
      }}
    >
      <Flex fontSize="sm" px={4} py={2}>
        <Flex gap={2} flex="1">
          <Text fontWeight="bold" flexBasis="50%">
            License plate
          </Text>
          <Text fontWeight="bold" flexBasis="50%">
            Resident
          </Text>
          <Box boxSize="1rem" />
        </Flex>
      </Flex>
      <Accordion allowToggle>
        {vehicles.map(vehicle => (
          <AccordionItem key={vehicle.vehicleId}>
            <AccordionButton fontSize="sm">
              <Flex flex="1" textAlign="start" gap={2} align="center">
                <Text flexBasis="50%">{vehicle.licensePlate}</Text>
                <Box flexBasis="50%">{vehicle.userName || 'Unknown'}</Box>
              </Flex>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel
              fontSize="sm"
              position="relative"
              overflow="visible"
            >
              <Flex align="center">
                <Flex direction="column" gap={2} mb={2} flex="1">
                  <Box>
                    <Text fontWeight="bold" as="span" mr={2}>
                      Make:
                    </Text>
                    {vehicle.make}
                  </Box>
                  <Box>
                    <Text fontWeight="bold" as="span" mr={2}>
                      Model:
                    </Text>
                    {vehicle.model}
                  </Box>
                  <Box>
                    <Text fontWeight="bold" as="span" mr={2}>
                      Color:
                    </Text>
                    {vehicle.color}
                  </Box>
                  <Box>
                    <Text fontWeight="bold" as="span" mr={2}>
                      State:
                    </Text>
                    {vehicle.state}
                  </Box>
                </Flex>

                <Flex direction="column" justify="end" gap={2} align="center">
                  {vehicle.canEdit ? (
                    <Button
                      size="sm"
                      variant="ghost"
                      onClick={() => onEdit(vehicle.vehicleId)}
                    >
                      <Icon as={MdModeEdit} w={6} h={6} />
                    </Button>
                  ) : null}
                  {vehicle.canDelete ? (
                    <Button
                      size="sm"
                      variant="ghost"
                      colorScheme="red"
                      onClick={() => onDelete(vehicle.vehicleId)}
                    >
                      <Icon as={MdDelete} w={6} h={6} />
                    </Button>
                  ) : null}
                </Flex>
              </Flex>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  )
}

export default VehiclesMobileTable
