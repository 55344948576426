import * as React from 'react'

const useSort = sortField => {
  const [sort, setSort] = React.useState({field: sortField, dir: 'desc'})

  const handleSort = (field, dir) => {
    setSort(currentSort => {
      if (field && dir) {
        return {
          field,
          dir,
        }
      }

      return currentSort.field === field
        ? {...currentSort, dir: currentSort.dir === 'asc' ? 'desc' : 'asc'}
        : {field, dir: 'desc'}
    })
  }

  return [sort, handleSort]
}

export default useSort
