import {
  Box,
  Button,
  Text,
  Icon,
  Progress,
  useDisclosure,
  useToast,
  useBreakpointValue,
} from '@chakra-ui/react'
import {MdAdd} from 'react-icons/md'
import {useAuth} from '../../context/auth'
import {useOfflineStore} from '../../context/offlineStore'
import {usePost} from '../../hooks/entities'
import Acl from '../Acl'

const CreateEntityButton = ({
  entity,
  entityName,
  permissionCreate,
  currentPage,
  filters,
  sort,
  formModal: FormModal,
}) => {
  const {user} = useAuth()
  const {addToStore} = useOfflineStore()
  const {isOpen, onOpen, onClose} = useDisclosure()
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  })
  const {mutate, reset, isLoading} = usePost({
    entity,
    currentPage,
    filters,
    sort,
  })
  const toast = useToast({
    position: 'top',
    isClosable: true,
  })

  const handlePost = entityData => {
    if (!navigator.onLine && user.isOfficer) {
      addToStore(entity, entityData)
      toast({
        title: `The ${entityName} will be created once the connection is recovered`,
        status: 'info',
      })
      reset()
      onClose()
      return
    }

    mutate(entityData, {
      onSuccess() {
        toast({
          title: `${entityName} created!`,
          status: 'success',
        })
        reset()
        onClose()
      },
      onError() {
        toast({
          title: `Error creating ${entityName}`,
          status: 'error',
        })
      },
    })
  }

  return (
    <Acl requiredPermissions={permissionCreate}>
      {isLoading ? (
        <Box position="absolute" top="0" left="0" w="100%">
          <Progress isIndeterminate size="xs" />
        </Box>
      ) : null}
      <Button variant="outline" colorScheme="blue" onClick={onOpen}>
        {isDesktop ? (
          <Text fontSize="sm">New {entityName}</Text>
        ) : (
          <Icon as={MdAdd} />
        )}
      </Button>
      <FormModal
        isOpen={isOpen}
        isSubmiting={isLoading}
        onClose={onClose}
        onSubmit={handlePost}
      />
    </Acl>
  )
}

export default CreateEntityButton
