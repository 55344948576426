import {
  Box,
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react'
import {BsChevronDown} from 'react-icons/bs'
import {useAuth} from '../../context/auth'
import useEndOfShift from '../../hooks/useEndOfShift'

const UserMenu = () => {
  const {user, handleLogout} = useAuth()
  const handleEndOfShift = useEndOfShift()

  return (
    <Box
      ml="auto"
      display={{
        base: 'none',
        lg: 'revert',
      }}
    >
      <Menu>
        <MenuButton
          as={Button}
          variant="outline"
          rightIcon={<Icon as={BsChevronDown} />}
        >
          {user.firstName} {user.lastName}
        </MenuButton>
        <MenuList>
          {user.isOfficer && !user.isOneTimeLogin ? (
            <MenuItem onClick={handleEndOfShift}>End of shift</MenuItem>
          ) : null}
          <MenuItem color="red.500" onClick={handleLogout}>
            Logout
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  )
}

export default UserMenu
