import {
  Box,
  Flex,
  Text,
  Button,
  Icon,
  List,
  ListItem,
  useBreakpointValue,
} from '@chakra-ui/react'
import {MdModeEdit} from 'react-icons/md'
import {
  getItemDetail,
  getItemType,
  STORE_STATUS,
} from '../../../lib/offlineStore'

const OfflineDesktopTable = ({
  isLoading,
  items,
  vehiclesData,
  darData,
  violationTypesData,
  incidentTypesData,
  onEdit,
}) => {
  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  })

  if (isMobile) {
    return null
  }

  return (
    <List boxShadow="lg" fontSize="xs">
      <ListItem borderBottom="1px solid" borderColor="gray.300">
        <Flex>
          <Flex pl={4} pr={0} py={2} flex="1" fontWeight="bold" align="center">
            <Text
              flexBasis="15%"
              color="gray.800"
              fontSize="sm"
              fontWeight="semibold"
            >
              Type
            </Text>
            <Text
              flexBasis="30%"
              color="gray.800"
              fontSize="sm"
              fontWeight="semibold"
            >
              Details
            </Text>
            <Text
              flexBasis="20%"
              color="gray.800"
              fontSize="sm"
              fontWeight="semibold"
            >
              Status
            </Text>
            <Text
              flexBasis="20%"
              color="gray.800"
              fontSize="sm"
              fontWeight="semibold"
            >
              Error
            </Text>
          </Flex>
          <Box flexBasis="15%" py={2} pr={4} />
        </Flex>
      </ListItem>
      {items.map(item => (
        <ListItem
          key={item.tempId}
          transitionProperty="var(--chakra-transition-property-common)"
          transitionDuration="var(--chakra-transition-duration-normal)"
          _even={{
            bg: 'gray.100',
          }}
          _hover={{
            bg: 'blue.50',
          }}
        >
          <Flex>
            <Flex
              w="100%"
              h="100%"
              minH="60px"
              pl={4}
              pr={0}
              py={2}
              flex="1"
              flexBasis="90%"
              fontSize="xs"
              color="gray.700"
            >
              <Flex align="center" w="100%">
                <Text flexBasis="15%" whiteSpace="pre-wrap">
                  {getItemType(item.type)}
                </Text>
                <Text flexBasis="30%">
                  {getItemDetail(
                    item,
                    vehiclesData,
                    darData,
                    violationTypesData,
                    incidentTypesData
                  )}
                </Text>
                <Text flexBasis="20%" whiteSpace="break-spaces">
                  {item.status}
                </Text>
                <Text flexBasis="20%" whiteSpace="pre-wrap">
                  {item.error || '-'}
                </Text>
              </Flex>
            </Flex>
            <Flex justify="center" align="center" flexBasis="15%" py={2} pr={4}>
              {item.status === STORE_STATUS.pending ? (
                <Button
                  variant="ghost"
                  isLoading={isLoading}
                  onClick={() => onEdit(item)}
                >
                  <Icon as={MdModeEdit} w={6} h={6} />
                </Button>
              ) : null}
            </Flex>
          </Flex>
        </ListItem>
      ))}
    </List>
  )
}

export default OfflineDesktopTable
