import * as React from 'react'
import {toast} from 'react-toastify'
import {Box, Button, Flex, Image, Icon} from '@chakra-ui/react'
import {MdAdd, MdClose} from 'react-icons/md'
import {COLORS} from '../../constants'
import {getBase64Files} from '../../lib/form'

const MAX_FILES = 2

const FilesInput = ({
  files = [],
  onChange,
  validFileExtensionsReg,
  extensionErrorMessage,
}) => {
  const inputRef = React.useRef()

  const handleClick = () => {
    inputRef.current.click()
  }

  const handleChange = async e => {
    if (!e.target.files.length) return

    const amountFilesSelected = (files?.length || 0) + e.target.files.length
    if (amountFilesSelected > MAX_FILES) {
      toast.error(`You can only attach ${MAX_FILES} files`)
      return
    }

    if (validFileExtensionsReg) {
      const keys = Object.keys(e.target.files)
      const arrValidFiles = keys.every(k =>
        validFileExtensionsReg.test(e.target.files[k].name)
      )
      if (!arrValidFiles) {
        toast.error(extensionErrorMessage)
        return
      }
    }

    const base64Files = await getBase64Files(e.target.files)
    onChange([...files, ...base64Files])
  }

  const handleDelete = ({currentTarget: {dataset}}) => {
    const filteredFiles = files.filter(
      (_, index) => index !== Number(dataset.index)
    )
    onChange(filteredFiles)
  }

  const isDisabled = files?.length >= MAX_FILES

  return (
    <Flex gap={8} mb={8}>
      <input
        type="file"
        accept="image/*;capture=camera"
        multiple
        hidden
        ref={inputRef}
        onChange={handleChange}
      />
      {files?.length
        ? files.map((file, index) => (
            <Box position="relative" key={`attached-${index}`}>
              <Button
                variant="solid"
                position="absolute"
                colorScheme="black"
                bg="gray.800"
                padding="0"
                top="-12px"
                right="-12px"
                w="24px"
                h="24px"
                minW="auto"
                borderRadius="50%"
                border="none"
                display="flex"
                justifyContent="center"
                alignItems="center"
                type="button"
                data-index={index}
                onClick={handleDelete}
              >
                <Icon
                  as={MdClose}
                  width="16px"
                  height="16px"
                  fill={COLORS.white}
                />
              </Button>
              <Image
                w="100px"
                h="100px"
                lineHeight="1"
                objectFit="cover"
                borderRadius={2}
                src={file}
                alt=""
              />
            </Box>
          ))
        : null}
      <Button
        w="100px"
        h="100px"
        p="20px"
        borderRadius={2}
        border="1px dashed"
        borderColor="blue.300"
        bg="white"
        opacity="0.65"
        type="button"
        title="Add file"
        onClick={handleClick}
        disabled={isDisabled}
        sx={{
          ':not(:disabled):hover': {
            opacity: 1,
          },
          ':disabled,:disabled *': {
            borderColor: 'gray.500',
            cursor: 'unset',
          },
          ':hover, :disabled:hover': {
            background: 'none',
          },
        }}
      >
        <Flex
          h="100%"
          w="100%"
          align="center"
          justify="center"
          borderRadius={2}
          border="1px dashed"
          borderColor="blue.300"
        >
          <Icon
            as={MdAdd}
            w="24px"
            h="24px"
            fill={isDisabled ? 'gray.500' : 'hsl(211deg, 100%, 68%)'}
          />
        </Flex>
      </Button>
    </Flex>
  )
}

export default FilesInput
