const getDefaultValues = household => ({
  name: household?.name || '',
  number: household?.number || '',
  street: household?.street || '',
  firstName: household?.residentFirstName || '',
  lastName: household?.residentLastName || '',
  email: household?.residentEmail || '',
})

export {getDefaultValues}
