import {extendTheme} from '@chakra-ui/react'
import {StepsStyleConfig as Steps} from 'chakra-ui-steps'

const chakraTheme = extendTheme({
  components: {
    Steps,
  },
  colors: {
    gray: {
      100: '#F5f5f5',
      200: '#EEEEEE',
      300: '#E0E0E0',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
    },
    blue: {
      50: '#ECF2F9',
      100: '#CADAED',
      200: '#A7C1E2',
      300: '#85A9D6',
      400: '#6291CA',
      500: '#4079BF',
      600: '#336199',
      700: '#264973',
      800: '#1A304C',
      900: '#0D1826',
    },
  },
  styles: {
    global: {
      'html, body, #root': {
        h: '100%',
      },
      '&::-webkit-scrollbar': {
        w: '2',
        h: '2',
      },
      '&::-webkit-scrollbar-track': {
        w: '6',
        h: '6',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '10',
        bg: `gray.400`,
      },
    },
  },
})

export {chakraTheme}
