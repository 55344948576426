import {
  Flex,
  Text,
  Skeleton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react'
import {useItem} from '../../hooks/entities'
import {ENTITIES, ENTITIES_KEYS} from '../../lib/entities'
import {formatDate} from '../../lib/formatters'
import ImagesGallery from '../ImagesGallery'

const IncidentsDetails = ({incidentId, isOpen, onClose}) => {
  const {data, isLoading} = useItem({
    entity: ENTITIES.incidents,
    entityKey: ENTITIES_KEYS.incidents,
    id: incidentId,
  })

  const {data: incident} = data ?? {}

  return (
    <Modal isOpen={isOpen} isCentered size="2xl" onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Details</ModalHeader>
        <ModalCloseButton />
        {isLoading ? (
          <Skeleton w="100%" h="100px" />
        ) : (
          <ModalBody p={6}>
            <Flex mb={2}>
              <Text fontWeight="bold" flexBasis="30%">
                Date
              </Text>
              <Text>{formatDate(incident.darDate)}</Text>
            </Flex>
            <Flex mb={2}>
              <Text fontWeight="bold" flexBasis="30%">
                Type
              </Text>
              <Text>{incident.incidentReportType}</Text>
            </Flex>
            <Flex mb={2}>
              <Text fontWeight="bold" flexBasis="30%">
                Officer
              </Text>
              <Text>{incident.officerName}</Text>
            </Flex>
            <Flex mb={2}>
              <Text fontWeight="bold" flexBasis="30%">
                Details
              </Text>
              <Text>{incident.details}</Text>
            </Flex>
            <ImagesGallery images={incident?.thumbImages} />
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  )
}

export default IncidentsDetails
