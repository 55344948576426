import {
  Flex,
  Box,
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useBreakpointValue,
} from '@chakra-ui/react'
import {FaSortAmountDown, FaCheck} from 'react-icons/fa'

const MobileSort = ({sort, sortFields, onSort}) => {
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  })

  if (isDesktop) {
    return null
  }

  const handleSort = (field, dir) => {
    onSort(field, dir)
  }

  return (
    <Menu>
      <MenuButton
        as={Button}
        variant="ghost"
        colorScheme="blue"
        title="Open sort options"
      >
        <Icon as={FaSortAmountDown} />
      </MenuButton>
      <MenuList>
        {sortFields.map(field => (
          <MenuItem
            key={`${field.key}-${field.dir}`}
            onClick={() => handleSort(field.key, field.dir)}
          >
            <Flex gap={2} align="center">
              <Box boxSize={4}>
                {sort.field === field.key && sort.dir === field.dir ? (
                  <Icon as={FaCheck} w={4} h={4} />
                ) : null}
              </Box>
              {field.label}
            </Flex>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}

export default MobileSort
