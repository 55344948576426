const getDefaultType = (incident, types) => {
  if (!incident) {
    return ''
  }

  return types.find(t => t.value === incident.incidentReportTypeId)
}

const getDefaultCode = (incident, codes) => {
  if (!incident) {
    return ''
  }

  return codes.find(c => c.value === incident.dailyActivityReportCodeId)
}

const getDefaultValues = ({incident, types, codes}) => {
  const incidentReportTypeId = getDefaultType(incident, types)
  const dailyActivityReportCodeId = getDefaultCode(incident, codes)

  return {
    incidentReportTypeId,
    dailyActivityReportCodeId,
    details: incident?.details,
  }
}

export {getDefaultValues}
