import {getEntitiesWithActions} from '../../lib/entities'

const propertiesPermissionsList = ['property list', 'property show']
const propertiesPermissionsCreate = ['property create', 'property store']
const propertiesPermissionsEdit = ['property update']
const propertiesPermissionsDelete = ['property delete']

const sortFields = [
  {
    key: 'name',
    dir: 'desc',
    label: 'By name descending',
  },
  {
    key: 'name',
    dir: 'asc',
    label: 'By name ascending',
  },
  {
    key: 'address',
    dir: 'desc',
    label: 'By address descending',
  },
  {
    key: 'address',
    dir: 'asc',
    label: 'By address ascending',
  },
]

const getPropertiesWithActions = (properties, user) =>
  getEntitiesWithActions(
    properties,
    user,
    propertiesPermissionsEdit,
    propertiesPermissionsDelete
  )

export {
  propertiesPermissionsList,
  propertiesPermissionsCreate,
  propertiesPermissionsEdit,
  propertiesPermissionsDelete,
  sortFields,
  getPropertiesWithActions,
}
