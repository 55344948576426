import * as React from 'react'
import {useAuthQuery} from './useAuthQuery'

const KEYS = {
  allHouseholds: ['households'],
  households: criteria => ['households', criteria],
}

const useAllHouseholds = ({enabled = false} = {}) => {
  const queryState = useAuthQuery({
    keys: KEYS.allHouseholds,
    endpoint: '/household/gethousebycriteria',
    queryOptions: {
      enabled,
    },
  })

  return queryState
}

const THROTTLE_MS = 400

const filterHouseholds = (input, households) =>
  households.filter(
    h =>
      h.street.toLowerCase().includes(input) ||
      String(h.number).toLowerCase().includes(input) ||
      h.firstName.toLowerCase().includes(input) ||
      h.lastName.toLowerCase().includes(input)
  )
const formatHouseholdOption = (key, households) =>
  households.map(h => ({
    value: h[key],
    label: `${h.firstName} ${h.lastName} - ${h.number} ${h.street}`,
  }))

const useHouseholdsByCriteria = key => {
  const [criteria, setCriteria] = React.useState()
  const timeoutRef = React.useRef()
  const callbackRef = React.useRef()
  const {
    data: {households: allHouseholds},
  } = useAllHouseholds()
  const [households, setHouseholds] = React.useState([])
  const {data, isSuccess, isFetching} = useAuthQuery({
    keys: KEYS.households(criteria),
    endpoint: `/household/gethousebycriteria?search_field=${criteria}`,
    queryOptions: {
      enabled: Boolean(criteria),
    },
  })

  const handleLoadOptions = React.useCallback(
    (input, callback) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef)
      }

      if (!input.trim() || input.length < 3) {
        return
      }

      if (!navigator.onLine) {
        const trimmed = input.trim().toLowerCase()
        const filteredHouseholds = filterHouseholds(trimmed, allHouseholds)
        const options = formatHouseholdOption(key, filterHouseholds)

        callback(options)
        setHouseholds(filteredHouseholds)
        return
      }

      callbackRef.current = callback

      setTimeout(() => {
        setCriteria(input)
      }, THROTTLE_MS)
    },
    [allHouseholds, key]
  )

  React.useEffect(() => {
    if (isSuccess && !isFetching) {
      const {households: _households} = data
      const options = formatHouseholdOption(key, _households)

      callbackRef.current(options)
      setHouseholds(_households)
    }
  }, [isSuccess, isFetching, data, key])

  return {households, handleLoadOptions}
}

export {useAllHouseholds, useHouseholdsByCriteria}
