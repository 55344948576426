const imagesRgx = /\.(jpe?g|png|bmp)$/i

const mailRgx =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

const validateMail = mail => {
  const result = mail.match(mailRgx)
  return Array.isArray(result)
}

const getControllerProps = (isDisabled, hasError, redColor) => {
  if (isDisabled) {
    return {
      border: 'gray.300',
    }
  }

  return hasError
    ? {
        border: redColor,
        shadow: `0 0 0 1px ${redColor}`,
      }
    : {
        border: 'inherit',
      }
}

const getErrorMessage = response =>
  new Promise(resolve => {
    if (response.message === 'Failed to fetch') {
      resolve(response.message)
      return
    }

    if (!response.body?.locked) {
      response.json().then(error => {
        const message = Array.isArray(error)
          ? error
              .map(e => {
                const keys = Object.keys(e)
                return keys.map(key => e[key]).join(' - ')
              })
              .join('\n')
          : error?.message
        resolve(message)
      })
    }
  })

const getFullDateToDb = (dateObj = new Date()) => {
  const utcDate = dateObj.toISOString().slice(0, 19).replace('T', ' ')
  return utcDate
}

const getDateToDb = dateObj => getFullDateToDb(dateObj).slice(0, 10)

const resizingFactor = 0.5
const quality = 0.5

const compressFile = file =>
  new Promise(resolve => {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')

    const imgToCompress = document.createElement('img')
    imgToCompress.src = file

    imgToCompress.addEventListener('load', () => {
      const originalWidth = imgToCompress.width
      const originalHeight = imgToCompress.height

      const canvasWidth = originalWidth * resizingFactor
      const canvasHeight = originalHeight * resizingFactor

      canvas.width = canvasWidth
      canvas.height = canvasHeight

      context.drawImage(
        imgToCompress,
        0,
        0,
        originalWidth * resizingFactor,
        originalHeight * resizingFactor
      )

      // reducing the quality of the image
      canvas.toBlob(
        blob => {
          if (blob) {
            setTimeout(() => {
              canvas.remove()
              imgToCompress.remove()
            }, 500)
            resolve(blob)
          }
        },
        'image/jpeg',
        quality
      )
    })
  })

const getBase64Files = files => {
  if (files.length === 0) {
    return Promise.resolve([])
  }

  return new Promise(resolve => {
    const base64Files = []
    let currentFile = 0
    const readFileData = file => {
      const reader = new FileReader()
      reader.onload = async () => {
        const blobReader = new FileReader()
        const compress = await compressFile(reader.result)

        blobReader.onload = () => {
          base64Files.push(reader.result)
          currentFile += 1

          if (currentFile === files.length) {
            resolve(base64Files)
          }
        }

        blobReader.readAsDataURL(compress)
      }

      reader.readAsDataURL(file)
    }

    for (const file of files) {
      readFileData(file)
    }
  })
}

export {
  imagesRgx,
  validateMail,
  getControllerProps,
  getErrorMessage,
  getDateToDb,
  getFullDateToDb,
  getBase64Files,
}
