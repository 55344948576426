import {
  Flex,
  Icon,
  Text,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Skeleton,
} from '@chakra-ui/react'
import {MdClose} from 'react-icons/md'
import UsersForm from './UsersForm'
import {useItem} from '../../hooks/entities'
import {useProperties} from '../../hooks/properties'
import {useRoles} from '../../hooks/users'
import {ENTITIES, ENTITIES_KEYS} from '../../lib/entities'

const UserFormModal = function ({
  userId,
  isOpen,
  isSubmiting,
  onClose,
  onSubmit,
}) {
  const {data: propertiesData, isLoading: isLoadingProperties} = useProperties({
    enabled: isOpen,
  })
  const {data: rolesData, isLoading: isLoadingRoles} = useRoles({
    enabled: isOpen,
  })
  const {
    data: userData,
    isLoading: isLoadingUser,
    isFetching,
    isRefetching,
  } = useItem({
    id: userId,
    entity: ENTITIES.users,
    entityKey: ENTITIES_KEYS.users,
  })

  const isLoading =
    isLoadingProperties ||
    isLoadingRoles ||
    isLoadingUser ||
    isFetching ||
    isRefetching

  const properties = propertiesData
    ? propertiesData?.properties.map(property => ({
        value: property.propertyId,
        label: property.name,
      }))
    : []

  const roles = rolesData
    ? rolesData?.roles.map(role => ({
        value: role.id,
        label: role.name,
        multipleProperties: role.multipleProperties,
      }))
    : []

  const user = userData?.data

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      size={{
        base: 'full',
        lg: 'lg',
      }}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader borderBottom="1px solid" borderColor="gray.400">
          <Flex align="center" gap={2}>
            <Button p="0" variant="ghost" onClick={onClose}>
              <Icon as={MdClose} w="24px" h="24px" />
            </Button>
            <Text>{userId ? 'Update' : 'Create'} user</Text>
            <Button
              ml="auto"
              type="submit"
              form="user-form"
              variant="solid"
              colorScheme="blue"
              disabled={isLoading}
              isLoading={isSubmiting}
            >
              {userId ? 'Update' : 'Create'}
            </Button>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          {isLoading ? (
            <Skeleton h="200px" />
          ) : (
            <UsersForm
              user={user}
              roles={roles}
              properties={properties}
              onSubmit={onSubmit}
              isSubmiting={isSubmiting}
            />
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default UserFormModal
