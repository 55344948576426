import {
  Flex,
  Icon,
  Text,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Skeleton,
} from '@chakra-ui/react'
import {MdClose} from 'react-icons/md'
import ViolationForm from './ViolationForm'
import {useItem} from '../../hooks/entities'
import {useDarCodes} from '../../hooks/dar'
import {useViolationTypes} from '../../hooks/violations'
import {ENTITIES, ENTITIES_KEYS} from '../../lib/entities'
import {useUserProperties} from '../../context/property'

const ViolationFormModal = function ({
  violationId,
  itemData,
  isOpen,
  isSubmiting,
  onClose,
  onSubmit,
}) {
  const {currentProperty} = useUserProperties()
  const {data: codesData, isLoading: isLoadingCodes} = useDarCodes({
    enabled: isOpen,
  })
  const {data: typesData, isLoading: isLoadingTypes} = useViolationTypes({
    enabled: isOpen,
  })
  const {
    data: violationData,
    isLoading: isLoadingViolation,
    isFetching,
    isRefetching,
  } = useItem({
    id: violationId,
    entity: ENTITIES.violations,
    entityKey: ENTITIES_KEYS.violations,
  })

  const isLoading =
    isLoadingCodes ||
    isLoadingTypes ||
    isLoadingViolation ||
    isFetching ||
    isRefetching

  const types = typesData
    ? typesData.violationTypes.map(({violationTypeId, type}) => ({
        value: violationTypeId,
        label: type,
      }))
    : []

  const codes = codesData
    ? codesData.dailyActivityReportCodes.map(
        ({dailyActivityReportCodeId, type}) => ({
          value: dailyActivityReportCodeId,
          label: type,
        })
      )
    : []

  const tows = currentProperty.towCompanies.map(({towCompanyId, name}) => ({
    value: towCompanyId,
    label: name,
  }))

  const violation = itemData || violationData?.data

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      size={{
        base: 'full',
        lg: 'lg',
      }}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader borderBottom="1px solid" borderColor="gray.400">
          <Flex align="center" gap={2}>
            <Button p="0" variant="ghost" onClick={onClose}>
              <Icon as={MdClose} w="24px" h="24px" />
            </Button>
            <Text>{violationId ? 'Update' : 'Create'} violation</Text>
            <Button
              ml="auto"
              type="submit"
              form="violation-form"
              variant="solid"
              colorScheme="blue"
              disabled={isLoading}
              isLoading={isSubmiting}
            >
              {violationId ? 'Update' : 'Create'}
            </Button>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          {isLoading ? (
            <Skeleton h="200px" />
          ) : (
            <ViolationForm
              violation={violation}
              types={types}
              codes={codes}
              tows={tows}
              onSubmit={onSubmit}
              isSubmiting={isSubmiting}
            />
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default ViolationFormModal
