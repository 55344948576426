import {Flex, Text} from '@chakra-ui/react'
import * as React from 'react'
import {HouseholdsTable, HouseholdsFilters} from '../components/Households'
import {PagePlaceholder} from '../components/Placeholder'
import MobileSort from '../components/MobileSort'
import Acl from '../components/Acl'
import CreateEntityButton from '../components/CreateEntityButton/CreateEntityButton'
import HouseholdsFormModal from '../components/Households/HouseholdsFormModal'
import useFilters from '../hooks/useFilters'
import useSort from '../hooks/useSort'
import usePagination from '../hooks/usePagination'
import {useList} from '../hooks/entities'
import {ENTITIES, ENTITIES_NAMES} from '../lib/entities'
import {
  sortFields,
  householdPermissionsList,
  householdPermissionsCreate,
} from '../components/Households/Households.helpers'

const Households = () => {
  const [itemsTotal, setItemsTotal] = React.useState()
  const {pages, pagesCount, currentPage, handlePageChange} =
    usePagination(itemsTotal)
  const [sort, handleSort] = useSort('name')
  const {filters, handleFilters, handleRemoveFilter} = useFilters()
  const {data, isLoading, isSuccess} = useList({
    entity: ENTITIES.households,
    currentPage,
    filters,
    sort,
  })

  if (isLoading) {
    return <PagePlaceholder />
  }

  if (isSuccess && itemsTotal !== data?.households.total) {
    setItemsTotal(data?.households.total)
  }

  return (
    <Flex gap={4} direction="column">
      <Flex align="center">
        <Text fontSize="xl" fontWeight="bold">
          Households
        </Text>
        <Flex
          gap={2}
          ml="auto"
          align="center"
          direction={{
            base: 'column',
            lg: 'row',
          }}
        >
          <Flex gap={2}>
            <HouseholdsFilters
              filters={filters}
              onFilter={handleFilters}
              onRemoveFilter={handleRemoveFilter}
            />
            <MobileSort
              sort={sort}
              sortFields={sortFields}
              onSort={handleSort}
            />
          </Flex>
          <CreateEntityButton
            entity={ENTITIES.households}
            entityName={ENTITIES_NAMES.households}
            permissionCreate={householdPermissionsCreate}
            sort={sort}
            filters={filters}
            currentPage={currentPage}
            formModal={HouseholdsFormModal}
          />
        </Flex>
      </Flex>
      <HouseholdsTable
        items={data?.households.data}
        sort={sort}
        filters={filters}
        pages={pages}
        itemsTotal={itemsTotal}
        pagesCount={pagesCount}
        currentPage={currentPage}
        onSort={handleSort}
        onPageChange={handlePageChange}
      />
    </Flex>
  )
}

const ProtectedHouseholds = () => (
  <Acl withRedirect requiredPermissions={householdPermissionsList}>
    <Households />
  </Acl>
)

export default ProtectedHouseholds
