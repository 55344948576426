import {Box, Flex, Text} from '@chakra-ui/react'
import {
  Pagination,
  PaginationPage,
  PaginationPageGroup,
  PaginationContainer,
  PaginationSeparator,
} from '@ajna/pagination'
import {ITEMS_PER_PAGE} from '../../lib/entities'

const Paginator = ({
  itemsTotal,
  pages,
  pagesCount,
  currentPage,
  onPageChange,
}) => {
  const from = ITEMS_PER_PAGE * currentPage - (ITEMS_PER_PAGE - 1)
  const to =
    itemsTotal < ITEMS_PER_PAGE ? itemsTotal : ITEMS_PER_PAGE * currentPage
  return (
    <Flex align="center">
      <Text fontSize="xs">
        Showing: {from} - {to} of {itemsTotal}
      </Text>
      <Box ml="auto">
        <Pagination
          pagesCount={pagesCount}
          currentPage={currentPage}
          onPageChange={onPageChange}
        >
          <PaginationContainer align="center" justify="flex-end">
            <PaginationPageGroup
              isInline
              align="center"
              separator={
                <PaginationSeparator fontSize="sm" w={7} jumpSize={2} />
              }
            >
              {pages.map(page => (
                <PaginationPage
                  w={7}
                  key={`pagination_page_${page}`}
                  page={page}
                  fontSize="sm"
                  _current={{
                    bg: 'blue.300',
                    color: 'white',
                    fontSize: 'sm',
                    w: 7,
                  }}
                />
              ))}
            </PaginationPageGroup>
          </PaginationContainer>
        </Pagination>
      </Box>
    </Flex>
  )
}

export default Paginator
