import {Flex, Text, Center, Spinner} from '@chakra-ui/react'

const Loader = () => (
  <Center w="100%" h="100%">
    <Flex direction="column" gap={4} align="center">
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
      <Text>Loading application data</Text>
    </Flex>
  </Center>
)

export default Loader
