import {Routes, Route, Navigate} from 'react-router-dom'
import {ErrorBoundary} from 'react-error-boundary'
import Unauthenticated from './pages/Unauthenticated'
import Properties from './pages/Properties'
import Dar from './pages/Dar'
import Users from './pages/Users'
import Login from './pages/Login'
import Forgot from './pages/Forgot'
import Reset from './pages/Reset'
import IncidentsReports from './pages/IncidentsReports'
import Households from './pages/Households'
import Vehicles from './pages/Vehicles'
import Permits from './pages/Permits'
import Violations from './pages/Violations'
import Pools from './pages/Pools'
import PoolReports from './pages/PoolReports'
import OfficerShift from './pages/OfficerShift'
import Schedules from './pages/Schedules'
import BriefingsBoard from './pages/BriefingsBoard'
import Shifts from './pages/Shifts'
import Auth from './pages/Auth'
import OfflineStore from './pages/OfflineStore'

import Authenticated from './components/Authenticated'

import ErrorFallback from './components/ErrorFallback'

const App = function () {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Routes>
        <Route path="/" element={<Authenticated />}>
          <Route index element={<Dar />} />
          <Route path="properties" element={<Properties />} />
          <Route path="households" element={<Households />} />
          <Route path="violations" element={<Violations />} />
          <Route path="permits" element={<Permits />} />
          <Route path="users" element={<Users />} />
          <Route path="pools" element={<Pools />} />
          <Route path="poolreport" element={<PoolReports />} />
          <Route path="incidents" element={<IncidentsReports />} />
          <Route path="offlinestore" element={<OfflineStore />} />
          <Route path="schedules" element={<Schedules />} />
          <Route path="briefing" element={<BriefingsBoard />} />
          <Route path="vehicles" element={<Vehicles />} />
          <Route path="officer-shift" element={<OfficerShift />} />
          <Route path="shifts" element={<Shifts />} />
        </Route>
        <Route path="auth" element={<Unauthenticated />}>
          <Route index element={<Auth />} />
          <Route path="login" element={<Login />} />
          <Route path="forgot" element={<Forgot />} />
          <Route path="reset/:token" element={<Reset />} />
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </ErrorBoundary>
  )
}

export default App
