import {formatQueryParams} from '../lib/formatters'
import {useAuthMutation, useAuthQuery} from './useAuthQuery'

const KEYS = ['unread briefing']

const BRIEFING_NOTIF_TYPE = 'App\\Notifications\\BriefingBoardNotification'

const useUnreadBriefings = ({enabled = false}) => {
  const queryParams = formatQueryParams({
    type: BRIEFING_NOTIF_TYPE,
  })
  const endpoint = `/notification/unread?${queryParams}`
  const queryOptions = {enabled}
  const queryData = useAuthQuery({
    keys: KEYS,
    endpoint,
    queryOptions,
  })

  return queryData
}

const useMarkBriefing = () => {
  const endpoint = '/notification/mark_read'
  const invalidationKeys = KEYS
  const mutationState = useAuthMutation({
    endpoint,
    invalidationKeys,
  })

  return mutationState
}

export {useUnreadBriefings, useMarkBriefing}
