import * as React from 'react'
import Map from '@arcgis/core/Map'
import MapView from '@arcgis/core/views/MapView'
import config from '@arcgis/core/config'
import Search from '@arcgis/core/widgets/Search'
import Graphic from '@arcgis/core/Graphic'
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer'
import {usePosition} from '../context/position'

const useArcgisMap = (mapContainerId, defaultLat, defaultLng, defaultAddr) => {
  const {getCoordinates} = usePosition()
  const [search, setSearch] = React.useState()

  React.useEffect(() => {
    const run = async () => {
      const {latitude, longitude} = await getCoordinates()
      config.apiKey = process.env.REACT_APP_ARCGIS_KEY

      const map = new Map({
        basemap: 'arcgis-navigation',
      })

      const view = new MapView({
        container: mapContainerId,
        map,
        center: [defaultLng ?? longitude, defaultLat ?? latitude],
        zoom: defaultLng ? 15 : 8,
      })

      const search = new Search({
        view,
      })

      const graphicsLayer = new GraphicsLayer()
      map.add(graphicsLayer)

      if (defaultLat && defaultLng) {
        const point = {
          // Create a point
          type: 'point',
          longitude: defaultLng,
          latitude: defaultLat,
        }
        const simpleMarkerSymbol = {
          type: 'simple-marker',
          color: [226, 119, 40], // Orange
          outline: {
            color: [255, 255, 255], // White
            width: 1,
          },
        }

        const pointGraphic = new Graphic({
          geometry: point,
          symbol: simpleMarkerSymbol,
        })
        graphicsLayer.add(pointGraphic)
      }

      setSearch(search)

      view.ui.add(search, 'top-right')
    }

    run()
  }, [defaultLat, defaultLng, getCoordinates, defaultAddr, mapContainerId])

  return search
}

export default useArcgisMap
