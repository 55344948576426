import * as React from 'react'

const useConnectionStatus = () => {
  const [isOnline, setIsOnline] = React.useState(navigator.onLine)

  const handleConnectionChange = React.useCallback(e => {
    setIsOnline(e.type === 'online')
  }, [])

  React.useEffect(() => {
    window.addEventListener('online', handleConnectionChange)
    window.addEventListener('offline', handleConnectionChange)

    return () => {
      window.removeEventListener('online', handleConnectionChange)
      window.removeEventListener('offline', handleConnectionChange)
    }
  }, [handleConnectionChange])

  return isOnline
}

export default useConnectionStatus
