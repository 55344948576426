import {
  Box,
  Flex,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  useBreakpointValue,
} from '@chakra-ui/react'
import {formatDate} from '../../../lib/formatters'
import PoolReportsMobileItem from './PoolReportsMobileItem'

const PoolsMobileTable = ({poolReports, onEdit, onDelete}) => {
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  })

  if (isDesktop) {
    return null
  }

  return (
    <Box
      boxShadow="lg"
      display={{
        lg: 'none',
      }}
    >
      <Flex fontSize="sm" px={4} py={2}>
        <Flex gap={2} flex="1">
          <Text fontWeight="bold" flexBasis="50%">
            Date
          </Text>
          <Text fontWeight="bold" flexBasis="50%">
            Type
          </Text>
          <Box boxSize="1rem" />
        </Flex>
      </Flex>
      <Accordion allowToggle>
        {poolReports.map(poolReport => (
          <AccordionItem key={poolReport.poolReportId}>
            {({isExpanded}) => (
              <>
                <AccordionButton fontSize="sm">
                  <Flex flex="1" textAlign="start" gap={2} align="center">
                    <Text flexBasis="50%">
                      {formatDate(poolReport.incidentDate)}
                    </Text>
                    <Text flexBasis="50%">{poolReport.incidentType}</Text>
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel
                  fontSize="sm"
                  position="relative"
                  overflow="visible"
                >
                  <PoolReportsMobileItem
                    isExpanded={isExpanded}
                    canEdit={poolReport.canEdit}
                    canDelete={poolReport.canDelete}
                    poolReportId={poolReport.poolReportId}
                    onEdit={onEdit}
                    onDelete={onDelete}
                  />
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  )
}

export default PoolsMobileTable
