import {Flex, Text, useToast} from '@chakra-ui/react'
import * as React from 'react'
import {useAuth} from '../../../context/auth'
import IncidentsMobileTable from './IncidentsMobileTable'
import IncidentsDesktopTable from './IncidentsDesktopTable'
import Paginator from '../../Paginator'
import DeleteModal from '../../DeleteModal'
import IncidentsDetails from '../IncidentsDetails'
import IncidentsFormModal from '../IncidentsFormModal'
import Acl from '../../Acl'
import {
  incidentPermissionsDelete,
  incidentPermissionsEdit,
  getIncidentsWithActions,
} from '../Incidents.helpers'
import {usePatch} from '../../../hooks/entities'
import {isObjectEmpty} from '../../../lib/objects'
import {ENTITIES, ENTITIES_KEYS} from '../../../lib/entities'
import {MODAL_KEYS} from '../../../lib/modal'

const IncidentsTable = ({
  items,
  sort,
  itemsTotal,
  pages,
  pagesCount,
  currentPage,
  filters,
  onSort,
  onPageChange,
}) => {
  const [incidentId, setIncidentId] = React.useState()
  const [activeModal, setActiveModal] = React.useState()
  const {user} = useAuth()
  const {mutate, isLoading} = usePatch({
    id: incidentId,
    entity: ENTITIES.incidents,
    currentPage,
    filters,
    sort,
  })
  const toast = useToast({
    position: 'top',
    isClosable: true,
  })

  const handleDetails = id => {
    setIncidentId(id)
    setActiveModal(MODAL_KEYS.details)
  }

  const handleEdit = id => {
    setIncidentId(id)
    setActiveModal(MODAL_KEYS.edit)
  }

  const handleDelete = id => {
    setIncidentId(id)
    setActiveModal(MODAL_KEYS.delete)
  }

  const handleModalClose = () => {
    setIncidentId(null)
    setActiveModal(null)
  }

  const handleUpdate = incidentData => {
    mutate(
      {
        ...incidentData,
        [ENTITIES_KEYS.incidents]: incidentId,
      },
      {
        onSuccess() {
          toast({
            title: 'Incident report updated!',
            status: 'success',
          })
          handleModalClose()
        },
        onError() {
          toast({
            title: 'Error updating Incident report',
            status: 'error',
          })
        },
      }
    )
  }

  const incidents = getIncidentsWithActions(items, user)

  if (!incidents?.length && isObjectEmpty(filters)) {
    return (
      <Flex justify="center" boxShadow="lg" p={4}>
        <Text>No data found for this section</Text>
      </Flex>
    )
  }

  if (!incidents?.length && !isObjectEmpty(filters)) {
    return (
      <Flex justify="center" boxShadow="lg" p={4}>
        <Text>No data found for the filters applied</Text>
      </Flex>
    )
  }

  return (
    <>
      <IncidentsMobileTable
        incidents={incidents}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
      <IncidentsDesktopTable
        incidents={incidents}
        sort={sort}
        onSort={onSort}
        onDetails={handleDetails}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />

      <Paginator
        itemsTotal={itemsTotal}
        pages={pages}
        pagesCount={pagesCount}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />

      {activeModal === MODAL_KEYS.details ? (
        <IncidentsDetails
          incidentId={incidentId}
          isOpen={activeModal === MODAL_KEYS.details}
          onClose={handleModalClose}
        />
      ) : null}

      <Acl requiredPermissions={incidentPermissionsEdit}>
        {activeModal === MODAL_KEYS.edit ? (
          <IncidentsFormModal
            incidentId={incidentId}
            isOpen={activeModal === MODAL_KEYS.edit}
            isSubmiting={isLoading}
            onClose={handleModalClose}
            onSubmit={handleUpdate}
          />
        ) : null}
      </Acl>

      <Acl requiredPermissions={incidentPermissionsDelete}>
        {activeModal === MODAL_KEYS.delete ? (
          <DeleteModal
            id={incidentId}
            sort={sort}
            entityName="Incident report"
            filters={filters}
            entity={ENTITIES.incidents}
            currentPage={currentPage}
            entityKey={ENTITIES_KEYS.incidents}
            isOpen={activeModal === MODAL_KEYS.delete}
            onClose={handleModalClose}
          />
        ) : null}
      </Acl>
    </>
  )
}

export default IncidentsTable
