import {useUserProperties} from '../context/property'
import {useDarCodes, useDarTypes} from './dar'
import {useAllHouseholds} from './households'
import {useIncidentTypes} from './incidents'
import {usePools} from './pools'
import {useAllVehicles} from './vehicles'
import {useViolationTypes} from './violations'

const useAppInit = () => {
  const {currentProperty} = useUserProperties()

  const isEnabled = Boolean(currentProperty?.propertyId)

  const {isLoading: isLoadingHouseholds} = useAllHouseholds({
    enabled: isEnabled,
  })
  const {isLoading: isLoadingVehicles} = useAllVehicles({enabled: isEnabled})
  const {isLoading: isLoadingTypes} = useDarTypes({
    enabled: isEnabled,
  })
  const {isLoading: isLoadingCodes} = useDarCodes({
    enabled: isEnabled,
  })
  const {isLoading: isLoadingViolationTypes} = useViolationTypes({
    enabled: isEnabled,
  })
  const {isLoading: isLoadingIncidentTypes} = useIncidentTypes({
    enabled: isEnabled,
  })
  const {isLoading: isLoadingPools} = usePools({
    enabled: isEnabled,
  })

  return (
    isLoadingTypes ||
    isLoadingCodes ||
    isLoadingViolationTypes ||
    isLoadingIncidentTypes ||
    isLoadingHouseholds ||
    isLoadingPools ||
    isLoadingVehicles
  )
}

export default useAppInit
