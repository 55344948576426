import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  useToast,
} from '@chakra-ui/react'
import * as React from 'react'
import {useForm} from 'react-hook-form'
import {getDefaultValues} from '../../lib/form/pools'
import {useUserProperties} from '../../context/property'

const PoolForm = ({pool, isSubmiting, onSubmit}) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: {errors},
  } = useForm({
    defaultValues: getDefaultValues(pool),
  })
  const toast = useToast({
    position: 'top',
    isClosable: true,
  })
  const {currentProperty} = useUserProperties()

  const handleFormSubmit = async values => {
    try {
      const {propertyId} = currentProperty

      const poolData = {
        ...values,
        propertyId,
      }

      onSubmit(poolData)
    } catch (e) {
      toast({
        title: 'Oops, there is some error in your data',
      })
    }
  }

  React.useEffect(
    () => () => {
      reset()
    },
    [reset]
  )

  return (
    <form id="pool-form" onSubmit={handleSubmit(handleFormSubmit)}>
      <FormControl mb={4} isInvalid={errors?.name} isDisabled={isSubmiting}>
        <FormLabel>Name</FormLabel>
        <Input {...register('name', {required: 'This field is required'})} />
        {errors?.name ? (
          <FormErrorMessage>{errors.name.message}</FormErrorMessage>
        ) : null}
      </FormControl>
    </form>
  )
}

export default PoolForm
