import {getEntitiesWithActions} from '../../lib/entities'

const violationPermissionsList = [
  'vehicleviolation list',
  'vehicleviolation show',
]
const violationPermissionsCreate = [
  'vehicleviolation create',
  'vehicleviolation store',
]
const violationPermissionsEdit = ['vehicleviolation update']
const violationPermissionsDelete = ['vehicleviolation delete']

const sortFields = [
  {
    key: 'violation_date',
    dir: 'desc',
    label: 'By date Descending ',
  },
  {
    key: 'violation_date',
    dir: 'asc',
    label: 'By date Ascending ',
  },
  {
    key: 'officer_id',
    dir: 'desc',
    label: 'By officer Descending',
  },
  {
    key: 'officer_id',
    dir: 'asc',
    label: 'By officer Ascending',
  },
  {
    key: 'vehicle_id',
    dir: 'desc',
    label: 'By lic. plate Descending',
  },
  {
    key: 'vehicle_id',
    dir: 'asc',
    label: 'By lic. plate Ascending',
  },
  {
    key: 'violation_type_id',
    dir: 'desc',
    label: 'By type Descending',
  },
  {
    key: 'violation_type_id',
    dir: 'asc',
    label: 'By type Ascending',
  },
]

const canEditValidation = entity => entity.automaticDar === 0

const canDeleteValidation = entity => entity.automaticDar === 0

const getViolationsWithActions = (violations, user) =>
  getEntitiesWithActions(
    violations,
    user,
    violationPermissionsEdit,
    violationPermissionsDelete,
    canEditValidation,
    canDeleteValidation
  )

export {
  violationPermissionsList,
  violationPermissionsCreate,
  violationPermissionsEdit,
  violationPermissionsDelete,
  getViolationsWithActions,
  sortFields,
}
