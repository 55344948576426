import {ENTITIES_KEYS} from '../entities'
import {toCamelCase} from '../formatters'

const filesRgx = /\.(gif|jpe?g|tiff?|png|webp|bmp|pdf)$/i

const getDefaultValues = property => ({
  units: 10,
  name: property?.name || '',
  contactName: property?.contactName || '',
  telephone: property?.telephone || '',
  email: property?.email || '',
  maintenanceEmail: property?.maintenanceEmail || '',
  emergencyContacts: property?.emergencyContacts || '',
  notes: property?.notes || '',
  street: property?.street || '',
  number: property?.number || '',
  zipCode: property?.zipCode || '',
  city: property?.city || '',
  gpsCoordinates: property?.gpsCoordinates || '',
  propertyTypeId: property?.propertyTypeId || '',
  sections: property?.propertyHiredTypes || '',
  rules: property?.rules || [],
  towCompanies: property?.towCompanies || null,
})

const getBasicDefaultValues = currentValues => ({
  name: currentValues.name || '',
  contactName: currentValues.contactName || '',
  telephone: currentValues.telephone || '',
  email: currentValues.email || '',
  maintenanceEmail: currentValues.maintenanceEmail || '',
  emergencyContacts: currentValues.emergencyContacts || '',
  notes: currentValues.notes || '',
})

const getLocationDefaultValues = currentValues => ({
  street: currentValues.street || '',
  number: currentValues.number || '',
  city: currentValues.city || '',
  zipCode: currentValues.zipCode || '',
  gpsCoordinates: currentValues.gpsCoordinates || '',
})

const getTypeDefaultValues = ({types, currentValues}) => {
  let propertyTypeId = currentValues.propertyTypeId || ''
  let sections = currentValues.sections || {}

  if (propertyTypeId) {
    propertyTypeId = types.find(t => t.value === propertyTypeId)
    sections = sections.reduce((acc, s) => {
      acc[`s-${s.sectionId}`] = Boolean(s.granted)

      return acc
    }, {})
  }

  return {
    propertyTypeId,
    ...sections,
  }
}

const getRulesDefaultValues = ({currentValues, rules}) => {
  const defaultRules =
    currentValues?.rules?.reduce((acc, r) => {
      if (r.type === 'select') {
        const {relationship} = rules.find(ru => ru.ruleId === r.ruleId)
        const entity = toCamelCase(relationship)
        acc[r.ruleId] = currentValues[entity].map(e => ({
          value: e[ENTITIES_KEYS[entity]],
          label: e.name,
        }))
      } else {
        acc[r.ruleId] = r.value
      }

      return acc
    }, {}) || {}

  return {
    ...defaultRules,
  }
}

const sortRules = rules =>
  rules.sort((a, b) => (a.ruleOrder > b.ruleOrder ? 1 : -1))

const getRules = (types, currentValues) => {
  const {rules} = types.find(t => t.value === currentValues.propertyTypeId)

  return rules
}

const getSortedRules = (types, currentValues) =>
  sortRules(getRules(types, currentValues))

export {
  filesRgx,
  getDefaultValues,
  getBasicDefaultValues,
  getLocationDefaultValues,
  getTypeDefaultValues,
  getRulesDefaultValues,
  getSortedRules,
}
