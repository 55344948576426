const USER_STATUS = {
  enabled: 1,
  disabled: 0,
}

const USER_STATUS_OPTIONS = Object.keys(USER_STATUS).map(k => ({
  value: USER_STATUS[k],
  label: `${k[0].toUpperCase()}${k.slice(1)}`,
}))

const getDefaultRole = (user, roles) => {
  if (!user) {
    return ''
  }

  return roles.find(r => r.value === user.roleId)
}

const getDefaultProperties = (user, properties) => {
  if (!user?.properties) {
    return []
  }

  return user.properties.map(u =>
    properties.find(p => p.value === u.propertyId)
  )
}

const getDefaultStatus = (user, status) => {
  if (!user) {
    return ''
  }

  return status.find(s => s.value === USER_STATUS[user.status])
}

const getDefaultUserValues = ({user, roles, properties}) => {
  const roleId = getDefaultRole(user, roles)
  const userProperties = getDefaultProperties(user, properties)
  const status = getDefaultStatus(user, USER_STATUS_OPTIONS)

  return {
    roleId,
    status,
    properties: userProperties,
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    email: user?.email ?? '',
    userName: user?.userName ?? '',
  }
}

const PROPERTY_ACTIONS = {
  none: 'none',
  add: 'add',
  remove: 'remove',
}

const getUserPropertyAction = (propertyId, user) => {
  if (!user?.properties) {
    return PROPERTY_ACTIONS.add
  }

  return user.properties.find(p => p.propertyId === propertyId)
    ? PROPERTY_ACTIONS.none
    : PROPERTY_ACTIONS.add
}

const getUserProperties = (properties, user) => {
  const userProperties = properties.map(({value}) => ({
    propertyId: value,
    action: getUserPropertyAction(value, user),
  }))

  const delUserProperties = user
    ? user.properties
        .filter(up => !properties.find(p => p.value === up.propertyId))
        .map(p => ({
          propertyId: p.propertyId,
          action: PROPERTY_ACTIONS.remove,
        }))
    : []

  return [...userProperties, ...delUserProperties]
}

export {
  USER_STATUS,
  USER_STATUS_OPTIONS,
  PROPERTY_ACTIONS,
  getDefaultUserValues,
  getUserProperties,
}
