import {getDateToDb} from './index'

const DAY_TIME = 24 * 60 * 60 * 1000

const DAY_IN_MS = 1000 * 60 * 60 * 24

const WEEK_DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

const getScheduleFilters = currentWeek => ({
  from: getDateToDb(currentWeek.dates[0], {withHours: false}),
  to: getDateToDb(currentWeek.dates[currentWeek.dates.length - 1], {
    withHours: false,
  }),
})

const getDefaultWeek = () => {
  const today = new Date()
  const firstDayOfYear = new Date(today.getFullYear(), 0, 1)
  const pastDaysOfYear = (today - firstDayOfYear) / 86400000
  let currentWeek = Math.ceil(
    (pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7
  )
  currentWeek = currentWeek < 10 ? `0${currentWeek}` : currentWeek
  return currentWeek
}

const getCurrentWeek = date => {
  const currentYear = date.getFullYear()
  const currentMonth = date.getMonth()

  const firstDayOfWeek = new Date(
    currentYear,
    currentMonth,
    date.getDate() - date.getDay(),
    0,
    0,
    0,
    0
  )

  return new Array(7)
    .fill(firstDayOfWeek)
    .map((day, index) => new Date(day.getTime() + index * DAY_TIME))
}

const getDatesOfWeek = value => {
  if (!value) return []
  const [year, week] = value
  const currentDay = (week - 1) * 7 - 5 // 1st of January + 7 days for each week
  const firstDayOfWeek = new Date(year, 0, currentDay)

  const dates = [firstDayOfWeek]

  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < 7; i++) {
    const weekDate = new Date(firstDayOfWeek)
    weekDate.setDate(weekDate.getDate() + i)
    dates.push(weekDate)
  }
  return dates
}

const getUserOptions = (officers, propertyId) => {
  if (!propertyId) return []

  return officers.filter(({properties}) =>
    properties.some(p => p.propertyId === propertyId)
  )
}

const getScheduleDate = (dateStr, startTime, endTime) => {
  const startDate = new Date(`${dateStr} ${startTime}:00`)
  const isEndTimeTomorrow =
    Number(startTime.replace(/:/g, '')) > Number(endTime.replace(/:/g, ''))
  let endDate = new Date(`${dateStr} ${endTime}:00`)
  if (isEndTimeTomorrow) {
    endDate = new Date(endDate.getTime() + DAY_IN_MS)
  }

  return {
    startDate,
    endDate,
  }
}

const getIsOverlapingShifts = (schedule, officers) =>
  schedule.dates.some(date => {
    const schedBegDate = new Date(date.startDate)
    const schedEndDate = new Date(date.endDate)

    return schedule.users.some(({userId}) => {
      const {officerSchedules} = officers.find(o => o.value === userId)

      return officerSchedules
        .filter(({shiftWorked}) => !shiftWorked)
        .some(s => {
          const offBegDate = new Date(s.startTime)
          const offEndDate = new Date(s.endTime)

          /** schedules are created weekly so no need to check for month or year */
          const beginBetweenShift =
            schedBegDate.getTime() < offEndDate.getTime() &&
            schedBegDate.getTime() > offBegDate.getTime()

          const endBetweenShift =
            schedEndDate.getTime() > offBegDate.getTime() &&
            schedBegDate.getTime() < offBegDate.getTime()

          return (
            schedBegDate.getDate() === offBegDate.getDate() &&
            (beginBetweenShift || endBetweenShift)
          )
        })
    })
  })

const getDefaultProperty = (schedule, properties) => {
  if (!schedule) {
    return ''
  }

  return properties.find(p => p.value === schedule.propertyId)
}

const getDefaultUser = (schedule, officers) => {
  if (!schedule) {
    return ''
  }

  return [officers.find(p => p.value === schedule.officerId)]
}

const getDefaultValues = ({schedule, properties, officers}) => {
  const propertyId = getDefaultProperty(schedule, properties)
  const users = getDefaultUser(schedule, officers)
  const startTime = schedule
    ? new Date(`${schedule.startTime} UTC`).toTimeString().slice(0, 5)
    : ''

  const endTime = schedule
    ? new Date(`${schedule.endTime} UTC`).toTimeString().slice(0, 5)
    : ''

  return {
    propertyId,
    users,
    startTime,
    endTime,
  }
}

export {
  WEEK_DAYS,
  getScheduleFilters,
  getDefaultWeek,
  getCurrentWeek,
  getDatesOfWeek,
  getUserOptions,
  getScheduleDate,
  getIsOverlapingShifts,
  getDefaultValues,
}
