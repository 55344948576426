import * as React from 'react'
import {OfflineTable} from '../components/OfflineStore'
import {useOfflineStore} from '../context/offlineStore'
import useConnectionStatus from '../hooks/useConnectionStatus'
import usePagination from '../hooks/usePagination'
import useStoreCount from '../hooks/useStoreCount'

const OfflineStore = () => {
  const {store, updateItem, updateItems} = useOfflineStore()
  const count = useStoreCount()
  const {pages, pagesCount, currentPage, handlePageChange} =
    usePagination(count)
  const isOnline = useConnectionStatus()

  React.useEffect(() => {
    if (isOnline) {
      //
    }
  }, [isOnline])

  return (
    <OfflineTable
      store={store}
      pages={pages}
      itemsTotal={count}
      pagesCount={pagesCount}
      currentPage={currentPage}
      onUpdate={updateItem}
      onBulkUpdate={updateItems}
      onPageChange={handlePageChange}
    />
  )
}

export default OfflineStore
