import * as React from 'react'
import {delMany, set} from 'idb-keyval'
import {getStorageItem, setStorageItem} from '../lib/localStorage'
import {getTempId, STORE_KEY, STORE_STATUS} from '../lib/offlineStore'

const StoreContext = React.createContext()
StoreContext.displayName = 'StoreContext'

const REDUCER_ACTIONS = {
  add: 'add',
  update: 'update',
  bulkUpdate: 'bulkUpdate',
  clear: 'clear',
}

const storeReducer = (state, action) => {
  const {item: {type, tempId, data} = {}, items} = action

  switch (action.type) {
    case REDUCER_ACTIONS.add:
      if (!state[type]) {
        state[type] = []
      }

      if (data.images?.length) {
        data.images = data.images.map((image, imageIndex) => {
          const imgId = `${tempId}-${imageIndex}`
          set(imgId, image)
          return imgId
        })
      }

      state[type].push({
        data,
        tempId,
        status: STORE_STATUS.pending,
        error: null,
      })

      setStorageItem(STORE_KEY, state)

      return {...state}
    case REDUCER_ACTIONS.update:
      const updateIndex = state[type].findupdateIndex(i => i.tempId === tempId)

      if (updateIndex >= 0) {
        state[type][updateIndex] = {
          status: STORE_STATUS.pending,
          data,
        }
      }

      return {...state}
    case REDUCER_ACTIONS.bulkUpdate:
      const imagesIds = []
      Object.keys(items).forEach(_type => {
        items[_type].forEach(item => {
          const updateBulkIndex = state[_type].findIndex(
            i => i.tempId === item.tempId
          )
          if (updateBulkIndex >= 0) {
            if (item.status === 1) {
              if (state[_type][updateBulkIndex].data.images) {
                state[_type][updateBulkIndex].data.images.forEach(
                  (_, index) => {
                    imagesIds.push(`${item.tempId}-${index}`)
                  }
                )
              }
              state[_type].splice(updateBulkIndex, 1)
            } else {
              state[_type][updateBulkIndex] = {
                ...state[_type][updateBulkIndex],
                status: STORE_STATUS.error,
                error: item.data,
              }
            }
          }
        })
      })

      delMany(imagesIds)

      setStorageItem(STORE_KEY, state)
      return {...state}
    case REDUCER_ACTIONS.clear:
      setStorageItem(STORE_KEY, {})
      return {}
    default:
      return state
  }
}

const StoreProvider = ({children}) => {
  const [store, dispatch] = React.useReducer(
    storeReducer,
    getStorageItem(STORE_KEY) || {}
  )

  const addToStore = React.useCallback(
    (type, data) => {
      const tempId = getTempId(store, type)
      const item = {type, data, tempId}

      dispatch({type: REDUCER_ACTIONS.add, item})
    },
    [store]
  )

  const updateItem = React.useCallback((type, tempId, data) => {
    const item = {type, tempId, data}
    dispatch({type: REDUCER_ACTIONS.update, item})
  }, [])

  const updateItems = React.useCallback(items => {
    dispatch({type: REDUCER_ACTIONS.bulkUpdate, items})
  }, [])

  const value = React.useMemo(
    () => ({
      store,
      addToStore,
      updateItems,
      updateItem,
    }),
    [store, addToStore, updateItems, updateItem]
  )

  return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
}

const useOfflineStore = () => {
  const context = React.useContext(StoreContext)
  if (context === undefined) {
    throw new Error('useOfflineStore must be use withing a StoreProvider')
  }
  return context
}

export {useOfflineStore}
export default StoreProvider
