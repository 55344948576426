import ReactDOM from 'react-dom/client'
import {HashRouter} from 'react-router-dom'
import {ToastContainer, toast} from 'react-toastify'
import {ChakraProvider, extendTheme} from '@chakra-ui/react'
import 'react-toastify/dist/ReactToastify.css'
import 'react-day-picker/dist/style.css'
import {QueryClient, QueryClientProvider} from 'react-query'
import App from './app'
import AuthProvider from './context/auth'
import PositionProvider from './context/position'
import StoreProvider from './context/offlineStore'
import {chakraTheme} from './lib/theme'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      networkMode: 'always',
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById('root'))

const theme = extendTheme(chakraTheme)

root.render(
  <>
    <ToastContainer
      position={toast.POSITION.TOP_CENTER}
      hideProgressBar={false}
      autoClose={4000}
      theme="colored"
      closeOnClick
      pauseOnHover
      draggable
    />
    <HashRouter>
      <ChakraProvider theme={theme}>
        <StoreProvider>
          <QueryClientProvider client={queryClient}>
            <PositionProvider>
              <AuthProvider>
                <App />
              </AuthProvider>
            </PositionProvider>
          </QueryClientProvider>
        </StoreProvider>
      </ChakraProvider>
    </HashRouter>
  </>
)

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/sw.js')
}
