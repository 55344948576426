const getDefaultType = (dar, types) => {
  if (!dar) {
    return ''
  }

  return types.find(t => t.value === dar.dailyActivityReportTypeId)
}

const getDefaultCode = (dar, codes) => {
  if (!dar) {
    return ''
  }

  return codes.find(c => c.value === dar.dailyActivityReportCodeId)
}

const getDefaultDarValues = ({dar, types, codes}) => {
  const dailyActivityReportTypeId = getDefaultType(dar, types)
  const dailyActivityReportCodeId = getDefaultCode(dar, codes)

  return {
    dailyActivityReportTypeId,
    dailyActivityReportCodeId,
    details: dar?.details ?? '',
    sendNotification: dar?.sendNotification ?? '',
    includeGpsCoordinates: dar?.includeGpsCoordinates ?? '',
  }
}

export {getDefaultDarValues}
