import {
  Box,
  Flex,
  Text,
  Button,
  Icon,
  List,
  ListItem,
  useBreakpointValue,
} from '@chakra-ui/react'
import {MdModeEdit, MdDelete} from 'react-icons/md'
import {HiChevronDown, HiChevronUp} from 'react-icons/hi'
import {formatDate} from '../../../lib/formatters'

const DarDesktopTable = ({dars, sort, onSort, onDetails, onEdit, onDelete}) => {
  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  })

  if (isMobile) {
    return null
  }

  const SortIcon =
    sort?.dir === 'desc' ? (
      <Icon as={HiChevronDown} />
    ) : (
      <Icon as={HiChevronUp} />
    )

  return (
    <List boxShadow="lg" fontSize="xs">
      <ListItem borderBottom="1px solid" borderColor="gray.300">
        <Flex>
          <Flex pl={4} pr={0} py={2} flex="1" fontWeight="bold" align="center">
            <Button
              flexBasis="15%"
              variant="link"
              justifyContent="flex-start"
              rightIcon={sort?.field === 'dar_date' ? SortIcon : null}
              onClick={() => onSort && onSort('dar_date')}
            >
              <Text color="gray.800" fontSize="sm">
                Date
              </Text>
            </Button>
            <Button
              flexBasis="15%"
              variant="link"
              justifyContent="flex-start"
              rightIcon={sort?.field === 'dar_type_id' ? SortIcon : null}
              onClick={() => onSort && onSort('dar_type_id')}
              disabled
            >
              <Text color="gray.800" fontSize="sm">
                Type
              </Text>
            </Button>
            <Button
              flexBasis="15%"
              variant="link"
              justifyContent="flex-start"
              rightIcon={sort?.field === 'user_id' ? SortIcon : null}
              onClick={() => onSort && onSort('user_id')}
            >
              <Text color="gray.800" fontSize="sm">
                User
              </Text>
            </Button>
            <Button
              flexBasis="10%"
              variant="link"
              justifyContent="flex-start"
              rightIcon={sort?.field === 'code_type' ? SortIcon : null}
              onClick={() => onSort && onSort('code_type')}
            >
              <Text color="gray.800" fontSize="sm">
                Code
              </Text>
            </Button>
            <Text
              flexBasis="15%"
              color="gray.800"
              fontSize="sm"
              fontWeight="semibold"
            >
              GPS Coordinates
            </Text>
            <Text
              flexBasis="20%"
              color="gray.800"
              fontSize="sm"
              fontWeight="semibold"
            >
              Details
            </Text>
          </Flex>
          <Box flexBasis="15%" py={2} pr={4} />
        </Flex>
      </ListItem>
      {dars.map(dar => (
        <ListItem
          key={dar.dailyActivityReportId}
          transitionProperty="var(--chakra-transition-property-common)"
          transitionDuration="var(--chakra-transition-duration-normal)"
          _even={{
            bg: 'gray.100',
          }}
          _hover={{
            bg: 'blue.50',
          }}
        >
          <Flex>
            <Button
              w="100%"
              h="100%"
              minH="60px"
              pl={4}
              pr={0}
              py={2}
              flex="1"
              variant="ghost"
              colorScheme="blue"
              textAlign="start"
              fontSize="xs"
              color="gray.700"
              display="block"
              onClick={() => onDetails && onDetails(dar.dailyActivityReportId)}
            >
              <Flex align="center" w="100%">
                <Text flexBasis="15%" whiteSpace="pre-wrap">
                  {formatDate(dar.darDate)}
                </Text>
                <Text flexBasis="15%" whiteSpace="pre-wrap">
                  {dar.type}
                </Text>
                <Text flexBasis="15%">{dar.userName}</Text>
                <Box flexBasis="10%">
                  <Box
                    boxSize="12px"
                    bg={`${dar.codeType.toLowerCase()}`}
                    borderRadius="50%"
                  />
                </Box>
                <Text flexBasis="15%" whiteSpace="break-spaces">
                  {dar.includeGpsCoordinates
                    ? dar.gpsCoordinates.replace(',', ', ')
                    : '-'}
                </Text>
                <Text flexBasis="20%" whiteSpace="pre-wrap">
                  {dar.details}
                </Text>
              </Flex>
            </Button>
            <Flex justify="center" align="center" flexBasis="15%" py={2} pr={4}>
              {dar.canEdit || dar.canDelete ? (
                <Flex gap={2}>
                  {dar.canEdit ? (
                    <Button
                      variant="ghost"
                      onClick={() => onEdit(dar.dailyActivityReportId)}
                    >
                      <Icon as={MdModeEdit} w={6} h={6} />
                    </Button>
                  ) : null}
                  {dar.canDelete ? (
                    <Button
                      variant="ghost"
                      colorScheme="red"
                      onClick={() => onDelete(dar.dailyActivityReportId)}
                    >
                      <Icon as={MdDelete} w={6} h={6} />
                    </Button>
                  ) : null}
                </Flex>
              ) : null}
            </Flex>
          </Flex>
        </ListItem>
      ))}
    </List>
  )
}

export default DarDesktopTable
