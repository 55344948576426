import {Flex, Text} from '@chakra-ui/react'
import * as React from 'react'
import {UsersTable, UsersFilters} from '../components/Users'
import {PagePlaceholder} from '../components/Placeholder'
import MobileSort from '../components/MobileSort'
import Acl from '../components/Acl'
import CreateEntityButton from '../components/CreateEntityButton/CreateEntityButton'
import UserFormModal from '../components/Users/UserFormModal'
import useFilters from '../hooks/useFilters'
import useSort from '../hooks/useSort'
import usePagination from '../hooks/usePagination'
import {useList} from '../hooks/entities'
import {ENTITIES, ENTITIES_NAMES} from '../lib/entities'
import {
  userPermissionsList,
  sortFields,
  userPermissionsCreate,
} from '../components/Users/Users.helpers'

const Users = () => {
  const [itemsTotal, setItemsTotal] = React.useState()
  const {pages, pagesCount, currentPage, handlePageChange} =
    usePagination(itemsTotal)
  const [sort, handleSort] = useSort('user_name')
  const {filters, handleFilters, handleRemoveFilter} = useFilters()
  const {data, isLoading, isSuccess} = useList({
    entity: ENTITIES.users,
    currentPage,
    filters,
    sort,
  })

  if (isLoading) {
    return <PagePlaceholder />
  }

  if (isSuccess && itemsTotal !== data?.users.total) {
    setItemsTotal(data?.users.total)
  }

  return (
    <Flex gap={4} direction="column">
      <Flex align="center">
        <Text fontSize="xl" fontWeight="bold">
          Users
        </Text>
        <Flex
          gap={2}
          ml="auto"
          align="center"
          direction={{
            base: 'column',
            lg: 'row',
          }}
        >
          <Flex gap={2}>
            <UsersFilters
              filters={filters}
              onFilter={handleFilters}
              onRemoveFilter={handleRemoveFilter}
            />
            <MobileSort
              sort={sort}
              sortFields={sortFields}
              onSort={handleSort}
            />
          </Flex>
          <CreateEntityButton
            entity={ENTITIES.users}
            entityName={ENTITIES_NAMES.users}
            permissionCreate={userPermissionsCreate}
            sort={sort}
            filters={filters}
            currentPage={currentPage}
            formModal={UserFormModal}
          />
        </Flex>
      </Flex>
      <UsersTable
        items={data?.users.data}
        sort={sort}
        filters={filters}
        pages={pages}
        itemsTotal={itemsTotal}
        pagesCount={pagesCount}
        currentPage={currentPage}
        onSort={handleSort}
        onPageChange={handlePageChange}
      />
    </Flex>
  )
}

const ProtectedUsers = () => (
  <Acl withRedirect requiredPermissions={userPermissionsList}>
    <Users />
  </Acl>
)

export default ProtectedUsers
