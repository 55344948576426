import * as React from 'react'
import {useAuthMutation, useAuthQuery} from './useAuthQuery'
import {formatQueryParams, toSnakeCase} from '../lib/formatters'
import {ITEMS_PER_PAGE} from '../lib/entities'

const KEYS = {
  list: ({entity, currentPage, sort, filters}) =>
    [
      entity,
      currentPage,
      sort ? JSON.stringify(sort) : undefined,
      filters ? JSON.stringify(filters) : undefined,
    ].filter(Boolean),
  detail: ({id, entity}) => [entity, 'detail', id],
}

const useList = ({entity, currentPage, filters, sort}) => {
  const queryParams = formatQueryParams(filters)
  let endpoint = `/${entity}/list/${ITEMS_PER_PAGE}/${sort.field}/${sort.dir}?page=${currentPage}`

  if (queryParams) {
    endpoint += `&${queryParams}`
  }

  const keys = React.useMemo(
    () => KEYS.list({entity, currentPage, sort, filters}),
    [entity, currentPage, sort, filters]
  )

  const queryState = useAuthQuery({
    keys,
    endpoint,
  })

  return queryState
}

const useItem = ({entity, id, entityKey}) => {
  const endpoint = `/${entity}/show?${toSnakeCase(entityKey)}=${id}`
  const keys = React.useMemo(() => KEYS.detail({entity, id}), [entity, id])
  const queryOptions = {enabled: typeof id === 'number'}

  const queryState = useAuthQuery({
    endpoint,
    keys,
    queryOptions,
  })

  return queryState
}

const usePost = ({entity, currentPage, filters, sort}) => {
  const endpoint = `/${entity}/store`
  const invalidationKeys = [
    React.useMemo(
      () => KEYS.list({entity, currentPage, sort, filters}),
      [entity, currentPage, sort, filters]
    ),
  ]
  const mutationState = useAuthMutation({
    endpoint,
    invalidationKeys,
  })

  return mutationState
}

const usePatch = ({id, entity, currentPage, filters, sort}) => {
  const endpoint = `/${entity}/update`
  const invalidationKeys = [
    React.useMemo(
      () => KEYS.list({entity, currentPage, sort, filters}),
      [entity, currentPage, sort, filters]
    ),
    React.useMemo(() => KEYS.detail({id, entity}), [id, entity]),
  ]
  const mutationState = useAuthMutation({
    endpoint,
    invalidationKeys,
  })

  return mutationState
}

const useDelete = ({id, entity, currentPage, filters, sort}) => {
  const endpoint = `/${entity}/delete`
  const invalidationKeys = [
    React.useMemo(
      () => KEYS.list({entity, currentPage, sort, filters}),
      [entity, currentPage, sort, filters]
    ),
    React.useMemo(() => KEYS.detail({id, entity}), [id, entity]),
  ]
  const method = 'DELETE'
  const mutationState = useAuthMutation({
    endpoint,
    invalidationKeys,
    method,
  })

  return mutationState
}

const useBulkDelete = ({entity, currentPage, filters, sort}) => {
  const endpoint = `/${entity}/bulkdestroy`
  const invalidationKeys = [
    React.useMemo(
      () => KEYS.list({entity, currentPage, sort, filters}),
      [entity, currentPage, sort, filters]
    ),
  ]
  const method = 'DELETE'
  const mutationState = useAuthMutation({
    endpoint,
    invalidationKeys,
    method,
  })

  return mutationState
}

const useBulkStore = () => {
  const endpoint = '/batch/store'
  const invalidationKeys = [
    React.useMemo(
      () => [
        ['dailyactivityreport', 1],
        ['vehicleviolation', 1],
        ['incidentreport', 1],
        ['poolreport', 1],
      ],
      []
    ),
  ]
  const mutationState = useAuthMutation({
    endpoint,
    invalidationKeys,
  })

  return mutationState
}

export {
  useList,
  useItem,
  usePost,
  usePatch,
  useDelete,
  useBulkDelete,
  useBulkStore,
}
