import {Flex, Button, VisuallyHidden, useDisclosure} from '@chakra-ui/react'
import * as React from 'react'
import {Step, Steps, useSteps} from 'chakra-ui-steps'
import ConfirmModal from '../../ConfirmModal'
import {PROPERTIES_FORM_STEPS, FORM_ACTIONS} from './PropertiesForm.helpers'
import {getDefaultValues} from '../../../lib/form/properties'

const PropertiesFormStepper = function ({
  property,
  types,
  towCompanies,
  isSubmiting,
  onSubmit,
}) {
  const [formAction, setFormAction] = React.useState(FORM_ACTIONS.initial)
  const submitButRef = React.useRef()
  const currentValuesRef = React.useRef(getDefaultValues(property))

  const {isOpen, onOpen, onClose} = useDisclosure()
  const {activeStep, nextStep, prevStep} = useSteps({
    initialStep: 0,
  })

  const handleSaveChanges = values => {
    currentValuesRef.current = {
      ...currentValuesRef.current,
      ...values,
    }

    if (
      formAction === FORM_ACTIONS.create ||
      formAction === FORM_ACTIONS.update
    ) {
      onSubmit(currentValuesRef.current)
    } else if (activeStep !== PROPERTIES_FORM_STEPS.length - 1) {
      nextStep()
    }
  }

  const handleCreate = () => {
    setFormAction(FORM_ACTIONS.create)
    setTimeout(() => {
      submitButRef.current.click()
    }, 100)
  }

  const handleUpdate = () => {
    setFormAction(FORM_ACTIONS.update)
    setTimeout(() => {
      submitButRef.current.click()
    }, 100)
  }

  const handleNext = () => {
    setFormAction(FORM_ACTIONS.initial)
    setTimeout(() => {
      submitButRef.current.click()
    }, 100)
  }

  const handleCancelPrev = () => {
    onClose()
  }

  const handleConfirmPrev = () => {
    prevStep()
    onClose()
  }

  const isUpdating = formAction === FORM_ACTIONS.update

  return (
    <>
      <Flex direction="column" h="100%">
        <Steps
          flex="revert"
          colorScheme="blue"
          activeStep={activeStep}
          labelOrientation="vertical"
        >
          {PROPERTIES_FORM_STEPS.map(
            ({label, formId, component: Component}) => (
              <Step key={label} label={label}>
                <Component
                  types={types}
                  formId={formId}
                  property={property}
                  towCompanies={towCompanies}
                  activeStep={activeStep}
                  currentValues={currentValuesRef.current}
                  isSubmiting={isSubmiting}
                  onSaveChanges={handleSaveChanges}
                />
              </Step>
            )
          )}
        </Steps>
        <Flex direction="column" align="flex-end" mt={6} gap={2} minH="88px">
          <Flex gap={2} mt="auto" maxW="150px">
            <Button
              type="button"
              flexBasis="50%"
              variant="outline"
              colorScheme="blue"
              disabled={activeStep === 0}
              onClick={onOpen}
            >
              Prev
            </Button>
            <Button
              type="button"
              variant="solid"
              flexBasis="50%"
              colorScheme="blue"
              disabled={
                (isSubmiting && isUpdating) ||
                activeStep === PROPERTIES_FORM_STEPS.length - 1
              }
              isLoading={isSubmiting && !isUpdating}
              onClick={handleNext}
            >
              Next
            </Button>
          </Flex>
          {activeStep === PROPERTIES_FORM_STEPS.length - 1 && !property ? (
            <Button
              w="100%"
              maxW="140px"
              type="button"
              variant="solid"
              flexBasis="50%"
              colorScheme="blue"
              isLoading={isSubmiting}
              onClick={handleCreate}
            >
              Create
            </Button>
          ) : null}
          {property ? (
            <Button
              w="100%"
              maxW="140px"
              type="button"
              variant="solid"
              colorScheme="blue"
              disabled={isSubmiting && !isUpdating}
              isLoading={isSubmiting && isUpdating}
              onClick={handleUpdate}
            >
              Update
            </Button>
          ) : null}
          <VisuallyHidden>
            <Button
              form={PROPERTIES_FORM_STEPS[activeStep].formId}
              type="submit"
              ref={submitButRef}
            >
              submit
            </Button>
          </VisuallyHidden>
        </Flex>
      </Flex>
      <ConfirmModal
        isOpen={isOpen}
        title="Confirm"
        text="Unsaved changes will be lost. Do you want to continue?"
        onCancel={handleCancelPrev}
        onConfirm={handleConfirmPrev}
      />
    </>
  )
}

export default PropertiesFormStepper
