import {
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  useToast,
} from '@chakra-ui/react'
import * as React from 'react'
import {Controller, useForm} from 'react-hook-form'
import {AsyncSelect} from '../Select'
import {getDefaultValues} from '../../lib/form/vehicles'
import {useUserProperties} from '../../context/property'
import {useHouseholdsByCriteria} from '../../hooks/households'

const VehicleForm = ({vehicle, isSubmiting, onSubmit}) => {
  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: {errors},
  } = useForm({
    defaultValues: getDefaultValues(vehicle),
  })
  const toast = useToast({
    position: 'top',
    isClosable: true,
  })
  const {currentProperty} = useUserProperties()
  const {handleLoadOptions} = useHouseholdsByCriteria('userId')

  const handleFormSubmit = async values => {
    try {
      const {value: userId} = values.userId
      const {propertyId} = currentProperty

      const vehicledata = {
        ...values,
        propertyId,
        userId,
      }

      onSubmit(vehicledata)
    } catch (e) {
      toast({
        title: 'Oops, there is some error in your data',
      })
    }
  }

  React.useEffect(
    () => () => {
      reset()
    },
    [reset]
  )

  return (
    <form id="vehicle-form" onSubmit={handleSubmit(handleFormSubmit)}>
      <Flex mb={2} gap={2}>
        <FormControl
          flexBasis="50%"
          isInvalid={errors?.userId}
          isDisabled={isSubmiting}
        >
          <FormLabel>Resident</FormLabel>
          <Controller
            control={control}
            name="userId"
            rules={{
              validate: ({value} = {}) =>
                value !== undefined || 'You must select a resident',
              valueAsNumber: true,
            }}
            render={({field}) => (
              <AsyncSelect
                isClearable
                isSearchable
                cacheOptions
                placeholder="Select resident"
                loadOptions={handleLoadOptions}
                {...field}
              />
            )}
          />
          {errors?.userId ? (
            <FormErrorMessage>{errors.userId.message}</FormErrorMessage>
          ) : null}
        </FormControl>

        <FormControl
          flexBasis="50%"
          isInvalid={errors?.licensePlate}
          isDisabled={isSubmiting}
        >
          <FormLabel>License plate</FormLabel>
          <Input
            {...register('licensePlate', {required: 'This field is required'})}
          />
          {errors?.licensePlate ? (
            <FormErrorMessage>{errors.licensePlate.message}</FormErrorMessage>
          ) : null}
        </FormControl>
      </Flex>

      <Flex mb={2} gap={2}>
        <FormControl
          flexBasis="50%"
          isInvalid={errors?.make}
          isDisabled={isSubmiting}
        >
          <FormLabel>Make</FormLabel>
          <Input {...register('make', {required: 'This field is required'})} />
          {errors?.make ? (
            <FormErrorMessage>{errors.make.message}</FormErrorMessage>
          ) : null}
        </FormControl>
        <FormControl
          flexBasis="50%"
          isInvalid={errors?.model}
          isDisabled={isSubmiting}
        >
          <FormLabel>Model</FormLabel>
          <Input {...register('model', {required: 'This field is required'})} />
          {errors?.model ? (
            <FormErrorMessage>{errors.model.message}</FormErrorMessage>
          ) : null}
        </FormControl>
      </Flex>

      <Flex mb={2} gap={2}>
        <FormControl
          flexBasis="50%"
          isInvalid={errors?.color}
          isDisabled={isSubmiting}
        >
          <FormLabel>Color</FormLabel>
          <Input {...register('color', {required: 'This field is required'})} />
          {errors?.color ? (
            <FormErrorMessage>{errors.color.message}</FormErrorMessage>
          ) : null}
        </FormControl>
        <FormControl
          flexBasis="50%"
          isInvalid={errors?.state}
          isDisabled={isSubmiting}
        >
          <FormLabel>State</FormLabel>
          <Input {...register('state', {required: 'This field is required'})} />
          {errors?.state ? (
            <FormErrorMessage>{errors.state.message}</FormErrorMessage>
          ) : null}
        </FormControl>
      </Flex>
    </form>
  )
}

export default VehicleForm
