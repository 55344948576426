import {Flex, Text} from '@chakra-ui/react'
import * as React from 'react'
import {PropertiesTable, PropertiesFilters} from '../components/Properties'
import {PagePlaceholder} from '../components/Placeholder'
import MobileSort from '../components/MobileSort'
import Acl from '../components/Acl'
import CreateEntityButton from '../components/CreateEntityButton/CreateEntityButton'
import PropertiesFormModal from '../components/Properties/PropertiesFormModal'
import useFilters from '../hooks/useFilters'
import useSort from '../hooks/useSort'
import usePagination from '../hooks/usePagination'
import {useList} from '../hooks/entities'
import {ENTITIES, ENTITIES_NAMES} from '../lib/entities'
import {
  propertiesPermissionsCreate,
  propertiesPermissionsList,
  sortFields,
} from '../components/Properties/Properties.helpers'

const Properties = function () {
  const [itemsTotal, setItemsTotal] = React.useState()
  const {pages, pagesCount, currentPage, handlePageChange} =
    usePagination(itemsTotal)
  const [sort, handleSort] = useSort('name')
  const {filters, handleFilters, handleRemoveFilter} = useFilters()
  const {data, isLoading, isSuccess} = useList({
    entity: ENTITIES.properties,
    currentPage,
    filters,
    sort,
  })

  if (isLoading) {
    return <PagePlaceholder />
  }

  if (isSuccess && itemsTotal !== data?.properties.total) {
    setItemsTotal(data?.properties.total)
  }

  return (
    <Flex gap={4} direction="column">
      <Flex align="center">
        <Text fontSize="xl" fontWeight="bold">
          Properties
        </Text>
        <Flex
          gap={2}
          ml="auto"
          align="center"
          direction={{
            base: 'column',
            lg: 'row',
          }}
        >
          <Flex gap={2}>
            <PropertiesFilters
              filters={filters}
              onFilter={handleFilters}
              onRemoveFilter={handleRemoveFilter}
            />
            <MobileSort
              sort={sort}
              sortFields={sortFields}
              onSort={handleSort}
            />
          </Flex>
          <CreateEntityButton
            entity={ENTITIES.properties}
            entityName={ENTITIES_NAMES.properties}
            permissionCreate={propertiesPermissionsCreate}
            sort={sort}
            filters={filters}
            currentPage={currentPage}
            formModal={PropertiesFormModal}
          />
        </Flex>
      </Flex>
      <PropertiesTable
        items={data?.properties.data}
        sort={sort}
        filters={filters}
        pages={pages}
        itemsTotal={itemsTotal}
        pagesCount={pagesCount}
        currentPage={currentPage}
        onSort={handleSort}
        onPageChange={handlePageChange}
      />
    </Flex>
  )
}

const ProtectedProperties = function () {
  return (
    <Acl withRedirect requiredPermissions={propertiesPermissionsList}>
      <Properties />
    </Acl>
  )
}

export default ProtectedProperties
