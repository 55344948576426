import {
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Textarea,
} from '@chakra-ui/react'
import * as React from 'react'
import {useForm} from 'react-hook-form'
import {validateMail} from '../../../lib/form'
import {filesRgx, getBasicDefaultValues} from '../../../lib/form/properties'
import {FilesInput} from '../../Commons'

const PropertiesFormInfo = function ({
  formId,
  property,
  currentValues,
  isSubmiting,
  onSaveChanges,
}) {
  const [files, setFiles] = React.useState(
    () => currentValues.files || property?.files || []
  )
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    defaultValues: getBasicDefaultValues(currentValues),
  })

  const handleFiles = selectedFiles => {
    setFiles(selectedFiles)
  }

  const handleFormSubmit = values => {
    const propertyFiles = files.filter(file => file.startsWith('data:image/'))
    let propertyFilesDel

    if (property?.files) {
      propertyFilesDel = property.files.filter(i => !files.find(f => f === i))
    }

    const infoData = {
      ...values,
      files: propertyFiles,
      filesDel: propertyFilesDel,
    }

    onSaveChanges(infoData)
  }

  return (
    <form id={formId} onSubmit={handleSubmit(handleFormSubmit)} noValidate>
      <FormControl mb={2} isInvalid={errors?.name} isDisabled={isSubmiting}>
        <FormLabel>Name</FormLabel>
        <Input
          type="text"
          {...register('name', {required: 'This field is required'})}
        />
        {errors?.name ? (
          <FormErrorMessage>{errors.name.message}</FormErrorMessage>
        ) : null}
      </FormControl>

      <Flex gap={2} mb={2}>
        <FormControl
          flexBasis="50%"
          isInvalid={errors?.contactName}
          isDisabled={isSubmiting}
        >
          <FormLabel>Contact name</FormLabel>
          <Input
            type="text"
            {...register('contactName', {required: 'This field is required'})}
          />
          {errors?.contactName ? (
            <FormErrorMessage>{errors.contactName.message}</FormErrorMessage>
          ) : null}
        </FormControl>
        <FormControl
          flexBasis="50%"
          isInvalid={errors?.telephone}
          isDisabled={isSubmiting}
        >
          <FormLabel>Telephone</FormLabel>
          <Input
            type="tel"
            {...register('telephone', {required: 'This field is required'})}
          />
          {errors?.telephone ? (
            <FormErrorMessage>{errors.telephone.message}</FormErrorMessage>
          ) : null}
        </FormControl>
      </Flex>
      <Flex gap={2} mb={2}>
        <FormControl
          flexBasis="50%"
          isInvalid={errors?.email}
          isDisabled={isSubmiting}
        >
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            {...register('email', {
              validate: value => {
                if (!value) {
                  return 'This field is required'
                }

                return validateMail(value) || 'Wrong format: jon@doe.com'
              },
            })}
          />
          {errors?.email ? (
            <FormErrorMessage>{errors.email.message}</FormErrorMessage>
          ) : null}
        </FormControl>
        <FormControl
          flexBasis="50%"
          isInvalid={errors?.maintenanceEmail}
          isDisabled={isSubmiting}
        >
          <FormLabel>Maintenance email</FormLabel>
          <Input
            type="email"
            {...register('maintenanceEmail', {
              validate: value => {
                if (!value) {
                  return 'This field is required'
                }

                return validateMail(value) || 'Wrong format: jon@doe.com'
              },
            })}
          />
          {errors?.maintenanceEmail ? (
            <FormErrorMessage>
              {errors.maintenanceEmail.message}
            </FormErrorMessage>
          ) : null}
        </FormControl>
      </Flex>
      <FormControl
        mb={2}
        isInvalid={errors?.emergencyContacts}
        isDisabled={isSubmiting}
      >
        <FormLabel>Emergency contacts</FormLabel>
        <Input
          type="tel"
          {...register('emergencyContacts', {
            required: 'This field is required',
          })}
        />
        {errors?.emergencyContacts ? (
          <FormErrorMessage>
            {errors.emergencyContacts.message}
          </FormErrorMessage>
        ) : null}
      </FormControl>

      <FormControl mb={2} isDisabled={isSubmiting}>
        <FormLabel>Notes</FormLabel>
        <Textarea size="sm" resize="none" {...register('notes')} />
      </FormControl>

      <FormControl isDisabled={isSubmiting}>
        <FormLabel>Files</FormLabel>
        <FilesInput
          files={files}
          onChange={handleFiles}
          validFileExtensionsReg={filesRgx}
          extensionErrorMessage="Wrong file extensions. Please select a jpeg, png, bmp or pdf file."
        />
      </FormControl>
    </form>
  )
}

export default PropertiesFormInfo
