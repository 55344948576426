import {useAuth} from '../context/auth'
import {usePosition} from '../context/position'
import {useUserProperties} from '../context/property'
import {getFullDateToDb} from '../lib/form'
import {useAuthMutation} from './useAuthQuery'

const useEndOfShift = () => {
  const {handleLogout} = useAuth()
  const {getCoordinates} = usePosition()
  const {currentProperty} = useUserProperties()
  const endOfShitMutation = useAuthMutation({
    endpoint: '/officerlog/store',
  })

  const handleEndOfShift = async () => {
    if (endOfShitMutation.isLoading) {
      return
    }
    const {latitude, longitude} = await getCoordinates()

    endOfShitMutation.mutate(
      {
        officerLogTypeId: 2,
        gpsCoordinates: `${latitude},${longitude}`,
        dailyActivityReportCodeId: 1,
        propertyId: currentProperty.propertyId,
        logTime: getFullDateToDb(),
      },
      {
        onSuccess() {
          handleLogout()
        },
      }
    )
  }

  return handleEndOfShift
}

export default useEndOfShift
