import {
  Box,
  Flex,
  Text,
  Button,
  Icon,
  List,
  ListItem,
  useBreakpointValue,
} from '@chakra-ui/react'
import {MdDelete} from 'react-icons/md'
import {HiChevronDown, HiChevronUp} from 'react-icons/hi'
import {formatDate} from '../../../lib/formatters'

const BriefsDesktopTable = ({briefings, sort, onSort, onDelete}) => {
  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  })

  if (isMobile) {
    return null
  }

  const SortIcon =
    sort?.dir === 'desc' ? (
      <Icon as={HiChevronDown} />
    ) : (
      <Icon as={HiChevronUp} />
    )

  return (
    <List boxShadow="lg" fontSize="xs">
      <ListItem borderBottom="1px solid" borderColor="gray.300">
        <Flex>
          <Flex pl={4} pr={0} py={2} flex="1" fontWeight="bold" align="center">
            <Text
              flexBasis="50%"
              color="gray.800"
              fontSize="sm"
              fontWeight="semibold"
            >
              Brief
            </Text>
            <Button
              flexBasis="20%"
              variant="link"
              justifyContent="flex-start"
              rightIcon={sort?.field === 'created_at' ? SortIcon : null}
              onClick={() => onSort && onSort('created_at')}
            >
              <Text color="gray.800" fontSize="sm">
                Date
              </Text>
            </Button>
            <Text
              flexBasis="15%"
              color="gray.800"
              fontSize="sm"
              fontWeight="semibold"
            >
              Notified to
            </Text>
            <Text
              flexBasis="15%"
              color="gray.800"
              fontSize="sm"
              fontWeight="semibold"
            >
              Read by
            </Text>
          </Flex>
          <Box flexBasis="15%" py={2} pr={4} />
        </Flex>
      </ListItem>
      {briefings.map(brief => (
        <ListItem
          key={brief.briefingBoardId}
          transitionProperty="var(--chakra-transition-property-common)"
          transitionDuration="var(--chakra-transition-duration-normal)"
          _even={{
            bg: 'gray.100',
          }}
          _hover={{
            bg: 'blue.50',
          }}
        >
          <Flex>
            <Flex
              w="100%"
              h="100%"
              minH="60px"
              pl={4}
              pr={0}
              py={2}
              flex="1"
              flexBasis="90%"
              fontSize="xs"
              color="gray.700"
            >
              <Flex align="center" w="100%">
                <Text flexBasis="50%" whiteSpace="pre-wrap">
                  {brief.brief}
                </Text>
                <Text flexBasis="20%" whiteSpace="pre-wrap">
                  {formatDate(brief.createdAt)}
                </Text>
                <Text flexBasis="15%" whiteSpace="pre-wrap">
                  {brief.notifiedTo}
                </Text>
                <Text flexBasis="15%" whiteSpace="pre-wrap">
                  {brief.readBy}
                </Text>
              </Flex>
            </Flex>
            <Flex justify="center" align="center" flexBasis="15%" py={2} pr={4}>
              <Flex gap={2}>
                {brief.canDelete ? (
                  <Button
                    variant="ghost"
                    colorScheme="red"
                    onClick={() => onDelete(brief.briefingBoardId)}
                  >
                    <Icon as={MdDelete} w={6} h={6} />
                  </Button>
                ) : null}
              </Flex>
            </Flex>
          </Flex>
        </ListItem>
      ))}
    </List>
  )
}

export default BriefsDesktopTable
