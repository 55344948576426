import {
  Flex,
  Icon,
  Text,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Skeleton,
} from '@chakra-ui/react'
import {MdClose} from 'react-icons/md'
import PoolForm from './PoolForm'
import {useItem} from '../../hooks/entities'
import {ENTITIES, ENTITIES_KEYS} from '../../lib/entities'

const PoolFormModal = function ({
  poolId,
  isOpen,
  isSubmiting,
  onClose,
  onSubmit,
}) {
  const {
    data: poolData,
    isLoading: isLoadingPool,
    isFetching,
    isRefetching,
  } = useItem({
    id: poolId,
    entity: ENTITIES.pools,
    entityKey: ENTITIES_KEYS.pools,
  })

  const isLoading = isLoadingPool || isFetching || isRefetching

  const pool = poolData?.data

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      size={{
        base: 'full',
        lg: 'lg',
      }}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader borderBottom="1px solid" borderColor="gray.400">
          <Flex align="center" gap={2}>
            <Button p="0" variant="ghost" onClick={onClose}>
              <Icon as={MdClose} w="24px" h="24px" />
            </Button>
            <Text>{poolId ? 'Update' : 'Create'} pool</Text>
            <Button
              ml="auto"
              type="submit"
              form="pool-form"
              variant="solid"
              colorScheme="blue"
              disabled={isLoading}
              isLoading={isSubmiting}
            >
              {poolId ? 'Update' : 'Create'}
            </Button>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          {isLoading ? (
            <Skeleton h="200px" />
          ) : (
            <PoolForm
              pool={pool}
              onSubmit={onSubmit}
              isSubmiting={isSubmiting}
            />
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default PoolFormModal
