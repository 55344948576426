import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  Button,
  Icon,
  Text,
  FormControl,
  FormLabel,
  Input,
  Tag,
  TagLabel,
  TagCloseButton,
  useDisclosure,
  FormErrorMessage,
  Skeleton,
} from '@chakra-ui/react'
import {Controller, useForm} from 'react-hook-form'
import {FaFilter} from 'react-icons/fa'
import {Select} from '../Select'
import {useProperties} from '../../hooks/properties'
import {filterEmptyValues} from '../../lib/objects'
import {useOfficers} from '../../hooks/auth'
import {useAuth} from '../../context/auth'

const ShiftsFilters = function ({filters, onFilter, onRemoveFilter}) {
  const {user} = useAuth()
  const {isOpen, onOpen, onClose} = useDisclosure()
  const {data: propertiesData, isLoading: isLoadingProperties} = useProperties({
    enabled: isOpen,
  })
  const {data: officersData, isLoading: isLoadingOfficers} = useOfficers({
    enabled: isOpen,
  })
  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: {errors},
  } = useForm({
    defaultValues: {
      ...filters,
    },
  })
  const dateFromWatch = watch('from')

  const onSumit = values => {
    const newFilters = filterEmptyValues(values)

    if (Object.keys(newFilters).length) {
      onFilter(newFilters)
      onClose()
    }
  }

  const handleClick = key => {
    onRemoveFilter(key)
    reset({
      [key]: null,
    })
  }

  const handleClearFilters = () => {
    onFilter({})
    onClose()
    reset({
      propertyId: null,
      userId: null,
      dateFrom: null,
      dateTo: null,
    })
  }

  const properties = propertiesData?.properties?.map(({propertyId, name}) => ({
    value: propertyId,
    label: name,
  }))

  const officers = officersData?.officers?.map(
    ({userId, firstName, lastName}) => ({
      value: userId,
      label: `${firstName} ${lastName}`,
    })
  )

  return (
    <>
      <Flex gap={2} align="center">
        {Object.keys(filters).map(key => (
          <Tag
            key={key}
            size="md"
            borderRadius="full"
            variant="subtle"
            colorScheme="blue"
          >
            <TagLabel>{filters[key]}</TagLabel>
            <TagCloseButton onClick={() => handleClick(key)} />
          </Tag>
        ))}
      </Flex>
      <Button
        title="Open filter modal"
        variant="ghost"
        colorScheme="blue"
        onClick={onOpen}
      >
        <Icon as={FaFilter} />
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent pb={4}>
          <ModalHeader>Filters</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isLoadingProperties || isLoadingOfficers ? (
              <Skeleton h="200px" />
            ) : (
              <form onSubmit={handleSubmit(onSumit)}>
                <Flex gap={2} mb={2}>
                  <FormControl flexBasis="50%">
                    <FormLabel>Property</FormLabel>
                    <Controller
                      control={control}
                      name="propertyId"
                      render={({field}) => (
                        <Select
                          isClearable
                          placeholder="Select a property"
                          options={properties}
                          {...field}
                        />
                      )}
                    />
                  </FormControl>
                  {user.isAdmin ? (
                    <FormControl flexBasis="50%">
                      <FormLabel>Officer</FormLabel>
                      <Controller
                        control={control}
                        name="userId"
                        render={({field}) => (
                          <Select
                            isClearable
                            placeholder="Select a officer"
                            options={officers}
                            {...field}
                          />
                        )}
                      />
                    </FormControl>
                  ) : null}
                </Flex>
                <Flex gap={2} mb={8}>
                  <FormControl flexBasis="50%">
                    <FormLabel>
                      <Text fontSize="sm">Date from</Text>
                    </FormLabel>
                    <Input type="date" {...register('from')} />
                  </FormControl>
                  <FormControl flexBasis="50%" isInvalid={errors?.to?.message}>
                    <FormLabel>
                      <Text fontSize="sm">Date to</Text>
                    </FormLabel>
                    <Input
                      type="date"
                      {...register('to', {
                        validate: value => {
                          if (!dateFromWatch || !value) {
                            return true
                          }
                          const dateFrom = new Date(dateFromWatch).getTime()
                          const dateTo = new Date(value).getTime()

                          return (
                            dateTo > dateFrom ||
                            'Date to must be grater than date from'
                          )
                        },
                      })}
                    />
                    {errors?.to?.message ? (
                      <FormErrorMessage>{errors.to.message}</FormErrorMessage>
                    ) : null}
                  </FormControl>
                </Flex>

                <Flex justify="flex-end" gap={2}>
                  <Button
                    type="button"
                    variant="outline"
                    colorScheme="blue"
                    onClick={handleClearFilters}
                  >
                    <Text fontSize="sm">Clear filters</Text>
                  </Button>
                  <Button type="submit" variant="solid" colorScheme="blue">
                    <Text fontSize="sm">Apply filters</Text>
                  </Button>
                </Flex>
              </form>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ShiftsFilters
