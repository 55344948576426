import {Box, Flex, Image, Text} from '@chakra-ui/react'
import {Outlet, Navigate} from 'react-router-dom'
import {useAuth} from '../context/auth'
import logo from '../images/logo.png'

const Unauthenticated = () => {
  const {user} = useAuth()

  if (user) {
    return <Navigate to="/" replace />
  }

  return (
    <Flex
      h="100%"
      p="32px"
      align="center"
      gap={8}
      justify={{lg: 'center'}}
      direction={{
        base: 'column',
        lg: 'row',
      }}
    >
      <Image
        src={logo}
        alt="logo"
        boxSize={{
          base: '150px',
          lg: '250px',
        }}
      />
      <Box boxShadow="dark-lg" rounded="md" p="32px">
        <Text
          as="h1"
          fontSize="xl"
          fontWeight="bold"
          textAlign="center"
          w="350px"
        >
          Stars Pro Access
        </Text>
        <Outlet />
      </Box>
    </Flex>
  )
}

export default Unauthenticated
