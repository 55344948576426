import {
  Box,
  Flex,
  Link,
  Image,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  useBreakpointValue,
  Button,
  Text,
} from '@chakra-ui/react'
import {Link as RouterLink, useLocation} from 'react-router-dom'
import useStoreCount from '../../hooks/useStoreCount'
import logo from '../../images/logo.png'

const MobileNavbar = ({sections, onLogout, onEndOfShift}) => {
  const location = useLocation()
  const count = useStoreCount()
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  })

  if (isDesktop) {
    return null
  }

  return (
    <Flex
      p={16}
      w="100%"
      h="100%"
      align="center"
      direction="column"
      justify="space-between"
    >
      <Flex align="center" direction="column" w="100%">
        <Box>
          <Image src={logo} alt="logo" w="100px" h="100px" />
        </Box>
        <Accordion w="100%" p={8} borderColor="" textAlign="center">
          {sections
            .filter(section => section.items.length)
            .map(section => (
              <AccordionItem key={section.id}>
                <AccordionButton
                  w="100%"
                  justifyContent="center"
                  fontWeight="semibold"
                >
                  {section.title}
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
                  <Flex direction="column">
                    {section.items.map(item => (
                      <Link
                        key={item.path}
                        as={RouterLink}
                        to={item.path}
                        p={4}
                        fontWeight={
                          item.path === location.pathname
                            ? 'semibold'
                            : 'normal'
                        }
                        color={
                          item.path === location.pathname
                            ? 'blue.500'
                            : 'inherit'
                        }
                        bg={
                          item.path === location.pathname
                            ? 'blue.50'
                            : 'inherit'
                        }
                      >
                        <Text display="inline-block" position="relative">
                          {item.label}
                          {item.path === '/offlinestore' && count ? (
                            <Flex
                              as="span"
                              bg="red.400"
                              color="white"
                              position="absolute"
                              top="0"
                              right="-24px"
                              borderRadius="50%"
                              boxSize="20px"
                              justify="center"
                              align="center"
                            >
                              <Text as="span" fontSize="xs">
                                {count}
                              </Text>
                            </Flex>
                          ) : null}
                        </Text>
                      </Link>
                    ))}
                  </Flex>
                </AccordionPanel>
              </AccordionItem>
            ))}
        </Accordion>
      </Flex>

      <Flex gap={4} direction="column">
        <Button variant="outline" colorScheme="red" onClick={onEndOfShift}>
          End of shift
        </Button>
        <Button variant="outline" onClick={onLogout}>
          Logout
        </Button>
      </Flex>
    </Flex>
  )
}

export default MobileNavbar
