import {useAuthQuery} from './useAuthQuery'

const KEYS = {
  types: ['incident-types'],
}

const useIncidentTypes = ({enabled = false} = {}) => {
  const typeState = useAuthQuery({
    keys: KEYS.types,
    endpoint: '/incidentreporttype',
    queryOptions: {
      enabled,
    },
  })

  return typeState
}

export {useIncidentTypes}
