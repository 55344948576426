import {
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Checkbox,
  Textarea,
  useToast,
  Input,
} from '@chakra-ui/react'
import * as React from 'react'
import {Controller, useForm} from 'react-hook-form'
import {Select} from '../Select'
import {FilesInput} from '../Commons'
import {useUserProperties} from '../../context/property'
import {usePosition} from '../../context/position'
import {getFullDateToDb, imagesRgx} from '../../lib/form'
import {getDefaultDarValues} from '../../lib/form/dars'
import {useAuth} from '../../context/auth'

const DarForm = function ({dar, types, codes, isSubmiting, onSubmit}) {
  const {user} = useAuth()
  const {getCoordinates} = usePosition()
  const {currentProperty} = useUserProperties()
  const [files, setFiles] = React.useState(dar?.thumbImages ?? [])
  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: {errors},
  } = useForm({
    defaultValues: getDefaultDarValues({dar, types, codes}),
  })
  const toast = useToast({
    position: 'top',
    isClosable: true,
  })

  const handleFiles = selectedFiles => {
    setFiles(selectedFiles)
  }

  const handleFormSubmit = async values => {
    try {
      const {propertyId} = currentProperty
      const {latitude, longitude} = await getCoordinates()
      const gpsCoordinates = `${latitude},${longitude}`
      const images = files.filter(file => file.startsWith('data:image/'))
      let imagesDel

      if (dar?.thumbImages) {
        imagesDel = dar.thumbImages.filter(i => !files.find(f => f === i))
      }

      const darData = {
        ...values,
        propertyId,
        images,
        imagesDel,
        gpsCoordinates,
        sendNotification: Number(values.sendNotification) || 0,
        includeGpsCoordinates: Number(values.includeGpsCoordinates) || 0,
        dailyActivityReportTypeId: values.dailyActivityReportTypeId.value,
        dailyActivityReportCodeId: values.dailyActivityReportCodeId.value,
      }

      if (values.date) {
        darData.darDate = getFullDateToDb(new Date(values.date))
      }

      onSubmit(darData)
    } catch (e) {
      toast({
        title: 'Oops, there is some error in your data',
      })
    }
  }

  React.useEffect(
    () => () => {
      reset()
      setFiles([])
    },
    [reset]
  )

  return (
    <form id="dar-form" onSubmit={handleSubmit(handleFormSubmit)}>
      <Flex gap={2} mb={2}>
        <FormControl
          flexBasis="50%"
          isInvalid={errors?.dailyActivityReportTypeId}
          isDisabled={isSubmiting}
        >
          <FormLabel>Type</FormLabel>
          <Controller
            control={control}
            name="dailyActivityReportTypeId"
            rules={{
              validate: ({value} = {}) =>
                value !== undefined || 'You must select a DAR type',
              valueAsNumber: true,
            }}
            render={({field}) => (
              <Select
                isClearable
                isDisabled={isSubmiting}
                placeholder="Select a type"
                options={types}
                {...field}
              />
            )}
          />
          {errors?.dailyActivityReportTypeId ? (
            <FormErrorMessage>
              {errors.dailyActivityReportTypeId.message}
            </FormErrorMessage>
          ) : null}
        </FormControl>
        <FormControl
          flexBasis="50%"
          isInvalid={errors?.dailyActivityReportCodeId}
          isDisabled={isSubmiting}
        >
          <FormLabel>Code</FormLabel>
          <Controller
            control={control}
            name="dailyActivityReportCodeId"
            rules={{
              validate: ({value} = {}) =>
                value !== undefined || 'You must select a code',
              valueAsNumber: true,
            }}
            render={({field}) => (
              <Select
                isClearable
                isDisabled={isSubmiting}
                placeholder="Select a code"
                options={codes}
                {...field}
              />
            )}
          />
          {errors?.dailyActivityReportCodeId ? (
            <FormErrorMessage>
              {errors.dailyActivityReportCodeId.message}
            </FormErrorMessage>
          ) : null}
        </FormControl>
      </Flex>

      {user.isAdmin ? (
        <FormControl mb={2}>
          <FormLabel>Date</FormLabel>
          <Input type="date" isDisabled={isSubmiting} {...register('date')} />
        </FormControl>
      ) : null}

      <FormControl mb={8} isInvalid={errors?.details} isDisabled={isSubmiting}>
        <FormLabel>Details</FormLabel>
        <Textarea
          resize="none"
          isDisabled={isSubmiting}
          {...register('details', {required: 'This field is required'})}
        />
        {errors?.details ? (
          <FormErrorMessage>{errors.details.message}</FormErrorMessage>
        ) : null}
      </FormControl>

      <FilesInput
        files={files}
        onChange={handleFiles}
        validFileExtensionsReg={imagesRgx}
        extensionErrorMessage="Wrong file extensions. Please select a jpeg, png or bmp file."
      />

      <FormControl mb={2} isDisabled={isSubmiting}>
        <FormLabel>Send notification to prop. manager</FormLabel>
        <Checkbox
          isDisabled={isSubmiting}
          borderColor="gray.400"
          {...register('sendNotification', {valueAsNumber: true})}
        />
      </FormControl>
      <FormControl mb={2} isDisabled={isSubmiting}>
        <FormLabel>Include GPS coordinates</FormLabel>
        <Checkbox
          isDisabled={isSubmiting}
          borderColor="gray.400"
          {...register('includeGpsCoordinates', {valueAsNumber: true})}
        />
      </FormControl>
    </form>
  )
}

export default DarForm
