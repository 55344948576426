export const COLORS = {
  primary: 'hsl(213deg, 50%, 65%)',
  secondary: 'hsl(242deg, 30%, 60%)',
  white: 'hsl(0deg, 0%, 100%)',
  bg: 'hsl(210deg, 5%, 97%)',
  gray: {
    100: 'hsl(210deg, 5%, 95%)',
    200: 'hsl(215deg, 5%, 90%)',
    300: 'hsl(215deg, 5%, 80%)',
    500: 'hsl(240deg, 4%, 60%)',
    700: 'hsl(2600deg, 5%, 40%)',
    900: 'hsl(260deg, 3%, 20%)',
  },
  error: 'hsl(0deg, 50%, 60%)',
}

export const BREAKPOINTS = {
  phone: 600,
  tablet: 951,
  laptop: 1301,
}

export const QUERIES = {
  tabletAndBigger: `(min-width: ${BREAKPOINTS.tablet / 16}rem)`,
  laptopAndBigger: `(min-width: ${BREAKPOINTS.laptop / 16}rem)`,
}
