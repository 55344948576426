import {getEntitiesWithActions} from '../../lib/entities'

const vehiclePermissionsList = ['vehicle list', 'vehicle show']
const vehiclePermissionsCreate = ['vehicle create', 'vehicle store']
const vehiclePermissionsEdit = ['vehicle update']
const vehiclePermissionsDelete = ['vehicle delete']

const sortFields = [
  {
    key: 'user_name',
    dir: 'desc',
    label: 'By username Descending ',
  },
  {
    key: 'user_name',
    dir: 'asc',
    label: 'By username Ascending ',
  },
  {
    key: 'license_plate',
    dir: 'desc',
    label: 'By lic. plate Descending',
  },
  {
    key: 'license_plate',
    dir: 'asc',
    label: 'By lic. plate Ascending',
  },
  {
    key: 'make',
    dir: 'desc',
    label: 'By make Descending',
  },
  {
    key: 'make',
    dir: 'asc',
    label: 'By make Ascending',
  },
  {
    key: 'model',
    dir: 'desc',
    label: 'By model Descending',
  },
  {
    key: 'model',
    dir: 'asc',
    label: 'By model Ascending',
  },
  {
    key: 'color',
    dir: 'desc',
    label: 'By color Descending',
  },
  {
    key: 'color',
    dir: 'asc',
    label: 'By color Ascending',
  },
  {
    key: 'state',
    dir: 'desc',
    label: 'By state Descending',
  },
  {
    key: 'state',
    dir: 'asc',
    label: 'By state Ascending',
  },
]

const getVehiclesWithActions = (vehicles, user) =>
  getEntitiesWithActions(
    vehicles,
    user,
    vehiclePermissionsEdit,
    vehiclePermissionsDelete
  )

export {
  vehiclePermissionsList,
  vehiclePermissionsCreate,
  vehiclePermissionsEdit,
  vehiclePermissionsDelete,
  getVehiclesWithActions,
  sortFields,
}
