import {
  Box,
  Flex,
  Text,
  Button,
  Icon,
  List,
  ListItem,
  useBreakpointValue,
} from '@chakra-ui/react'
import {MdModeEdit, MdDelete} from 'react-icons/md'
import {HiChevronDown, HiChevronUp} from 'react-icons/hi'

const PropertyDesktopTable = ({properties, sort, onSort, onEdit, onDelete}) => {
  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  })

  if (isMobile) {
    return null
  }

  const SortIcon =
    sort?.dir === 'desc' ? (
      <Icon as={HiChevronDown} />
    ) : (
      <Icon as={HiChevronUp} />
    )

  return (
    <List boxShadow="lg" fontSize="xs">
      <ListItem borderBottom="1px solid" borderColor="gray.300">
        <Flex>
          <Flex
            pl={4}
            pr={0}
            py={2}
            flex="1"
            flexBasis="90%"
            fontWeight="bold"
            align="center"
          >
            <Button
              flexBasis="25%"
              variant="link"
              justifyContent="flex-start"
              rightIcon={sort?.field === 'name' ? SortIcon : null}
              onClick={() => onSort && onSort('name')}
            >
              <Text color="gray.800" fontSize="sm">
                Name
              </Text>
            </Button>
            <Button
              flexBasis="25%"
              variant="link"
              justifyContent="flex-start"
              rightIcon={sort?.field === 'address' ? SortIcon : null}
              onClick={() => onSort && onSort('address')}
            >
              <Text color="gray.800" fontSize="sm">
                Address
              </Text>
            </Button>
            <Box flexBasis="25%" variant="link" justifyContent="flex-start">
              <Text color="gray.800" fontSize="sm" fontWeight="semibold">
                Contact name
              </Text>
            </Box>
            <Box flexBasis="25%" variant="link" justifyContent="flex-start">
              <Text color="gray.800" fontSize="sm" fontWeight="semibold">
                Email
              </Text>
            </Box>
          </Flex>
          <Box flexBasis="15%" py={2} pr={4} />
        </Flex>
      </ListItem>
      {properties.map(property => (
        <ListItem
          key={property.propertyId}
          transitionProperty="var(--chakra-transition-property-common)"
          transitionDuration="var(--chakra-transition-duration-normal)"
          _even={{
            bg: 'gray.100',
          }}
          _hover={{
            bg: 'blue.50',
          }}
        >
          <Flex>
            <Flex
              w="100%"
              h="100%"
              minH="60px"
              pl={4}
              pr={0}
              py={2}
              flex="1"
              flexBasis="90%"
              fontSize="xs"
              color="gray.700"
            >
              <Flex align="center" w="100%">
                <Text flexBasis="25%" whiteSpace="pre-wrap">
                  {property.name}
                </Text>
                <Text flexBasis="25%" whiteSpace="pre-wrap">
                  {property.number} {property.street}
                </Text>
                <Text flexBasis="25%" whiteSpace="pre-wrap">
                  {property.contactName}
                </Text>
                <Text flexBasis="25%" whiteSpace="pre-wrap">
                  {property.email}
                </Text>
              </Flex>
            </Flex>
            <Flex justify="center" align="center" flexBasis="15%" py={2} pr={4}>
              {property.canEdit || property.canDelete ? (
                <Flex gap={2}>
                  {property.canEdit ? (
                    <Button
                      variant="ghost"
                      onClick={() => onEdit(property.propertyId)}
                    >
                      <Icon as={MdModeEdit} w={6} h={6} />
                    </Button>
                  ) : null}
                  {property.canDelete ? (
                    <Button
                      variant="ghost"
                      colorScheme="red"
                      onClick={() => onDelete(property.propertyId)}
                    >
                      <Icon as={MdDelete} w={6} h={6} />
                    </Button>
                  ) : null}
                </Flex>
              ) : null}
            </Flex>
          </Flex>
        </ListItem>
      ))}
    </List>
  )
}

export default PropertyDesktopTable
