import PropertiesProvider from '../../context/property'
import Authenticated from './Authenticated'

const AuthenticatedProviders = () => (
  <PropertiesProvider>
    <Authenticated />
  </PropertiesProvider>
)

export default AuthenticatedProviders
