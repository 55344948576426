import {useAuthQuery} from './useAuthQuery'

const KEYS = {
  types: ['violation-types'],
}

const useViolationTypes = ({enabled = false}) => {
  const queryState = useAuthQuery({
    keys: KEYS.types,
    endpoint: '/violationtype',
    queryOptions: {
      enabled,
    },
  })

  return queryState
}

export {useViolationTypes}
