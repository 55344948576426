import {Flex, Text, Alert, AlertIcon} from '@chakra-ui/react'
import * as React from 'react'
import {SchedulesCalendar, ImportWeekModal} from '../components/Schedules'
import {PagePlaceholder} from '../components/Placeholder'
import Acl from '../components/Acl'
import CreateEntityButton from '../components/CreateEntityButton/CreateEntityButton'
import SchedulesFormModal from '../components/Schedules/SchedulesFormModal'
import WeekPicker from '../components/Schedules/WeekPicker'
import {useSchedules} from '../hooks/schedules'
import {ENTITIES, ENTITIES_NAMES} from '../lib/entities'
import {
  getCurrentWeek,
  getDefaultWeek,
  getScheduleFilters,
} from '../lib/form/schedules'
import {
  getDatesOfWeek,
  schedulePermissionsCreate,
  schedulePermissionsList,
} from '../components/Schedules/Schedules.helpers'

const Schedules = () => {
  const [currentWeek, setCurrentWeek] = React.useState({
    week: getDefaultWeek(),
    dates: getCurrentWeek(new Date()),
  })

  const filters = React.useMemo(
    () => getScheduleFilters(currentWeek),
    [currentWeek]
  )

  const {data, isLoading} = useSchedules({
    entity: ENTITIES.officerSchedules,
    filters,
  })

  const handleWeekChange = week => {
    const dates = getDatesOfWeek(week)
    setCurrentWeek({
      week: week[1],
      dates,
    })
  }

  if (isLoading) {
    return <PagePlaceholder />
  }

  return (
    <Flex gap={4} direction="column">
      <Flex align="flex-end" gap={2}>
        <Flex direction="column">
          <Text fontSize="xl" fontWeight="bold">
            Officers schedules
          </Text>
          <Alert status="info" rounded="sm" fontSize="xs" p={2}>
            <AlertIcon />
            This section is not being filtered by the current property
          </Alert>
        </Flex>
        <Flex
          gap={2}
          ml="auto"
          align="center"
          direction={{
            base: 'column',
            lg: 'row',
          }}
        >
          <WeekPicker
            handleWeekChange={handleWeekChange}
            currentWeek={currentWeek.week}
          />
          <ImportWeekModal filters={filters} />
          <CreateEntityButton
            entity={ENTITIES.officerSchedules}
            entityName={ENTITIES_NAMES.officerSchedules}
            permissionCreate={schedulePermissionsCreate}
            filters={filters}
            formModal={SchedulesFormModal}
          />
        </Flex>
      </Flex>
      <SchedulesCalendar
        items={data?.officerSchedule}
        filters={filters}
        currentWeek={currentWeek}
      />
    </Flex>
  )
}

const ProtectedSchedules = () => (
  <Acl withRedirect requiredPermissions={schedulePermissionsList}>
    <Schedules />
  </Acl>
)

export default ProtectedSchedules
