import {Flex, Text, useToast} from '@chakra-ui/react'
import * as React from 'react'
import {useAuth} from '../../../context/auth'
import Paginator from '../../Paginator'
import DeleteModal from '../../DeleteModal'
import ViolationsDesktopTable from './ViolationsDesktopTable'
import ViolationsMobileTable from './ViolationsMobileTable'
import ViolationFormModal from '../ViolationFormModal'
import ViolationDetails from '../ViolationDetails'
import {getViolationsWithActions} from '../Violations.helpers'
import {usePatch} from '../../../hooks/entities'
import {isObjectEmpty} from '../../../lib/objects'
import {ENTITIES, ENTITIES_KEYS} from '../../../lib/entities'
import {MODAL_KEYS} from '../../../lib/modal'

const ViolationsTable = ({
  items,
  sort,
  itemsTotal,
  pages,
  pagesCount,
  currentPage,
  filters,
  onSort,
  onPageChange,
}) => {
  const [violationId, setViolationId] = React.useState()
  const [activeModal, setActiveModal] = React.useState()
  const {user} = useAuth()
  const {mutate, isLoading} = usePatch({
    id: violationId,
    entity: ENTITIES.violations,
    currentPage,
    filters,
    sort,
  })
  const toast = useToast({
    position: 'top',
    isClosable: true,
  })

  const handleDetails = id => {
    setViolationId(id)
    setActiveModal(MODAL_KEYS.details)
  }

  const handleEdit = id => {
    setViolationId(id)
    setActiveModal(MODAL_KEYS.edit)
  }

  const handleDelete = id => {
    setViolationId(id)
    setActiveModal(MODAL_KEYS.delete)
  }

  const handleModalClose = () => {
    setViolationId(null)
    setActiveModal(null)
  }

  const handleUpdate = violationdata => {
    mutate(
      {
        ...violationdata,
        [ENTITIES_KEYS.violations]: violationId,
      },
      {
        onSuccess() {
          toast({
            title: 'Violation updated!',
            status: 'success',
          })
          handleModalClose()
        },
        onError() {
          toast({
            title: 'Error updating Violation',
            status: 'error',
          })
        },
      }
    )
  }

  const violations = getViolationsWithActions(items, user)

  if (!violations?.length && isObjectEmpty(filters)) {
    return (
      <Flex justify="center" boxShadow="lg" p={4}>
        <Text>No data found for this section</Text>
      </Flex>
    )
  }

  if (!violations?.length && !isObjectEmpty(filters)) {
    return (
      <Flex justify="center" boxShadow="lg" p={4}>
        <Text>No data found for the filters applied</Text>
      </Flex>
    )
  }

  return (
    <>
      <ViolationsMobileTable
        violations={violations}
        onDetails={handleDetails}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
      <ViolationsDesktopTable
        violations={violations}
        sort={sort}
        onSort={onSort}
        onDetails={handleDetails}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />

      <Paginator
        itemsTotal={itemsTotal}
        pages={pages}
        pagesCount={pagesCount}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />

      {activeModal === MODAL_KEYS.details ? (
        <ViolationDetails
          violationId={violationId}
          isOpen={activeModal === MODAL_KEYS.details}
          onClose={handleModalClose}
        />
      ) : null}

      {activeModal === MODAL_KEYS.edit ? (
        <ViolationFormModal
          violationId={violationId}
          isOpen={activeModal === MODAL_KEYS.edit}
          isSubmiting={isLoading}
          onClose={handleModalClose}
          onSubmit={handleUpdate}
        />
      ) : null}

      {activeModal === MODAL_KEYS.delete ? (
        <DeleteModal
          id={violationId}
          sort={sort}
          entityName="Violation"
          filters={filters}
          entity={ENTITIES.violations}
          currentPage={currentPage}
          entityKey={ENTITIES_KEYS.violations}
          isOpen={activeModal === MODAL_KEYS.delete}
          onClose={handleModalClose}
        />
      ) : null}
    </>
  )
}

export default ViolationsTable
