import * as React from 'react'
import {useAuthMutation, useAuthQuery} from './useAuthQuery'
import {formatQueryParams} from '../lib/formatters'

const useSchedules = ({entity, filters}) => {
  const queryParams = formatQueryParams(filters)
  let endpoint = `/${entity}/list`

  if (queryParams) {
    endpoint += `?${queryParams}`
  }

  const keys = React.useMemo(
    () => [entity, JSON.stringify(filters)],
    [entity, filters]
  )

  const queryState = useAuthQuery({
    keys,
    endpoint,
    queryOptions: {includeProperty: false},
  })

  return queryState
}

const useImportWeek = ({entity, filters}) => {
  const keys = React.useMemo(
    () => [entity, JSON.stringify(filters)],
    [entity, filters]
  )

  const mutationState = useAuthMutation({
    endpoint: '/officerschedule/copy',
    invalidationKeys: keys,
  })

  return mutationState
}

export {useSchedules, useImportWeek}
