import {
  Flex,
  Textarea,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast,
  Checkbox,
  Input,
} from '@chakra-ui/react'
import * as React from 'react'
import {Controller, useForm} from 'react-hook-form'
import PlateForm from '../PlateForm'
import {Select} from '../Select'
import {FilesInput} from '../Commons'
import {useUserProperties} from '../../context/property'
import {getDefaultValues} from '../../lib/form/violations'
import {getFullDateToDb, imagesRgx} from '../../lib/form'
import {usePosition} from '../../context/position'
import {useAuth} from '../../context/auth'

const ViolationForm = ({
  violation,
  types,
  codes,
  tows,
  isSubmiting,
  onSubmit,
}) => {
  const {user} = useAuth()
  const {
    register,
    control,
    reset,
    resetField,
    watch,
    handleSubmit,
    formState: {errors},
    setValue,
  } = useForm({
    defaultValues: getDefaultValues({violation, types, codes, tows}),
  })
  const toast = useToast({
    position: 'top',
    isClosable: true,
  })
  const {getCoordinates} = usePosition()
  const {currentProperty} = useUserProperties()
  const [isNewVehicle, setIsNewVehicle] = React.useState(false)
  const [vehicles, setVehicles] = React.useState([])
  const [files, setFiles] = React.useState(violation?.media?.thumbImages ?? [])

  const vehicleIdWatch = watch('vehicleId')

  const handleFormSubmit = async values => {
    try {
      const {latitude, longitude} = await getCoordinates()
      const gpsCoordinates = `${latitude},${longitude}`
      const {propertyId} = currentProperty
      const {
        violationTypeId: {value: violationTypeId} = {},
        towCompanyId: {value: towCompanyId} = {},
        vehicleId: {value: vehicleId} = {},
        dailyActivityReportCodeId: {value: dailyActivityReportCodeId} = {},
        details,
        sendNotification,
      } = values

      const images = files.filter(file => file.startsWith('data:image/'))

      let violationData = {
        propertyId,
        violationTypeId,
        vehicleId,
        towCompanyId,
        dailyActivityReportCodeId,
        details,
        images,
        gpsCoordinates,
        sendNotification: Number(sendNotification) || 0,
      }

      if (!vehicleId) {
        const {licensePlate, make, model, color, state} = values
        violationData = {
          ...violationData,
          licensePlate,
          make,
          model,
          color,
          state,
        }
      }

      if (values.date) {
        violationData.violationDate = getFullDateToDb(new Date(values.date))
      }

      onSubmit(violationData)
    } catch (e) {
      toast({
        title: 'Oops, there is some error in your data',
      })
    }
  }

  React.useEffect(
    () => () => {
      reset()
    },
    [reset]
  )

  React.useEffect(() => {
    if (isNewVehicle) {
      resetField('vehicleId')
    }
  }, [isNewVehicle, resetField])

  const handleNewVehicle = e => {
    e.preventDefault()
    setIsNewVehicle(state => !state)
  }

  const handleVehicles = React.useCallback(newVehicles => {
    setVehicles(newVehicles)
  }, [])

  const handleFiles = selectedFiles => {
    setFiles(selectedFiles)
  }

  const vehicle = vehicleIdWatch
    ? vehicles.find(v => v.vehicleId === vehicleIdWatch.value)
    : null
  const showTowCompanies =
    tows.length &&
    ((vehicle && !vehicle.violationsLeft) ||
      (violation && violation.towCompanyId))

  return (
    <form id="violation-form" onSubmit={handleSubmit(handleFormSubmit)}>
      <PlateForm
        plateEdit={violation}
        register={register}
        control={control}
        setValue={setValue}
        errors={errors}
        isNewVehicle={isNewVehicle}
        handleNewVehicle={handleNewVehicle}
        handleVehicles={handleVehicles}
      />
      {vehicleIdWatch || isNewVehicle || violation ? (
        <>
          <Flex mb={2} gap={2}>
            <FormControl
              isInvalid={Boolean(errors.violationTypeId?.message)}
              isDisabled={isSubmiting}
            >
              <FormLabel>Violation Type</FormLabel>
              <Controller
                control={control}
                name="violationTypeId"
                rules={{
                  validate: ({value} = {}) =>
                    value !== undefined || 'You must select a violation type',
                  valueAsNumber: true,
                }}
                render={({field}) => (
                  <Select
                    isClearable
                    placeholder="Select a violation"
                    options={types}
                    isDisabled={isSubmiting}
                    {...field}
                  />
                )}
              />
              {errors.violationTypeId?.message ? (
                <FormErrorMessage>
                  {errors.violationTypeId.message}
                </FormErrorMessage>
              ) : null}
            </FormControl>

            <FormControl
              isInvalid={Boolean(errors.dailyActivityReportCodeId?.message)}
              isDisabled={isSubmiting}
            >
              <FormLabel>Code</FormLabel>
              <Controller
                control={control}
                name="dailyActivityReportCodeId"
                rules={{
                  validate: ({value} = {}) =>
                    value !== undefined || 'You must select a code',
                  valueAsNumber: true,
                }}
                render={({field}) => (
                  <Select
                    isClearable
                    placeholder="Select a code"
                    options={codes}
                    isDisabled={isSubmiting}
                    {...field}
                  />
                )}
              />
              {errors.dailyActivityReportCodeId?.message ? (
                <FormErrorMessage>
                  {errors.dailyActivityReportCodeId.message}
                </FormErrorMessage>
              ) : null}
            </FormControl>
          </Flex>

          {user.isAdmin ? (
            <FormControl mb={2}>
              <FormLabel>Date</FormLabel>
              <Input
                type="date"
                isDisabled={isSubmiting}
                {...register('date')}
              />
            </FormControl>
          ) : null}

          {showTowCompanies ? (
            <FormControl
              mb={2}
              isInvalid={errors.towCompanyId?.message}
              isDisabled={isSubmiting}
            >
              <FormLabel>Tow company</FormLabel>
              <Controller
                control={control}
                name="towCompanyId"
                rules={{
                  validate: value =>
                    value !== undefined || 'You must select a tow company',
                }}
                render={({field}) => (
                  <Select
                    isClearable
                    placeholder="Select a tow company"
                    options={tows}
                    isDisabled={isSubmiting}
                    {...field}
                  />
                )}
              />
              {errors.towCompanyId?.message ? (
                <FormErrorMessage>
                  {errors.towCompanyId.message}
                </FormErrorMessage>
              ) : null}
            </FormControl>
          ) : null}

          <FormControl mb={4} isDisabled={isSubmiting}>
            <FormLabel>Details</FormLabel>
            <Textarea isDisabled={isSubmiting} {...register('details')} />
          </FormControl>

          <FilesInput
            files={files}
            onChange={handleFiles}
            validFileExtensionsReg={imagesRgx}
            extensionErrorMessage="Wrong file extensions. Please select a jpeg, png or bmp file."
          />

          <FormControl mb={2} isDisabled={isSubmiting}>
            <FormLabel>Send notification to prop. manager</FormLabel>
            <Checkbox
              borderColor="gray.400"
              isDisabled={isSubmiting}
              {...register('sendNotification', {
                setValueAs: value => (value ? 1 : 0),
              })}
            />
          </FormControl>
        </>
      ) : null}
    </form>
  )
}

export default ViolationForm
