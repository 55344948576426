import {
  Box,
  Flex,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  useBreakpointValue,
} from '@chakra-ui/react'
import {formatDate} from '../../../lib/formatters'
import ViolationsMobileItem from './ViolationsMobileItem'

const ViolationsMobileTable = ({violations, onDetails, onEdit, onDelete}) => {
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  })

  if (isDesktop) {
    return null
  }

  return (
    <Box
      boxShadow="lg"
      display={{
        lg: 'none',
      }}
    >
      <Flex fontSize="sm" px={4} py={2}>
        <Flex gap={2} flex="1">
          <Text fontWeight="bold" flexBasis="50%">
            Date
          </Text>
          <Text fontWeight="bold" flexBasis="50%">
            Lic. plate
          </Text>
          <Box boxSize="1rem" />
        </Flex>
      </Flex>
      <Accordion allowToggle>
        {violations.map(violation => (
          <AccordionItem key={violation.vehicleViolationId}>
            {({isExpanded}) => (
              <>
                <AccordionButton fontSize="sm">
                  <Flex flex="1" textAlign="start" gap={2} align="center">
                    <Text flexBasis="50%">
                      {formatDate(violation.createdAt, {
                        isUTC: false,
                        withHours: false,
                      })}
                    </Text>
                    <Box flexBasis="50%">{violation.licensePlate}</Box>
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel
                  fontSize="sm"
                  position="relative"
                  overflow="visible"
                >
                  <ViolationsMobileItem
                    isExpanded={isExpanded}
                    violationId={violation.vehicleViolationId}
                    canEdit={violation.canEdit}
                    canDelete={violation.canDelete}
                    onDetails={onDetails}
                    onEdit={onEdit}
                    onDelete={onDelete}
                  />
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  )
}

export default ViolationsMobileTable
