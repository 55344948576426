import {Box, useToken} from '@chakra-ui/react'
import * as React from 'react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import {getControllerProps} from '../../lib/form'

const customStyles = {
  control: provided => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
  }),
}

const CustomSelect = React.forwardRef(
  ({styles = {}, hasError, isDisabled, ...props}, ref) => {
    const [red500, blue500, gray300] = useToken('colors', [
      'red.500',
      'blue.500',
      'gray.300',
    ])

    const errorState = getControllerProps(isDisabled, hasError, red500)

    return (
      <Box
        border="1px solid"
        borderColor={errorState.border}
        borderRadius="md"
        boxShadow={errorState.shadow}
        transitionProperty="var(--chakra-transition-property-common)"
        transitionDuration="var(--chakra-transition-duration-normal)"
        _hover={{
          borderColor: gray300,
        }}
        _focusWithin={{
          borderColor: blue500,
          boxShadow: `0 0 0 1px ${blue500}`,
        }}
      >
        <Select
          isDisabled={isDisabled}
          {...props}
          styles={{cursor: 'pointer', ...customStyles, ...styles}}
          ref={ref}
        />
      </Box>
    )
  }
)
CustomSelect.displayName = Select

const CustomAsyncSelect = React.forwardRef(
  ({styles = {}, hasError, isDisabled, ...props}, ref) => {
    const [red500, blue500, gray300] = useToken('colors', [
      'red.500',
      'blue.500',
      'gray.300',
    ])

    const errorState = getControllerProps(isDisabled, hasError, red500)

    return (
      <Box
        border="1px solid"
        borderColor={errorState.border}
        borderRadius="md"
        boxShadow={errorState.shadow}
        transitionProperty="var(--chakra-transition-property-common)"
        transitionDuration="var(--chakra-transition-duration-normal)"
        _hover={{
          borderColor: gray300,
        }}
        _focusWithin={{
          borderColor: blue500,
          boxShadow: `0 0 0 1px ${blue500}`,
        }}
      >
        <AsyncSelect
          isDisabled={isDisabled}
          {...props}
          styles={{...customStyles, ...styles}}
          ref={ref}
        />
      </Box>
    )
  }
)
CustomAsyncSelect.displayName = AsyncSelect

export {CustomSelect as Select, CustomAsyncSelect as AsyncSelect}
