import {
  Box,
  Flex,
  Icon,
  Button,
  Text,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react'
import * as React from 'react'
import {MdAdd} from 'react-icons/md'
import {Controller} from 'react-hook-form'
import {AsyncSelect} from '../Select'
import {useVehicleByCriteria} from '../../hooks/vehicles'

const PlateForm = ({
  plateEdit,
  householdId,
  register,
  control,
  setValue,
  errors,
  isNewVehicle,
  handleNewVehicle,
  handleVehicles,
}) => {
  const [plate, setPlate] = React.useState()
  const {vehicles, handleLoadOptions} = useVehicleByCriteria(householdId)

  React.useEffect(() => {
    if (vehicles) {
      handleVehicles(vehicles)
    }
  }, [handleVehicles, vehicles])

  React.useEffect(() => {
    if (isNewVehicle && plate) {
      setValue('licensePlate', plate)
    }
  }, [isNewVehicle, setValue, plate])

  const handleChange = (input, callback) => {
    setPlate(input)
    handleLoadOptions(input, callback)
  }

  const isAddVehicleButtonDisabled =
    !vehicles ||
    vehicles?.length ||
    (plateEdit && !plateEdit?.vehicleId && !plateEdit?.vehicle?.vehicleId)

  return (
    <>
      <Flex gap={4} mb={4} align="flex-end">
        <FormControl
          flexBasis="90%"
          isInvalid={Boolean(errors?.vehicleId?.message)}
        >
          <FormLabel>License Plate</FormLabel>
          {vehicles?.length <= 0 ? (
            <Text fontSize="sm" fontWeight="light" mb={2}>
              If the you can't find the license plate, press the '+' to register
              a new vehicle
            </Text>
          ) : null}
          <Controller
            control={control}
            name="vehicleId"
            rules={{
              validate: value =>
                Boolean(value) ||
                isNewVehicle ||
                'You must enter a license plate',
            }}
            render={({field}) => (
              <AsyncSelect
                isClearable
                isSearchable
                cacheOptions
                placeholder="Select a license plate"
                loadOptions={handleChange}
                isDisabled={isNewVehicle || plateEdit}
                {...field}
              />
            )}
          />
          {errors?.vehicleId?.message ? (
            <FormErrorMessage>{errors.vehicleId.message}</FormErrorMessage>
          ) : null}
        </FormControl>
        <Box flexBasis="10%">
          <Button
            border="1px solid"
            borderColor="gray.400"
            title="Add a new vehicle"
            isDisabled={isAddVehicleButtonDisabled}
            onClick={handleNewVehicle}
          >
            <Icon as={MdAdd} w={6} h={6} />
          </Button>
        </Box>
      </Flex>
      {isNewVehicle ||
      (plateEdit && !plateEdit?.vehicleId && !plateEdit?.vehicle?.vehicleId) ? (
        <>
          <FormControl
            mb={4}
            isInvalid={Boolean(errors?.licensePlate?.message)}
          >
            <FormLabel>License plate</FormLabel>
            <Input
              type="text"
              defaultValue={plateEdit?.licensePlate}
              {...register('licensePlate', {
                validate: value => Boolean(value) || 'This field is required',
              })}
            />
            {errors?.licensePlate?.message ? (
              <FormErrorMessage>{errors.licensePlate.message}</FormErrorMessage>
            ) : null}
          </FormControl>
          <Flex gap={4} mb={4}>
            <FormControl
              flexBasis="50%"
              isInvalid={Boolean(errors?.make?.message)}
            >
              <FormLabel>Make</FormLabel>
              <Input
                type="text"
                defaultValue={plateEdit?.make}
                {...register('make', {
                  required: 'This field is required',
                })}
              />
              {errors?.make?.message ? (
                <FormErrorMessage>{errors.make.message}</FormErrorMessage>
              ) : null}
            </FormControl>
            <FormControl
              flexBasis="50%"
              isInvalid={Boolean(errors?.model?.message)}
            >
              <FormLabel>Model</FormLabel>
              <Input
                type="text"
                defaultValue={plateEdit?.model}
                {...register('model', {
                  required: 'This field is required',
                })}
              />
              {errors?.model?.message ? (
                <FormErrorMessage>{errors.model.message}</FormErrorMessage>
              ) : null}
            </FormControl>
          </Flex>
          <Flex gap={4} mb={4}>
            <FormControl
              flexBasis="50%"
              isInvalid={Boolean(errors?.color?.message)}
            >
              <FormLabel>Color</FormLabel>
              <Input
                type="text"
                defaultValue={plateEdit?.color}
                {...register('color', {
                  required: 'This field is required',
                })}
              />
              {errors?.color?.message ? (
                <FormErrorMessage>{errors.color.message}</FormErrorMessage>
              ) : null}
            </FormControl>
            <FormControl
              flexBasis="50%"
              isInvalid={Boolean(errors?.state?.message)}
            >
              <FormLabel>State</FormLabel>
              <Input
                type="text"
                defaultValue={plateEdit?.state}
                {...register('state', {
                  required: 'This field is required',
                })}
              />
              {errors?.state?.message ? (
                <FormErrorMessage>{errors.state.message}</FormErrorMessage>
              ) : null}
            </FormControl>
          </Flex>
        </>
      ) : null}
    </>
  )
}

export default PlateForm
