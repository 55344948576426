import {ENTITIES, ENTITIES_NAMES} from './entities'
import {formatDate} from './formatters'

const STORE_KEY = 'starproaccess:offline_store'

const STORE_STATUS = {
  pending: 'Pending',
  error: 'Error',
}

const getTempId = (store, type) => {
  const itemCount = !store[type] ? 1 : store[type].length + 1
  return `${type}-${itemCount}`
}

const getItemDate = item => {
  let date
  switch (item.type) {
    case ENTITIES_NAMES.dar:
      date = item.data.darDate
      break
    case ENTITIES_NAMES.violations:
      date = item.data.violationDate
      break
    case ENTITIES_NAMES.incidents:
      date = item.data.incidentDate
      break
    case ENTITIES_NAMES.poolreports:
      date = item.data.poolReportdate
      break
    default:
      return '-'
  }

  return formatDate(date)
}

const getItemType = type => {
  const itemKey = Object.keys(ENTITIES).find(key => ENTITIES[key] === type)

  return ENTITIES_NAMES[itemKey]
}

const getItemDetail = (
  item,
  vehiclesData,
  darTypesData,
  violationTypesData,
  incidentTypesData
) => {
  switch (item.type) {
    case ENTITIES.dar:
      const {type: darType} = darTypesData.dailyActivityReportTypes.find(
        t => (t.dailyActivityReportTypeId = item.data.dailyActivityReportTypeId)
      )
      return `${darType} - ${item.data.details}`
    case ENTITIES.violations:
      const {licensePlate} = vehiclesData.vehicles.find(
        v => v.vehicleId === item.data.vehicleId
      )
      const {type: violationType} = violationTypesData.violationTypes.find(
        t => t.violationTypeId === item.data.violationTypeId
      )
      return `${violationType} - ${licensePlate}`
    case ENTITIES.incidents:
    case ENTITIES.poolreports:
      const {type: incidentType} = incidentTypesData.incidentReportTypes.find(
        t => t.incidentReportTypeId === item.data.incidentReportTypeId
      )
      return `${incidentType} - ${item.data.details}`
    default:
      return '-'
  }
}

export {
  STORE_KEY,
  STORE_STATUS,
  getTempId,
  getItemDate,
  getItemType,
  getItemDetail,
}
