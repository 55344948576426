import * as React from 'react'

const useFilters = (defaults = {}) => {
  const [filters, setFilters] = React.useState(defaults)

  const handleFilters = React.useCallback(newFilters => {
    setFilters(newFilters)
  }, [])

  const handleRemoveFilter = key => {
    setFilters(currentFilters => {
      const {[key]: removed, ...newFilters} = currentFilters

      return newFilters
    })
  }

  return {filters, handleFilters, handleRemoveFilter}
}

export default useFilters
