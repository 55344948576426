import {
  Flex,
  Box,
  Text,
  Skeleton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react'
import {useItem} from '../../hooks/entities'
import {ENTITIES, ENTITIES_KEYS} from '../../lib/entities'
import {formatDate} from '../../lib/formatters'
import ImagesGallery from '../ImagesGallery'

const DarDetails = ({darId, isOpen, onClose}) => {
  const {data, isLoading} = useItem({
    entity: ENTITIES.dar,
    entityKey: ENTITIES_KEYS.dar,
    id: darId,
  })

  const {data: dar} = data ?? {}

  return (
    <Modal isOpen={isOpen} isCentered size="2xl" onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Details</ModalHeader>
        <ModalCloseButton />
        {isLoading ? (
          <Skeleton w="100%" h="100px" />
        ) : (
          <ModalBody p={6}>
            <Flex mb={2}>
              <Text fontWeight="bold" flexBasis="30%">
                Date
              </Text>
              <Text>{formatDate(dar.darDate)}</Text>
            </Flex>
            <Flex mb={2}>
              <Text fontWeight="bold" flexBasis="30%">
                Type
              </Text>
              <Text>{dar.type}</Text>
            </Flex>
            <Flex mb={2}>
              <Text fontWeight="bold" flexBasis="30%">
                Officer
              </Text>
              <Text>{dar.userName}</Text>
            </Flex>
            <Flex mb={2}>
              <Text fontWeight="bold" flexBasis="30%">
                Code
              </Text>
              <Box>
                <Box
                  boxSize="12px"
                  bg={`${dar.codeType.toLowerCase()}`}
                  borderRadius="50%"
                />
              </Box>
            </Flex>
            <Flex mb={2}>
              <Text fontWeight="bold" flexBasis="30%">
                GPS Coordinates
              </Text>
              <Text>
                {dar.includeGpsCoordinates
                  ? dar.gpsCoordinates.replace(',', ', ')
                  : '-'}
              </Text>
            </Flex>
            <Flex mb={2}>
              <Text fontWeight="bold" flexBasis="30%">
                Details
              </Text>
              <Text>{dar.details}</Text>
            </Flex>
            <ImagesGallery images={dar?.thumbImages} />
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  )
}

export default DarDetails
