import {
  Box,
  Flex,
  Text,
  Alert,
  AlertIcon,
  Input,
  Checkbox,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast,
} from '@chakra-ui/react'
import * as React from 'react'
import {Controller, useForm} from 'react-hook-form'
import PlateForm from '../PlateForm'
import {AsyncSelect} from '../Select'
import {useUserProperties} from '../../context/property'
import {useAuth} from '../../context/auth'
import {getDefaultValues, getToDate} from '../../lib/form/permits'
import {formatDate} from '../../lib/formatters'
import {useHouseholdsByCriteria} from '../../hooks/households'

const PermitForm = ({permit, isSubmiting, onSubmit}) => {
  const {
    register,
    control,
    reset,
    resetField,
    watch,
    handleSubmit,
    formState: {errors},
    setValue,
  } = useForm({
    defaultValues: getDefaultValues(permit),
  })
  const toast = useToast({
    position: 'top',
    isClosable: true,
  })
  const {user} = useAuth()
  const {currentProperty, isPermitsByHousehold, isPermitsByPlate} =
    useUserProperties()
  const {handleLoadOptions, households} = useHouseholdsByCriteria('houseHoldId')

  const [isNewVehicle, setIsNewVehicle] = React.useState(false)
  const [vehicles, setVehicles] = React.useState()

  const houseHoldIdWatch = watch('houseHoldId')
  const vehicleIdWatch = watch('vehicleId')
  const startDateWatch = watch('startDate')
  const quantityWatch = watch('quantity')
  const adminOverrideWatch = watch('adminOverride')

  const handleFormSubmit = async values => {
    try {
      const {propertyId} = currentProperty
      const {
        houseHoldId: {value: houseHoldId} = {},
        vehicleId: {value: vehicleId} = {},
        startDate,
        quantity,
        adminOverride,
      } = values

      if (vehicleId) {
        const {houseHoldId: vehicleHouseHoldId} = vehicles.find(
          v => v.vehicleId === vehicleId
        )

        if (houseHoldId && houseHoldId !== vehicleHouseHoldId) {
          toast({
            title: "Vehicle doesn't belong to the selected household",
            status: 'error',
          })
          return
        }
      }

      let permitData = {
        propertyId,
        houseHoldId,
        vehicleId,
        startDate,
        quantity,
        adminOverride,
      }

      if (!vehicleId) {
        const {licensePlate, make, model, color, state} = values
        permitData = {
          ...permitData,
          licensePlate,
          make,
          model,
          color,
          state,
        }
      }

      onSubmit(permitData)
    } catch (e) {
      toast({
        title: 'Oops, there is some error in your data',
      })
    }
  }

  React.useEffect(
    () => () => {
      reset()
    },
    [reset]
  )

  React.useEffect(() => {
    resetField('vehicleId')
  }, [houseHoldIdWatch, resetField])

  const handleNewVehicle = e => {
    e.preventDefault()
    setIsNewVehicle(state => !state)
  }

  const handleVeichles = React.useCallback(newVehicles => {
    setVehicles(newVehicles)
  }, [])

  const household = houseHoldIdWatch?.value
    ? households.find(h => h.houseHoldId === houseHoldIdWatch?.value)
    : null

  const vehicle = vehicleIdWatch?.value
    ? vehicles?.find(v => v.vehicleId === vehicleIdWatch?.value)
    : null
  const entity = isPermitsByHousehold ? household : vehicle

  const allowedPermits = permit?.allowedPermits ?? entity?.allowedPermits
  const isDisabled = allowedPermits <= 0 && !adminOverrideWatch

  return (
    <form id="permit-form" onSubmit={handleSubmit(handleFormSubmit)}>
      {!user.isResident ? (
        <FormControl
          mb={2}
          isInvalid={errors?.houseHoldId}
          isDisabled={isSubmiting}
        >
          <FormLabel>
            Household{' '}
            {isPermitsByPlate ? (
              <Text as="span" fontSize="sm" fontWeight="light">
                (optional)
              </Text>
            ) : null}
          </FormLabel>
          <Controller
            control={control}
            name="houseHoldId"
            rules={{
              validate: value =>
                Boolean(value) ||
                isPermitsByPlate ||
                'You must select a household',
              valueAsNumber: true,
            }}
            render={({field}) => (
              <AsyncSelect
                isClearable
                isSearchable
                placeholder="Select household"
                isDisabled={isPermitsByHousehold && permit}
                loadOptions={handleLoadOptions}
                {...field}
              />
            )}
          />
          {errors?.houseHoldId ? (
            <FormErrorMessage>{errors.houseHoldId.message}</FormErrorMessage>
          ) : null}
        </FormControl>
      ) : null}

      <Box mb={2}>
        <PlateForm
          plateEdit={permit}
          householdId={houseHoldIdWatch?.value}
          setValue={setValue}
          register={register}
          control={control}
          errors={errors}
          isNewVehicle={isNewVehicle}
          handleNewVehicle={handleNewVehicle}
          handleVehicles={handleVeichles}
        />
      </Box>

      <Flex mb={2} gap={2}>
        <FormControl
          flexBasis="50%"
          isInvalid={errors?.startDate}
          isDisabled={isSubmiting}
        >
          <FormLabel>Start Date</FormLabel>
          <Input
            type="date"
            {...register('startDate', {
              validate: value => {
                if (!value) {
                  return 'This field is required'
                }
                const currentDate = new Date().toJSON().slice(0, 10)
                return (
                  new Date(`${value}T00:00:00`).getTime() >=
                    new Date(`${currentDate}T00:00:00`).getTime() ||
                  'Permit date cannot be a past in the past'
                )
              },
            })}
          />
          {errors?.startDate ? (
            <FormErrorMessage>{errors.startDate.message}</FormErrorMessage>
          ) : null}
        </FormControl>
        {!permit ? (
          <FormControl
            flexBasis="50%"
            isInvalid={errors?.quantity}
            isDisabled={isSubmiting}
          >
            <FormLabel>
              Quantity{' '}
              {entity && !adminOverrideWatch
                ? `(${entity?.availablePermits} available)`
                : null}
            </FormLabel>
            <Input
              type="number"
              {...register('quantity', {
                validate: value => {
                  if (!entity) {
                    return true
                  }
                  return (
                    isNewVehicle ||
                    adminOverrideWatch ||
                    value <= entity?.availablePermits ||
                    `Exeding the amount of permits left (${entity?.availablePermits})`
                  )
                },
              })}
            />
            {errors?.quantity ? (
              <FormErrorMessage>{errors.quantity.message}</FormErrorMessage>
            ) : null}
          </FormControl>
        ) : null}
      </Flex>

      {!permit && startDateWatch && quantityWatch ? (
        <Alert status="info" rounded="md" fontSize="md">
          <AlertIcon />
          {quantityWatch} will be created for each day from {startDateWatch} to{' '}
          {getToDate(startDateWatch, quantityWatch)}
        </Alert>
      ) : null}

      {!permit ? (
        <FormControl isDisabled={isSubmiting}>
          <FormLabel>Override</FormLabel>
          <Checkbox borderColor="gray.400" {...register('adminOverride')} />
        </FormControl>
      ) : null}

      {isDisabled && entity?.allowedPermits <= 0 ? (
        <Flex>
          <Text fontWeight="semibold">
            Next permit will be available at:{' '}
            {formatDate(vehicle?.nextAvailableDate, {withHours: false})}
          </Text>
        </Flex>
      ) : null}
    </form>
  )
}

export default PermitForm
