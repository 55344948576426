import {Flex, Box, Text, Button, Icon, Skeleton} from '@chakra-ui/react'
import {MdModeEdit, MdDelete} from 'react-icons/md'
import ImagesGallery from '../../ImagesGallery/ImagesGallery'
import {useItem} from '../../../hooks/entities'
import {ENTITIES, ENTITIES_KEYS} from '../../../lib/entities'

const ViolationsMobileItem = ({
  isExpanded,
  violationId,
  canEdit,
  canDelete,
  onDetails,
  onEdit,
  onDelete,
}) => {
  const {data, isLoading} = useItem({
    entity: ENTITIES.violations,
    id: isExpanded ? violationId : undefined,
    entityKey: ENTITIES_KEYS.violations,
  })

  if (!isExpanded) {
    return null
  }

  if (isLoading) {
    return (
      <Flex>
        <Skeleton w="100%" h="150px" />
      </Flex>
    )
  }

  const {data: violation} = data

  return (
    <Flex align="center">
      <Flex direction="column" gap={2} mb={2} flex="1">
        <Box>
          <Text fontWeight="bold" as="span" mr={2}>
            Officer:
          </Text>
          {violation.officer.officer}
        </Box>
        <Box>
          <Text fontWeight="bold" as="span" mr={2}>
            Type:
          </Text>
          {violation.type}
        </Box>
        <Box>
          <Text fontWeight="bold" as="span" mr={2}>
            Details:
          </Text>
          {violation.details}
        </Box>
        {violation.thumbImages ? (
          <Box>
            <ImagesGallery images={violation.thumbImages} />
          </Box>
        ) : null}
        <Button
          w="100px"
          fontSize="sm"
          variant="solid"
          colorScheme="blue"
          onClick={() => onDetails(violation.vehicleViolationId)}
        >
          Print
        </Button>
      </Flex>
      <Flex direction="column" justify="end" gap={2} align="center">
        {canEdit ? (
          <Button
            size="sm"
            variant="ghost"
            onClick={() => onEdit(violation.vehicleViolationId)}
          >
            <Icon as={MdModeEdit} w={6} h={6} />
          </Button>
        ) : null}
        {canDelete ? (
          <Button
            size="sm"
            variant="ghost"
            colorScheme="red"
            onClick={() => onDelete(violation.vehicleViolationId)}
          >
            <Icon as={MdDelete} w={6} h={6} />
          </Button>
        ) : null}
      </Flex>
    </Flex>
  )
}

export default ViolationsMobileItem
