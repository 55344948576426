import {getEntitiesWithActions} from '../../lib/entities'

const userPermissionsList = ['user list', 'user show']
const userPermissionsCreate = ['user create', 'user store']
const userPermissionsEdit = ['user update']
const userPermissionsDelete = ['user delete']

const sortFields = [
  {
    key: 'user_name',
    dir: 'desc',
    label: 'By username Descending ',
  },
  {
    key: 'user_name',
    dir: 'asc',
    label: 'By username Ascending ',
  },
  {
    key: 'role_name',
    dir: 'desc',
    label: 'By user type Descending',
  },
  {
    key: 'role_name',
    dir: 'asc',
    label: 'By user type Ascending',
  },
]

const getUsersWithActions = (users, user) =>
  getEntitiesWithActions(
    users,
    user,
    userPermissionsEdit,
    userPermissionsDelete
  )

export {
  sortFields,
  getUsersWithActions,
  userPermissionsList,
  userPermissionsCreate,
  userPermissionsDelete,
}
