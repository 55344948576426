import {
  Flex,
  Text,
  Icon,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react'
import {BsChevronDown} from 'react-icons/bs'
import {useUserProperties} from '../../context/property'

const PropertyMenu = () => {
  const {currentProperty, properties, handlePropertyChange} =
    useUserProperties()

  if (!properties || !currentProperty) {
    return null
  }

  return (
    <Flex align="center" gap="4px" direction="column">
      <Text
        fontSize={{
          base: 'sm',
          lg: 'xs',
        }}
      >
        Showing elements of property
      </Text>
      <Menu>
        <MenuButton
          as={Button}
          variant="outline"
          rightIcon={<Icon as={BsChevronDown} />}
          disabled={properties.length <= 1}
        >
          {currentProperty.name}
        </MenuButton>
        <MenuList>
          {properties.map(property => (
            <MenuItem
              key={property.propertyId}
              onClick={() => handlePropertyChange(property.propertyId)}
            >
              {property.name}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Flex>
  )
}

export default PropertyMenu
