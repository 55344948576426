import {
  Flex,
  Icon,
  Text,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Skeleton,
} from '@chakra-ui/react'
import {MdClose} from 'react-icons/md'
import DarForm from './DarForm'
import {useDarTypes, useDarCodes} from '../../hooks/dar'
import {useItem} from '../../hooks/entities'
import {ENTITIES, ENTITIES_KEYS} from '../../lib/entities'

const DarFormModal = function ({
  darId,
  itemData,
  isOpen,
  isSubmiting,
  onClose,
  onSubmit,
}) {
  const {data: typeData, isLoading: isLoadingTypes} = useDarTypes({
    enabled: isOpen,
  })
  const {data: codesData, isLoading: isLoadingCodes} = useDarCodes({
    enabled: isOpen,
  })
  const {
    data: darData,
    isLoading: isLoadingDar,
    isFetching,
    isRefetching,
  } = useItem({
    id: darId,
    entity: ENTITIES.dar,
    entityKey: ENTITIES_KEYS.dar,
  })

  const isLoading =
    isLoadingTypes ||
    isLoadingCodes ||
    isLoadingDar ||
    isFetching ||
    isRefetching

  const types = typeData
    ? typeData.dailyActivityReportTypes.map(
        ({dailyActivityReportTypeId, type}) => ({
          value: dailyActivityReportTypeId,
          label: type,
        })
      )
    : []

  const codes = codesData
    ? codesData.dailyActivityReportCodes.map(
        ({dailyActivityReportCodeId, type}) => ({
          value: dailyActivityReportCodeId,
          label: type,
        })
      )
    : []

  const dar = itemData || darData?.data

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      size={{
        base: 'full',
        lg: 'lg',
      }}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader borderBottom="1px solid" borderColor="gray.400">
          <Flex align="center" gap={2}>
            <Button p="0" variant="ghost" onClick={onClose}>
              <Icon as={MdClose} w="24px" h="24px" />
            </Button>
            <Text>{darId ? 'Update' : 'Create'} DAR</Text>
            <Button
              ml="auto"
              type="submit"
              form="dar-form"
              variant="solid"
              colorScheme="blue"
              disabled={isLoading}
              isLoading={isSubmiting}
            >
              {darId ? 'Update' : 'Create'}
            </Button>
          </Flex>
        </DrawerHeader>
        <DrawerBody px={8}>
          {isLoading ? (
            <Skeleton h="200px" />
          ) : (
            <DarForm
              dar={dar}
              types={types}
              codes={codes}
              onSubmit={onSubmit}
              isSubmiting={isSubmiting}
            />
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default DarFormModal
