import {getEntitiesWithActions} from '../../lib/entities'

const briefPermissionsList = ['briefingboard list', 'briefingboard show']
const briefPermissionsCreate = ['briefingboard create', 'briefingboard store']
const briefPermissionsEdit = ['briefingboard update']
const briefPermissionsDelete = ['briefingboard delete']

const getBriefsWithActions = (briefs, user) =>
  getEntitiesWithActions(
    briefs,
    user,
    briefPermissionsEdit,
    briefPermissionsDelete
  )

const sortFields = [
  {
    key: 'created_at',
    dir: 'desc',
    label: 'By date descending',
  },
  {
    key: 'created_at',
    dir: 'asc',
    label: 'By date ascending',
  },
]

export {
  briefPermissionsCreate,
  briefPermissionsList,
  briefPermissionsDelete,
  briefPermissionsEdit,
  getBriefsWithActions,
  sortFields,
}
