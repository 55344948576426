import PrpertiesFormInfo from './PropertiesFormInfo'
import PrpertiesFormAddress from './PropertiesFormAddress'
import PrpertiesFormSections from './PropertiesFormSections'
import PrpertiesFormRules from './PropertiesFormRules'

const PROPERTIES_FORM_STEPS = [
  {label: 'Info', formId: 'info-form', component: PrpertiesFormInfo},
  {label: 'Address', formId: 'address-from', component: PrpertiesFormAddress},
  {
    label: 'Sections',
    formId: 'sections-from',
    component: PrpertiesFormSections,
  },
  {label: 'Rules', formId: 'rules-from', component: PrpertiesFormRules},
]

const FORM_ACTIONS = {
  initial: 'initial',
  create: 'create',
  update: 'update',
}

export {PROPERTIES_FORM_STEPS, FORM_ACTIONS}
