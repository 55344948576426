import {Flex, Skeleton} from '@chakra-ui/react'

const PagePlaceholder = () => (
  <Flex h="100%" gap={4} direction="column">
    <Skeleton h="40px" />
    <Skeleton h="80%" />
  </Flex>
)

export default PagePlaceholder
