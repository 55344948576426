import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import {useForm} from 'react-hook-form'
import usePostPoneShift from '../../hooks/usePostPoneShift'

const EOSPostPone = ({event, onClose}) => {
  const {mutate, isLoading} = usePostPoneShift()
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    defaultValues: {
      date: event.object.detail.end_time.replace(' ', 'T'),
    },
  })

  const hnadleFormSubmit = values => {
    let {date} = values
    date = `${date.replace('T', ' ')}:00`
    const schedId = event.object.detail.officer_schedule_id

    mutate(
      {
        postpone_time: date,
        officer_schedule_id: schedId,
      },
      {
        onSuccess() {
          onClose()
        },
      }
    )
  }

  return (
    <Modal isOpen isCentered onClose={() => {}} blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Enter your new leavnig time</ModalHeader>
        <ModalBody>
          <form id="date-form" onSubmit={handleSubmit(hnadleFormSubmit)}>
            <FormControl isInvalid={errors?.date}>
              <FormLabel>Leaving on</FormLabel>
              <Input
                type="datetime-local"
                {...register('date', {required: 'This field is required'})}
              />
              {errors?.date ? (
                <FormErrorMessage>{errors.date.message}</FormErrorMessage>
              ) : null}
            </FormControl>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outline"
            colorScheme="blue"
            onClick={onClose}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            variant="solid"
            colorScheme="blue"
            ml={2}
            type="submit"
            form="date-form"
            isLoading={isLoading}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default EOSPostPone
