import * as React from 'react'
import {useOfflineStore} from '../context/offlineStore'

const useStoreCount = () => {
  const {store} = useOfflineStore()
  const [itemsCount, setItemsCount] = React.useState(0)

  React.useEffect(() => {
    const count = Object.keys(store)
      .filter(key => store[key])
      .reduce((acc, key) => acc + store[key].length, 0)
    setItemsCount(count)
  }, [store])

  return itemsCount
}

export default useStoreCount
