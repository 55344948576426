const GROUP_IDS = {
  properties_management: 'properties_management',
  reports: 'reports',
  users_management: 'users_management',
}
const GROUP_SECTIONS = {
  [GROUP_IDS.properties_management]: [
    '/properties',
    '/households',
    '/vehicles',
    '/permits',
    '/pools',
  ],
  [GROUP_IDS.reports]: [
    '/',
    '/incidents',
    '/poolreport',
    '/violations',
    '/offlinestore',
  ],
  [GROUP_IDS.users_management]: [
    '/users',
    '/briefing',
    '/schedules',
    '/shifts',
  ],
}
const NAVBAR_GROUPS = [
  {
    id: GROUP_IDS.properties_management,
    icon: 'md/MdWork',
    title: 'Properties management',
    groupSections: GROUP_SECTIONS[GROUP_IDS.properties_management],
    items: [
      {
        path: '/properties',
        permissions: ['property list'],
        label: 'Properties',
        position: 1,
      },
    ],
  },
  {
    id: GROUP_IDS.reports,
    icon: 'md/MdTopic',
    title: 'Reports',
    groupSections: GROUP_SECTIONS[GROUP_IDS.reports],
    items: [
      {
        path: '/offlinestore',
        permissions: ['offlinestore list'],
        label: 'Offline store',
        position: 14,
      },
    ],
  },
  {
    id: GROUP_IDS.users_management,
    icon: 'md/MdPeople',
    title: 'Users management',
    groupSections: GROUP_SECTIONS[GROUP_IDS.users_management],
    items: [
      {
        path: '/users',
        permissions: ['user list'],
        label: 'Users',
        position: 1,
      },
      {
        path: '/briefing',
        permissions: ['briefingboard list'],
        label: 'Biefring boards',
        position: 2,
      },
      {
        path: '/shifts',
        permissions: ['shift list'],
        label: 'Shifts',
        position: 3,
      },
      {
        path: '/schedules',
        permissions: ['officerschedule list'],
        label: 'Schedules',
        position: 4,
      },
    ],
  },
]

export {NAVBAR_GROUPS, GROUP_SECTIONS, GROUP_IDS}
