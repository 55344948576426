import {Flex, Text, Button} from '@chakra-ui/react'

const ErrorFallback = ({error}) => {
  const handleReload = () => {
    window.location.reload()
  }

  return (
    <Flex justify="center" align="center" direction="column" gap={4} h="100%">
      <Text fontSize="xl" fontWeight="bold">
        Something went wrong!
      </Text>

      <Text whiteSpace="break-spaces">Error message: {error?.message}</Text>
      <Button variant="solid" colorScheme="blue" onClick={handleReload}>
        Reload app
      </Button>
      <Text fontSize="xs">
        If the error persist, contact your administrator
      </Text>
    </Flex>
  )
}

export default ErrorFallback
