import {
  Flex,
  Button,
  Input,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast,
} from '@chakra-ui/react'
import {useForm} from 'react-hook-form'
import {Link, useNavigate} from 'react-router-dom'
import {useAuth} from '../context/auth'
import {usePosition} from '../context/position'
import {useLogin} from '../hooks/auth'
import {getUserData} from '../lib/auth'

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: {errors},
    resetField,
  } = useForm()
  const navigate = useNavigate()
  const {handleUser} = useAuth()
  const {getCoordinates} = usePosition()
  const {mutate, reset, isLoading} = useLogin()
  const toast = useToast({
    position: 'top',
    isClosable: true,
  })

  const onSubmit = async ({userName, password}) => {
    const {latitude, longitude} = await getCoordinates()
    const gpsCoordinates = `${latitude},${longitude}`
    const loginData = {
      userName,
      password,
      gpsCoordinates,
    }

    mutate(loginData, {
      async onSuccess(responseData) {
        const userData = getUserData(responseData)

        handleUser(userData)
        reset()
        navigate('/')
      },
      onError(error) {
        resetField('password')
        toast({
          title: error.statusText,
          status: 'error',
        })
      },
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <FormControl isInvalid={errors?.userName} mb={2}>
        <FormLabel>Username</FormLabel>
        <Input
          type="text"
          {...register('userName', {
            required: 'This field is required',
          })}
        />
        {errors?.userName ? (
          <FormErrorMessage>{errors.userName.message}</FormErrorMessage>
        ) : null}
      </FormControl>
      <FormControl isInvalid={errors?.password} mb={8}>
        <FormLabel>Password</FormLabel>
        <Input
          type="password"
          {...register('password', {
            required: 'This field is required',
          })}
        />
        {errors?.password ? (
          <FormErrorMessage>{errors.password.message}</FormErrorMessage>
        ) : null}
      </FormControl>
      <Flex justify="center" gap={4} flexWrap="wrap">
        <Flex flexBasis="100%" justify="center">
          <Button
            w="40%"
            type="submit"
            variant="solid"
            colorScheme="blue"
            isLoading={isLoading}
          >
            Login
          </Button>
        </Flex>
        <Link to="/auth/forgot">
          <Text fontSize="xs" textDecoration="underline">
            Forgot password?
          </Text>
        </Link>
      </Flex>
    </form>
  )
}

export default Login
